/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import ButtonLoader from "../../components/Loaders/ButtonLoader/ButtonLoader";
import * as mq from "../../styles/mediaQueries";
import { amount, range } from "../../utils";
import {
  extractNumbers,
  getInWords,
  isCIRider,
  sendProductFormat,
  sendRiderFormat,
} from "../../utils/helper";
import { InputWrapper } from "../Landing/Landing.style";
import { toast } from "react-toastify";
import CardPopup from "../../components/CardPopup";
import { useDidMountEffect } from "../../custom-hooks/useDidMountEffect";
import { setDiabeticFalse } from "../QuotesPage/Quotes.slice";
import {
  useGetCartQuery,
  useGetRiderPremiumMutation,
  useUpdateCartMutation,
} from "../rider/services/rider";
import { AddButton, Filter, SelectLabel } from "./RiderCard.style";
import RiderDescription from "./RiderDescription";
import FilterSelect from "./RiderFilterSelect";

function RiderCardNew({
  rider,
  onRiderAddRemove,
  recalculateRiders = [],
  hideRiders = [],
  setLoader,
  setLoad,
  ...props
}) {
  const dispatch = useDispatch();
  const { data: cartData } = useGetCartQuery();
  const { isDiabetic } = useSelector(state => state.quotes);
  const inputRef = useRef();
  const [CIVariant, setCIVariant] = useState(isCIRider(rider) ? 22 : undefined);
  const [optionalBenefitValue, setOptionalBenefitValue] = useState(
    rider?.optional_benefit || false,
  );
  const ridersAdded = cartData?.investment_riders?.map(rider =>
    sendRiderFormat({
      ...rider,
      policy_term: rider.policy_term,
      investment_amount: rider.investment_amount,
      sum_assured: rider.investment_amount,
      premiumPayingTerm: rider.pay_term,
      max_cover_amount: rider.max_cover_amount,
      min_cover_amount: rider.min_cover_amount,
    }),
  );
  const thisRider = ridersAdded.find(
    thisRider => thisRider.id === rider.rider_id,
  );
  const isThisRiderAdded = !!thisRider;
  let initialRider = { ...rider };
  if (isThisRiderAdded) {
    initialRider.net_premium = thisRider.total_premium;
  }
  const [finalRider, setFinalRider] = useState(initialRider);
  const {
    rider_name,
    net_premium,
    mandatory,
    error_message,
    min_ppt,
    max_ppt,
    min_policy_term,
    max_policy_term,
    min_cover_amount,
    max_cover_amount,
    rider_id,
    rider_shortname,
    limited_pay_rider,
    default_policy_term = "5",
    default_ppt = "5",
    default_sum_assured,
    sa_base_plan,
    pt_freq,
    ppt_freq,
    pt_base_plan,
    ppt_base_plan,
    ppt_interval = 1,
    pt_interval = 1,
    paytype_option,
    rider_sa_interval,
    optional_benefit = false,
  } = finalRider;
  let defaultCoverAmount = default_sum_assured;
  let defaultPolicyTerm = default_policy_term;
  let defaultPremiumPayingTerm = default_ppt;

  if (isThisRiderAdded) {
    defaultCoverAmount = thisRider.investment_amount;
    defaultPolicyTerm = thisRider.policy_term;
    defaultPremiumPayingTerm = thisRider.pay_term;
  }

  const [show, setShow] = useState(null);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(defaultCoverAmount || default_sum_assured);
  const [coverAmount, setCoverAmount] = useState(
    defaultCoverAmount || default_sum_assured,
  );
  const [policyTerm, setPolicyTerm] = useState(defaultPolicyTerm);
  const [premiumPayingTerm, setPremiumPayingTerm] = useState(
    defaultPremiumPayingTerm,
  );

  const closePopup = () => {
    setShow(null);
    dispatch(setDiabeticFalse());
  };
  const { alias } = cartData.product.company;
  const riderToAdd = sendRiderFormat({
    ...finalRider,
    sum_assured: coverAmount,
    policy_term: policyTerm,
    premiumPayingTerm,
  });

  const [updateCart, { isLoading: isUpdateCartLoading, data: updateCartData }] =
    useUpdateCartMutation();

  const sendProductData = sendProductFormat(cartData);

  const addRider = ({ ci_variant, ...otherRiderProperties } = {}) => {
    updateCart({
      cartId: cartData.id,
      ...sendProductData,
      extra_input: { ci_variant, optional_benefit_value: optionalBenefitValue },
      riders: [
        ...ridersAdded,
        {
          ...riderToAdd,
          extra_input: {
            ci_variant,
            optional_benefit_value: optionalBenefitValue,
          },
          ...otherRiderProperties,
        },
      ],
    }).then(() => onRiderAddRemove(finalRider, true));
  };
  const removeRider = () => {
    updateCart({
      cartId: cartData.id,
      ...sendProductData,
      riders: ridersAdded.filter(rider => rider.id !== rider_id),
    }).then(() => onRiderAddRemove(finalRider, false));
  };
  const updateRider = () =>
    updateCart({
      cartId: cartData.id,
      ...sendProductData,
      riders: ridersAdded.map(rider =>
        rider.id === rider_id ? riderToAdd : rider,
      ),
    });

  useEffect(() => {
    if (isThisRiderAdded && isDiabetic && alias === "max_life") {
      removeRider();
    }
  }, [isThisRiderAdded, isDiabetic]);

  const handleBuyClick = e => {
    e.preventDefault();
    if (optional_benefit) setOptionalBenefitValue(!optionalBenefitValue);
    if (isThisRiderAdded) {
      removeRider();
      return;
    }
    if (
      alias === "max_life" &&
      ["CI", "CIP", "WOP"].includes(rider_shortname)
    ) {
      setShow("diabetic");
      return;
    }
    addRider();
  };

  const [getRiderPremium, { isLoading: isRiderPremiumLoading }] =
    useGetRiderPremiumMutation();

  const {
    investment_insurance_id: investmentInsuranceId,
    product: { id: productId },
  } = cartData;

  const updatePremium = useCallback(
    (otherRiderProperties = {}) => {
      getRiderPremium({
        investmentInsuranceId,
        productId,
        riders: [
          {
            rider_shortname,
            rider_id,
            sum_assured: coverAmount,
            policy_term: policyTerm,
            premium_payment_term: premiumPayingTerm,
            paytype_option,
            default_policy_term,
            extra_input: {
              ci_variant: CIVariant,
              optional_benefit_value: optionalBenefitValue,
            },
            max_cover_amount,
            min_cover_amount,
            ...otherRiderProperties,
          },
        ],
      }).then(res => {
        if (res.error) {
          toast.error(res?.error?.data?.message);
          return;
        }
        const {
          data: {
            data: [newRider],
          },
        } = res;
        if (newRider) setFinalRider(newRider);
      });
    },
    [coverAmount, policyTerm, premiumPayingTerm, CIVariant],
  );
  const isHidden = hideRiders.includes(rider_shortname) || isDiabetic;

  useDidMountEffect(() => {
    if (recalculateRiders.includes(rider_shortname)) {
      updatePremium();
    }
  }, [updatePremium, recalculateRiders, rider_shortname]);

  useDidMountEffect(() => {
    updatePremium();
  }, [coverAmount, policyTerm, premiumPayingTerm, CIVariant]);

  useDidMountEffect(() => {
    if (isThisRiderAdded) {
      updateRider();
    }
  }, [finalRider]);

  const debouncedSave = useCallback(
    debounce(data => {
      if (rider_sa_interval) {
        if (data % rider_sa_interval !== 0) {
          toast.error("Please enter a multiple of " + rider_sa_interval);
          setValue(riderCoverAmount?.sum_assured);
          return;
        }
      }
      if (data > max_cover_amount) {
        toast.warning(
          `Rider Cover can't be more than the maximum rider cover of ${max_cover_amount}`,
          { toastId: "rider-error" },
        );
        setValue(riderCoverAmount?.sum_assured);
        return;
      }
      if (data < min_cover_amount) {
        toast.warning(
          `Rider Cover can't be less than the minimum rider cover of ${min_cover_amount}`,
          { toastId: "rider-error" },
        );
        setValue(riderCoverAmount?.sum_assured);
        return;
      }
      setCoverAmount(data);
    }, 3000),
    [],
  );

  const handleCoverChange = e => {
    setValue(e);
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") inputRef.current.blur();
  };

  useDidMountEffect(() => {
    if (value !== "") return debouncedSave(value);
  }, [value]);

  let [riderCoverAmount] = cartData.investment_riders
    .map(item => {
      return {
        sum_assured: item.term_sum_assured,
        short: item.rider_shortname,
      };
    })
    .filter(item => item.short === rider_shortname);
  riderCoverAmount =
    riderCoverAmount !== undefined
      ? riderCoverAmount
      : { sum_assured: initialRider.default_sum_assured };

  const clearWaitingQueue = () => {
    toast.clearWaitingQueue();
  };

  const cartWarn = useCallback(
    debounce(() => {
      if (
        updateCartData?.status === false &&
        updateCartData?.message !== error_message
      )
        return isThisRiderAdded === false
          ? toast.warning(updateCartData?.message, { toastId: "rider-error" })
          : toast.warning(updateCartData?.message, {
              toastId: "rider-error",
            }) && removeRider();
      if (
        (updateCartData?.status === false && error_message === "") ||
        (value < min_cover_amount && error_message === "") ||
        (value > max_cover_amount && error_message === "")
      ) {
        return setValue(riderCoverAmount?.sum_assured);
      } else return null;
    }, 2000),
  );

  useEffect(() => {
    cartWarn();
    clearWaitingQueue();
  }, [coverAmount, updateCartData]);

  useEffect(() => {
    if (error_message !== "") {
      toast.warning(error_message, { toastId: "rider-error" }) &&
        setValue(initialRider.default_sum_assured);
    } else {
      clearWaitingQueue();
    }
  }, [error_message]);

  useEffect(() => {
    setLoader(isUpdateCartLoading);
  }, [isUpdateCartLoading]);

  const isLimitedPay =
    !!(ppt_base_plan === "N" && limited_pay_rider) &&
    limited_pay_rider instanceof Array
      ? limited_pay_rider.length > 0
      : true;

  const handlePolicyTermChange = changedPolicyTerm => {
    if (!isLimitedPay) setPremiumPayingTerm(changedPolicyTerm);
    else {
      if (isLimitedPay && paytype_option !== "S") {
        if (limited_pay_rider?.[`pt_${changedPolicyTerm}`]) {
          const limitedPayDropDown = limited_pay_rider?.[
            `pt_${changedPolicyTerm}`
          ].map(i => ({
            code: i,
            display_name: `${i} ${parseInt(i) !== 1 ? "years" : "year"}`,
          }));
          setPremiumPayingTerm(limitedPayDropDown[0].code);
        }
      }
    }
    setPolicyTerm(changedPolicyTerm);
  };

  const handlePremiumPayingTermChange = changedPremiumPayingTerm => {
    return paytype_option === "S"
      ? setPremiumPayingTerm(min_ppt)
      : setPremiumPayingTerm(changedPremiumPayingTerm);
  };

  const riderTermOptions = range(
    parseInt(min_policy_term),
    parseInt(max_policy_term),
    parseInt(pt_interval),
  ).map(i => ({
    code: i,
    display_name: `${i} ${pt_freq}${parseInt(i) !== 1 ? "s" : ""}`,
  }));
  const payTermOptions = range(
    parseInt(min_ppt),
    parseInt(max_ppt),
    parseInt(ppt_interval),
  ).map(i => ({
    code: i,
    display_name: `${i} ${ppt_freq}${parseInt(i) !== 1 ? "s" : ""}`,
  }));

  return (
    <RiderCardWrap
      rider={rider}
      message={error_message}
      active={isThisRiderAdded}
      onPremiumClick={handleBuyClick}
      {...props}
    >
      {isHidden ? <HideRider /> : null}
      <div
        css={`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={`
            font-family: Inter;
            font-weight: 600;
            font-size: 14px;
            width: 60%;
          `}
        >
          {rider_name}
        </div>
        <AddButton
          active={isThisRiderAdded}
          onClick={handleBuyClick}
          disabled={
            isRiderPremiumLoading ||
            isUpdateCartLoading ||
            mandatory === "yes" ||
            (net_premium === 0 && !optional_benefit)
          }
          isCheckBox={net_premium === 0 && !optional_benefit}
        >
          {isRiderPremiumLoading || isUpdateCartLoading ? (
            <ButtonLoader colored={!isThisRiderAdded} />
          ) : (
            <>
              {!optional_benefit && (
                <span
                  css={`
                    margin-left: 0px;
                    font-weight: 700;
                  `}
                >
                  {amount(net_premium)}
                </span>
              )}
              {isThisRiderAdded ? (
                <div
                  css={`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20%;
                    height: 25px;
                    border-radius: 50%;
                    background-color: #e6f3ff;

                    ${mq.sm} {
                      width: 20%;
                      height: 19px;
                    }
                  `}
                >
                  <i
                    className="fas fa-check"
                    style={{
                      fontSize: "11px",
                      padding: "auto",
                      color: "var(--primary-color)",
                    }}
                  />
                </div>
              ) : (
                <div
                  css={`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20%;
                    height: 25px;
                    border-radius: 50%;
                    background-color: #e6f3ff;
                    ${mq.sm} {
                      width: 20%;
                      height: 19px;
                    }
                  `}
                >
                  <i
                    className="fas fa-plus"
                    style={{
                      fontSize: "11px",
                      padding: "auto",
                      color: "var(--primary-color)",
                    }}
                  />
                </div>
              )}
            </>
          )}
        </AddButton>
      </div>
      <RiderDescriptionWrap>
        <RiderDescription rider={finalRider} />
      </RiderDescriptionWrap>
      <>
        {sa_base_plan === "Y" &&
        pt_base_plan === "Y" &&
        ppt_base_plan === "Y" ? (
          <div></div>
        ) : (
          <>
            {min_policy_term ? (
              <RiderFiltersWrap>
                <Filter>
                  <SelectLabel>Rider Cover</SelectLabel>
                  <InputWrapper
                    style={{
                      height: "90%",
                      margin: "0",
                      padding: "0",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      ref={inputRef}
                      type=""
                      inputMode="numeric"
                      style={{
                        width: "100%",
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                        marginBottom: "5px",
                        border: "none",
                        outline: "none",
                      }}
                      onKeyDown={handleKeyDown}
                      onBlur={() => {
                        setEdit(false);
                        debouncedSave(extractNumbers(value));
                      }}
                      readOnly={!edit}
                      value={edit ? extractNumbers(value) : getInWords(value)}
                      maxLength={11}
                      onChange={e => handleCoverChange(e.target.value)}
                      disabled={
                        sa_base_plan === "Y" ||
                        //   isRiderPremiumLoading ||
                        isUpdateCartLoading
                      }
                    />

                    {edit ? (
                      <i
                        style={{
                          color: "#666",
                          paddingBottom: "5px",
                          fontSize: "16px",
                        }}
                        className="fas fa-check-circle"
                        onClick={() => {
                          setEdit(false);
                          debouncedSave(extractNumbers(value));
                        }}
                      />
                    ) : (
                      <i
                        className="fas fa-edit"
                        onClick={() => setEdit(true)}
                        style={{
                          color: "#666",
                          paddingBottom: "5px",
                          fontSize: "16px",
                        }}
                      />
                    )}
                  </InputWrapper>
                </Filter>
                <Filter>
                  <SelectLabel>Rider Term</SelectLabel>
                  <RiderDropdown
                    options={riderTermOptions}
                    selectedItem={
                      policyTerm +
                      ` ${pt_freq}${parseInt(policyTerm) !== 1 ? "s" : ""}`
                    }
                    onChange={handlePolicyTermChange}
                    disabled={pt_base_plan === "Y"}
                  />
                </Filter>
                <Filter>
                  <SelectLabel>Pay Term</SelectLabel>
                  <RiderDropdown
                    options={payTermOptions}
                    selectedItem={
                      paytype_option === "S"
                        ? "Pay Once"
                        : premiumPayingTerm +
                          ` ${ppt_freq}${
                            parseInt(premiumPayingTerm) !== 1 ? "s" : ""
                          }`
                    }
                    onChange={e => handlePremiumPayingTermChange(e)}
                    disabled={ppt_base_plan === "Y" || paytype_option === "S"}
                  />
                </Filter>
              </RiderFiltersWrap>
            ) : null}
          </>
        )}
      </>
      {/* <SpousePopup
          show={show === "spouse-popup"}
          onHide={closePopup}
          handleContinue={isThisRiderAdded ? removeRider : addRider}
          fetchRiderPremium={updatePremium}
          rider={finalRider}
          isLoading={isUpdateCartLoading || isRiderPremiumLoading}
        /> */}
      <CardPopup
        show={show === "diabetic"}
        handleClose={closePopup}
        handleContinue={isThisRiderAdded ? removeRider : addRider}
        rider={finalRider}
        handleCICountChange={setCIVariant}
        isLoading={isUpdateCartLoading || isRiderPremiumLoading}
        CIVariant={CIVariant}
      />
    </RiderCardWrap>
  );
}

export default RiderCardNew;

const RiderDescriptionWrap = styled.div`
  color: #666;
  font-size: 0.76rem;
  margin: 1em 0;
`;

const RiderFiltersWrap = styled.div`
  margin-top: auto;
  display: flex;
  gap: 0.6em;
`;

export const HideRider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff90;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const RiderCardWrap = styled.div`
  box-shadow: 0 0 12px 0px #00000021;
  margin: 0;
  padding: 15px;
  border-radius: 0.3em;
  border: 1px solid transparent;
  border-color: ${props =>
    props.active
      ? props.message !== ""
        ? "red"
        : "var(--primary-color)"
      : "transparent"};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  ${mq.sm} {
    margin-top: 11px;
    padding: 7px 15px;
  }
`;

function RiderDropdown({ disabled = false, ...props }) {
  return (
    <FilterSelect
      {...props}
      searchable
      inputStyle={{
        backgroundColor: "#fff",
        padding: 0,
        textAlign: "left",
        height: "auto",
        fontSize: "0.76rem",
        margin: 0,
      }}
      arrowStyle={{
        right: 0,
        position: "absolute",
        display: disabled ? "none" : "static",
      }}
      wrapperStyle={{
        padding: 0,
        fontSize: "0.873em",
        marginLeft: "-0.397em",
        marginTop: "0.6em",
        pointerEvents: disabled ? "none" : "all",
      }}
      listStyle={{ width: "100%", left: 0 }}
    />
  );
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import styled from "styled-components/macro";
import useGetInWords from "../../custom-hooks/useGetInWords";
import * as mq from "../../styles/mediaQueries";
import { convertIntoYearly } from "../../utils";
import { updateEnquiries } from "../Landing/input.slice";
import { selectICLogo } from "../frontendBoot/frontendBoot.slice";
import {
  fetchKnowMoreData,
  selectShortListedPlanData,
  selectShortListedPlanTemp,
  setAddShowMoreQuotes,
  setRemoveShowMore,
  setSelectedQuote,
  setShortListedPlan,
  setShortListedPlansData,
} from "./Quotes.slice";
import BookmarkQuote from "./components/BookMarkQuote";
import { CompareCheckboxSymbol } from "./components/CompareCheckboxSymbol";
import PayoutFrequencySelect from "./components/PayoutFrequencySelect";
import PolicyTermSelect from "./components/PolicyTermSelect";
import { Label, TextInput } from "./components/Quotes.style";

const QuoteCard = ({
  quoteData,
  onBuyNowClick,
  showAddOn,
  setShowAddOn = () => {},
  differentQuote = false,
  showMorePlan,
  setShowMorePlan,
  hasMorePlans,
  morePlanCount,
  isFetching,
  policyTermToShow,
  setPolicyTermToShow,
  payoutTermToShow,
  setPayoutTermToShow,
}) => {
  const location = useLocation();
  const temp = useSelector(selectShortListedPlanTemp);
  const shortlisted = useSelector(selectShortListedPlanData);
  useEffect(() => {
    if (
      temp.some(item => item?.product.id === quoteData?.product.id) &&
      shortlisted.some(item => item?.product.id === quoteData?.product.id) ===
        false
    ) {
      const { id } = quoteData?.product;
      dispatch(setShortListedPlan(id));
      dispatch(setShortListedPlansData(quoteData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const logo = useSelector(selectICLogo(quoteData?.company_alias));
  const product = quoteData?.product_name ? quoteData?.product_name : "Product";
  const [planName, ...planType] = product
    ?.split("-")
    .map(item => item && item.trim());
  const benefit = quoteData?.maturity_benefit;
  const benefit4 = quoteData?.maturity_benefit4;
  const benefit8 = quoteData?.maturity_benefit8;
  const benefitWord = useGetInWords(benefit);
  const benefitWord4 = useGetInWords(benefit4);
  const benefitWord8 = useGetInWords(benefit8);
  const dispatch = useDispatch();
  const {
    getMoneyAs,
    payFrequency,
    investedAmount,
    investFor: investForValue = "10",
    returnsAfter: returnsAfterValue = "20",
    incomeFor: incomeForValue = "20",
  } = useSelector(state => state.filters);
  const incomeStartAtValue = `${quoteData?.income_start_at} Years` || "0";
  const totalAmount = convertIntoYearly(
    quoteData?.pay_frequency,
    quoteData?.investment_amount,
  );
  const totalAmountInWords = useGetInWords(totalAmount);
  const incomeBenefitInWords = useGetInWords(quoteData?.income_benfit);
  const incomeBenefitInWords4 = useGetInWords(quoteData?.income_benefit4);
  const incomeBenefitInWords8 = useGetInWords(quoteData?.income_benefit8);
  const income_benefit = quoteData?.income_benfit;
  const income_benefit4 = quoteData?.income_benefit4;
  const income_benefit8 = quoteData?.income_benefit8;
  const showMorePlans = useSelector(state => state.quotes.showMoreQuotes);
  const deathBenefit = useGetInWords(quoteData?.death_benefit);
  const productId = quoteData?.product?.id;
  const insuranceId = quoteData?.investment_insurance_id;
  const selectedProduct = {
    investment_insurance_id: insuranceId,
    product_id: productId,
  };
  const totalAmountToBeReceived =
    quoteData?.income_benfit * incomeForValue +
    Number(benefit) +
    quoteData?.additional_lumpsum +
    quoteData?.additional_rop;
  const totalAmountToBeReceived4 =
    quoteData?.income_benefit4 * incomeForValue +
    Number(benefit4) +
    quoteData?.additional_lumpsum +
    quoteData?.additional_rop;
  const totalAmountToBeReceived8 =
    quoteData?.income_benefit8 * incomeForValue +
    Number(benefit8) +
    quoteData?.additional_lumpsum +
    quoteData?.additional_rop;
  const totalAmountInvested = quoteData?.premium * investForValue;
  const returns =
    Math.round((totalAmountToBeReceived / totalAmountInvested) * 100) / 100;
  const returns4 =
    Math.round((totalAmountToBeReceived4 / totalAmountInvested) * 100) / 100;
  const returns8 =
    Math.round((totalAmountToBeReceived8 / totalAmountInvested) * 100) / 100;
  const handleGetDetails = () => {
    dispatch(
      setSelectedQuote({ ...quoteData, frequency: payFrequency, getMoneyAs }),
    );
    dispatch(
      updateEnquiries({
        input: {
          selected_product: selectedProduct,
          policy_term: String(policyTermToShow),
          income_for: incomeForValue,
        },
      }),
    );
    dispatch(fetchKnowMoreData(quoteData?.product?.id));
    onBuyNowClick && onBuyNowClick();
  };
  const { showMoreQuotes } = useSelector(state => state.quotes);
  const handleShowMore = () => {
    if (showMoreQuotes?.includes(quoteData?.company_alias)) {
      setShowMorePlan(false);
      dispatch(setRemoveShowMore(quoteData?.company_alias));
    } else {
      setShowMorePlan(true);
      dispatch(setAddShowMoreQuotes(quoteData?.company_alias));
    }
  };
  const selectedPayoutTerm = quoteData?.selected_payout_term;
  const isPar = quoteData?.product_type === "PAR";
  const isIncomeOrBoth = getMoneyAs === "I" || getMoneyAs === "B";
  const isLumpsum = getMoneyAs === "L";
  const isDefermentPeriod = quoteData?.deferment_period !== null;
  if (isFetching) return null;
  return (
    <>
      <DeathBenefitSection>Death Benefit : {deathBenefit}</DeathBenefitSection>
      <QuoteCard1Container>
        {/* IC Logo & Name Section */}
        <QuoteContentSection>
          <QuoteCardImageSection planName={planName}>
            <img src={logo} alt="ic-logo" className="ic-logo" />
            <div className="q-plan-name">{planName}</div>
            <div className="q-plan-type">{planType.join(" - ")}</div>
          </QuoteCardImageSection>
          <StraightLineDiv />
          {/* You Invest Section*/}
          <QuoteCardInfoSection>
            <QuoteVal>You Invest</QuoteVal>
            <QuoteVal
              amount
              css={`
                color: ${quoteData?.differentQuote &&
                +quoteData?.investment_amount !== +investedAmount &&
                "red"};
              `}
            >
              {totalAmountInWords}
            </QuoteVal>
            {+investForValue === 1 ? (
              <>
                <QuoteVal>Rupees in</QuoteVal>
                <QuoteVal
                  css={`
                    color: ${quoteData?.differentQuote &&
                    +quoteData?.pay_term !== +investForValue &&
                    "red"};
                  `}
                >
                  Single Pay
                </QuoteVal>
              </>
            ) : (
              <>
                <QuoteVal>Per Year</QuoteVal>
                <QuoteVal
                  css={`
                    color: ${quoteData?.differentQuote &&
                    +quoteData?.pay_term !== +investForValue &&
                    "red"};
                  `}
                >
                  {`For ${quoteData?.pay_term} Years`}
                </QuoteVal>
              </>
            )}
            {isPar && <QuoteVal>&nbsp;</QuoteVal>}
          </QuoteCardInfoSection>
          <StraightLineDiv />
          {/* You Receive Section*/}
          <QuoteCardInfoSection>
            <QuoteVal>You Receive</QuoteVal>
            {isPar ? (
              <>
                <QuoteVal
                  amount
                  css={`
                    color: ${quoteData?.differentQuote && "red"};
                  `}
                >
                  {income_benefit4 ? incomeBenefitInWords4 : benefitWord4}
                  <span>{" (4.0% p.a)"}</span>
                </QuoteVal>
                <QuoteVal
                  amount
                  css={`
                    color: ${quoteData?.differentQuote && "red"};
                  `}
                >
                  {income_benefit8 ? incomeBenefitInWords8 : benefitWord8}
                  <span>{" (8.0% p.a)"}</span>
                </QuoteVal>
              </>
            ) : (
              <QuoteVal
                amount
                css={`
                  color: ${quoteData?.differentQuote && "red"};
                `}
              >
                {income_benefit ? incomeBenefitInWords : benefitWord}
              </QuoteVal>
            )}
            <QuoteVal>
              {isIncomeOrBoth
                ? selectedPayoutTerm
                  ? selectedPayoutTerm
                  : "Per Year"
                : "After"}
            </QuoteVal>
            {isIncomeOrBoth ? (
              <QuoteVal
                css={`
                  color: ${quoteData?.differentQuote &&
                  +quoteData?.income_for !== +incomeForValue &&
                  "red"};
                `}
              >
                For {quoteData?.income_for}
                {+quoteData?.income_for === 1 ? " Year" : " Years"}
              </QuoteVal>
            ) : (
              <QuoteVal
                css={`
                  color: ${quoteData?.differentQuote &&
                  +quoteData?.guaranteed_lumpsum_at !== +returnsAfterValue &&
                  "red"};
                `}
              >
                {quoteData?.guaranteed_lumpsum_at}
                {+quoteData?.guaranteed_lumpsum_at === 1 ? " Year" : " Years"}
              </QuoteVal>
            )}
            {location.pathname !== "/shortlisted-plans" &&
              hasMorePlans &&
              showMorePlans && (
                <ShowMorePlanDiv onClick={handleShowMore}>
                  {showMorePlan ? "Close" : ` + ${morePlanCount} More Plan`}
                </ShowMorePlanDiv>
              )}
          </QuoteCardInfoSection>
          <StraightLineDiv />
          {/* Returns Section*/}
          <QuoteCardInfoSection>
            <QuoteVal>Returns</QuoteVal>
            {isPar ? (
              <>
                <QuoteVal
                  amount
                  css={`
                    color: ${quoteData?.differentQuote && "red"};
                  `}
                >
                  {isLumpsum
                    ? `IRR ${quoteData?.irr_value}%`
                    : `${returns4} times`}
                  <span>{" (4.0% p.a)"}</span>
                </QuoteVal>
                <QuoteVal
                  amount
                  css={`
                    color: ${quoteData?.differentQuote && "red"};
                  `}
                >
                  {isLumpsum
                    ? `IRR ${quoteData?.irr_value}%`
                    : `${returns8} times`}
                  <span>{" (8.0% p.a)"}</span>
                </QuoteVal>
              </>
            ) : (
              <QuoteVal
                amount
                css={`
                  color: ${quoteData?.differentQuote && "red"};
                `}
              >
                {isLumpsum
                  ? `IRR ${quoteData?.irr_value}%`
                  : `${returns} times`}
              </QuoteVal>
            )}
            <QuoteVal>{isLumpsum ? `Policy Term` : `Income Start `}</QuoteVal>
            {isLumpsum ? (
              <QuoteVal
                css={`
                  color: ${quoteData?.differentQuote &&
                  quoteData?.policy_term !== policyTermToShow &&
                  "red"};
                `}
              >
                {policyTermToShow} Years
              </QuoteVal>
            ) : (
              <QuoteVal
                css={`
                  color: ${quoteData?.differentQuote && "red"};
                `}
              >
                After {incomeStartAtValue}
              </QuoteVal>
            )}
          </QuoteCardInfoSection>
          <StraightLineDiv />
          {/* Get Details Section */}
          <QuoteLastSection>
            <PolicyTermSelect
              title="Policy Term :"
              quoteData={quoteData}
              differentQuote={differentQuote}
              policyTermToShow={policyTermToShow}
              setPolicyTermToShow={setPolicyTermToShow}
            />
            <QuoteCard1Button onClick={handleGetDetails}>
              Get Details
            </QuoteCard1Button>
            <div>
              {!isLumpsum && isDefermentPeriod && (
                <TextInput>
                  <Label>
                    Deferment Period : {quoteData?.deferment_period}{" "}
                    {quoteData?.deferment_period > 1 ? "years" : "year"}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </Label>
                </TextInput>
              )}
              {!isLumpsum && (
                <PayoutFrequencySelect
                  title="Payout Frequency :"
                  quoteData={quoteData}
                  differentQuote={differentQuote}
                  payoutTermToShow={payoutTermToShow}
                  setPayoutTermToShow={setPayoutTermToShow}
                />
              )}
            </div>
            {isDefermentPeriod && <div style={{ height: "5px" }}>&nbsp;</div>}
          </QuoteLastSection>
          <CompareCheckboxSymbol quoteData={quoteData} />
        </QuoteContentSection>
        <QuoteCard1Bookmark>
          <BookmarkQuote
            investmentId={quoteData?.product?.id}
            plans={quoteData}
          />
        </QuoteCard1Bookmark>
      </QuoteCard1Container>
    </>
  );
};

export const DeathBenefitSection = styled.div`
  display: block;
  position: relative;
  font-size: 9px;
  left: 5px;
  top: 30px;
  font-family: "Inter";
  font-weight: 600;
  color: #fff;
  background: #00a364;
  clip-path: polygon(100% 0, 90% 50%, 100% 100%, 0 100%, 0 0);
  width: 150px;
  padding: 4px 12px;
  text-align: left;
  z-index: 1;

  ${mq.lg} {
    font-size: 9px;
    font-family: "Inter";
    font-weight: 500;
    top: 30px;
    width: 140px;
  }
  ${mq.md} {
    font-size: 9px;
    font-family: "Inter";
    font-weight: 500;
    top: 5px;
    left: -5px;
    width: 140px;
  }
`;

const QuoteCard1Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: calc(100% - 10px);
  height: 130px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  background: white;
  box-shadow: 2px 2px 6px 2px #ddd;
  border: solid 1px rgba(0, 0, 0, 0.3);
  padding: 1px;
  transition: all 0.3s ease;
  ${mq.lg} {
    height: 120px;
  }
`;

const QuoteContentSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const QuoteCardImageSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20%;
  margin-top: 28px;
  .ic-logo {
    height: 35px;
    ${mq.lg} {
      height: 30px;
    }
  }
  .q-plan-name {
    font-size: ${props => (props?.planName?.length > 20 ? "11px" : "12px")};
    font-family: "Inter";
    font-weight: 600;
    text-align: center;
    margin-top: 5px;
    line-height: 1.3;

    @media screen and (max-width: 1400px) and (min-width: 992px) {
      font-size: ${props => (props?.planName?.length > 20 ? "10px" : "11px")};
      font-weight: ${props => (props?.planName?.length > 20 ? 700 : 600)};
    }
  }
  .q-plan-type {
    font-size: ${props => (props?.planName?.length > 20 ? "10px" : "11px")};
    font-family: "Inter";
    text-align: center;
    @media screen and (max-width: 1400px) and (min-width: 992px) {
      ${props => (props?.planName?.length > 20 ? "9px" : "10px")};
    }
  }
`;

const QuoteCardInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px 10px 0;
  border-radius: 5px;
  height: 100%;
  position: relative;
  ${mq.lg} {
    height: 85%;
    margin-top: 13px;
  }
  width: 15%;
`;
const StraightLineDiv = styled.div`
  display: flex;
  height: 61px;
  width: 1px;
  background: var(--border-light);
`;
const QuoteVal = styled.div`
  font-weight: ${props => (props.amount ? "600" : "400")};
  font-size: ${props => (props.amount ? "14px" : "12px")};
  text-align: center;
  white-space: nowrap;
  span {
    font-size: 0.65rem;
  }
`;

const QuoteLastSection = styled.div`
  width: 24%;
  height: 100%;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const QuoteCard1Button = styled.button`
  width: 70%;
  font-size: 13px;
  border: none;
  outline: none;
  padding: 5px;
  color: white;
  font-weight: 700;
  background: var(--primary-color);
  border-radius: 5px;
`;
const QuoteCard1Bookmark = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  ${mq.lg} {
    right: 5px;
    top: 5px;
  }
`;
const ShowMorePlanDiv = styled.div`
  position: absolute;
  bottom: -3px;
  font-size: 10px;
  background-color: rgba(226, 240, 255, 0.5);
  border-radius: 5px 5px 0 0;
  font-weight: 700;
  padding: 2px 5px;
  color: #0056fe;
  cursor: pointer;
  text-align: center;
  margin-top: 4px;
  ${mq.lg} {
    font-size: 9px;
    bottom: -5px;
  }
`;
export default QuoteCard;

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectInvestmentFilters } from "../../Filters/filterSlice";
import { setSelectedQuote } from "../../QuotesPage/Quotes.slice";
import { useGetCartQuery, useGetQuoteQuery } from "../../rider/services/rider";

function usePlanCard() {
  const { data: cartData } = useGetCartQuery();
  const { isFilterUpdating, ...filters } = useSelector(state => state.filters);
  const quote = useRef();
  const { newFilters } = useSelector(state => state.inputForm);
  const { selectedQuote } = useSelector(state => state?.quotes);
  const dispatch = useDispatch();
  const { proposalData } = useSelector(state => state.proposalForm);
  const {
    is_early_income,
    is_increasing_income,
    is_deferred_income,
    is_wealth_income,
    early_income_percentage,
    increasing_income_percentage,
    deferred_income_percentage,
    wealth_income_percentage,
  } = proposalData?.["Insured Basic Details"] || {};
  const {
    data: quoteData,
    isLoading,
    isFetching,
  } = useGetQuoteQuery(
    {
      company_alias: cartData?.data?.product?.company.alias,
      newFilters,
      differentQuote: false,
      ...(is_early_income === "Y" && {
        is_early_income,
        early_income_percentage,
      }),
      ...(is_deferred_income === "Y" && {
        is_deferred_income,
        deferred_income_percentage,
      }),
      ...(is_increasing_income === "Y" && {
        is_increasing_income,
        increasing_income_percentage,
      }),
      ...(is_wealth_income === "Y" && {
        is_wealth_income,
        wealth_income_percentage,
      }),
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (!isLoading && !isFetching && quoteData?.data) {
      quote.current = quoteData?.data?.find(
        item => item?.product?.id === cartData?.data?.product?.id,
      );
      dispatch(setSelectedQuote(quote?.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching]);

  const selectedProduct = cartData;
  const premiumLoading = isLoading || isFetching;
  const ridersAdded = cartData?.investment_riders;

  if (!cartData || !quoteData)
    return {
      totalGst: 0,
      totalPremium: 0,
      basePremium: 0,
      ridersAdded: [],
      quote: {},
      premiumLoading,
      selectedProduct: { product: {} },
      filters,
    };

  const totalRidersPremium = ridersAdded.reduce(
    (sum, rider) => parseInt(sum) + parseInt(rider?.total_premium),
    0,
  );

  const totalRidersTax = ridersAdded.reduce(
    (taxSum, rider) => parseInt(taxSum) + parseInt(rider?.tax_amount),
    0,
  );

  const totalGst =
    (parseInt(quote?.current?.tax_amount) || parseInt(cartData?.service_tax)) +
    parseInt(totalRidersTax);

  const totalPremium =
    (parseInt(quote?.current?.total_premium) ||
      parseInt(cartData?.total_premium)) + parseInt(totalRidersPremium);
  return {
    totalGst,
    totalPremium,
    premiumLoading,
    basePremium:
      (parseInt(quote?.current?.total_premium) || cartData?.total_premium) -
      cartData?.service_tax,
    ridersAdded,
    selectedProduct,
    filters,
    planData: selectedQuote || quote?.current,
    isLoading: isLoading || isFetching,
  };
}

const paymentOptions = {
  regularPay: "Regular Pay",
  limitedPay: "Limited Pay",
  singlePay: "Single Pay",
};

function usePlanDetails() {
  const { totalPremium } = usePlanCard();
  const investmentFilters = useSelector(selectInvestmentFilters);

  const coverAmountObject = () => {
    let numbers = [];
    if (String(investmentFilters?.term_sum_assured).length >= 8) {
      let value = investmentFilters?.term_sum_assured / 10000000;
      numbers.push({
        code: investmentFilters?.term_sum_assured,
        display_name: `${value} Crore`,
      });
    } else if (String(investmentFilters?.term_sum_assured).length >= 6) {
      let value = investmentFilters?.term_sum_assured / 100000;
      numbers.push({
        code: investmentFilters?.term_sum_assured,
        display_name: `${value} Lac`,
      });
    }
    return numbers;
  };

  const [{ display_name: coverAmount }] = coverAmountObject();

  return {
    coverAmount,
    isLoading: !totalPremium,
  };
}

function redirectToIc(redirectionData) {
  if (redirectionData) {
    const { redirection_url, redirection_method, redirection_params } =
      redirectionData;

    const form = document.createElement("form");

    form.setAttribute("action", redirection_url);
    form.setAttribute("method", redirection_method);
    if (redirection_method === "POST") {
      Object.entries(redirection_params).forEach(([key, value]) => {
        const inputData = document.createElement("input");
        inputData.name = key;
        inputData.value = value;
        form.appendChild(inputData);
      });

      document.body.appendChild(form);
      form.submit();

      document.body.removeChild(form);
    } else if (redirection_method === "GET") {
      window.open(redirection_url, "_self");
    }
  }
}

export { usePlanCard, usePlanDetails, paymentOptions, redirectToIc };

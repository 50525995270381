import { Checkbox } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import * as mq from "../../../styles/mediaQueries";
import { useGetCompareProductsQuery } from "../../rider/services/rider";
import {
  selectShowDifference,
  setShowDifference,
  setShowSelected,
} from "../Compare.slice";
import { BiArrowBack } from "react-icons/bi";
import { DownloadCompareButton } from "./DownloadCompareButton";
import {
  ProductCard,
  ProductCardContainer,
  ProductCardWrap,
  SelectProductCard,
} from "./ProductCard";
import { useNavigate } from "react-router";

const scrollCss = `
box-shadow: rgb(0 0 0 / 10%) 0px 5px 8px 0px;

`;

function ProductCards({ ...props }) {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  const matches = useMediaQuery(`${mq.mdForHook}`);

  const dispatch = useDispatch();
  // const { compareQuotes } = useSelector((state) => state.compareQuotes);

  const { data, isLoading } = useGetCompareProductsQuery();
  const { showSelected } = useSelector(state => state.compare);

  if (isLoading) return <></>;

  const { products } = data?.data;
  const renderDownload = products?.length > 0;

  return (
    <>
      <StyledProductCards {...props} css={offset > 110 && scrollCss}>
        {matches && (
          <>
            <div
              css={`
                min-width: 100%;
              `}
            >
              <Head />
              <ShowDifferenceCheckbox />
            </div>
          </>
        )}
        {!matches && (
          <ProductCardContainer>
            <ProductCardWrap>
              <StyledHeading>Product Comparison</StyledHeading>

              <ShowDifferenceCheckbox />

              {!matches && renderDownload && <DownloadCompareButton />}
              <div
                css={`
                  color: var(--primary-color);
                  text-decoration: underline;
                  cursor: pointer;
                  ${mq.md} {
                    font-size: 11px;
                    padding-bottom: 2px;
                  }
                `}
                onClick={() => dispatch(setShowSelected(!showSelected))}
              >
                {showSelected ? "Show all rows" : "Show only selected"}
              </div>
            </ProductCardWrap>
          </ProductCardContainer>
        )}
        {matches
          ? [0, 1].map(_i =>
              products[_i] ? (
                <ProductCard
                  product={products[_i]}
                  key={products[_i].product_id}
                />
              ) : (
                <SelectProductCard />
              ),
            )
          : [0, 1, 2].map(_i =>
              products[_i] ? (
                <ProductCard
                  product={products[_i]}
                  key={products[_i].product_id}
                />
              ) : (
                <SelectProductCard />
              ),
            )}
      </StyledProductCards>
    </>
  );
}

export default ProductCards;

function Head() {
  const navigate = useNavigate();
  return (
    <>
      <HeadWrapper>
        <div
          css={`
            width: 20px;
            height: 20px;
            background: var(--tertiary-shade);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          `}
          onClick={() => {
            navigate({
              pathname: "/quote",
              search: window.location.search,
            });
          }}
        >
          <BiArrowBack fontSize={"18px"} />
        </div>
        <StyledHeading>Product Comparison</StyledHeading>
      </HeadWrapper>
    </>
  );
}

function ShowDifferenceCheckbox() {
  const dispatch = useDispatch();
  const showDifference = useSelector(selectShowDifference);
  const { data } = useGetCompareProductsQuery();
  const { products } = data.data;
  const renderShowDifferenceCheckbox = products.length > 1;
  const handleChange = () => {
    if (showDifference) {
      dispatch(setShowDifference(false));
      return;
    }
    dispatch(setShowDifference(true));
  };

  return (
    <div
      css={`
        font-family: "Inter";
        font-weight: 600;
        margin: 12px 0;
        display: flex;
        align-items: center;
        ${mq.md} {
          font-family: "Inter";
          font-weight: 500;
          background: #eaeef2;
          width: 100%;
          margin: 0;
          padding: 3px 10px;
          box-shadow: inset 0 0 10px #dce1e6;
        }
      `}
    >
      <div>
        <Checkbox
          size="small"
          title="Add to Compare"
          style={{ padding: "2px" }}
          disabled={!renderShowDifferenceCheckbox}
          onClick={event => handleChange(event)}
          checked={showDifference}
          id="compare-show-difference"
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 18,
            },
          }}
          css={`
            .MuiSvgIcon-root {
              ${mq.lg} {
                font-size: 17px !important;
              }
            }
          `}
        />
      </div>
      <label
        htmlFor="compare-show-difference"
        css={`
          margin-left: 2px;
          ${mq.lg} {
            font-size: 12px;
          }
        `}
      >
        Show Difference
      </label>
    </div>
  );
}

const StyledProductCards = styled.main`
  display: flex;
  justify-content: space-between;
  position: sticky;
  flex-wrap: wrap;
  background-color: #fff;
  top: 0px;
  z-index: 10;
  padding: 25px 20px;
  border-radius: 4px;
  -webkit-transition: -webkit-transform 0.25s;
  transition: all 0.3s ease;
  transition: transform 0.25s;
  box-shadow: rgb(0 0 0 / 10%) 0px 5px 15px 0px;

  ${mq.lg} {
    padding: 15px;
  }

  ${mq.md} {
    position: sticky;
    width: 100%;
    justify-content: center;
    top: 0;
    padding: 0;
  }
  & > div {
    width: 24%;
    height: 14em;
    ${mq.lg} {
      height: 13em;
    }
    ${mq.md} {
      height: 12em;
      width: 44%;
      &:first-child {
        width: 100%;
        height: fit-content;
      }
    }
    ${mq.sm} {
      width: 46.5%;
      &:first-child {
        width: 100%;
      }
    }
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 10px 10px 10px;
  gap: 5px;
`;

const StyledHeading = styled.div`
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  color: var(--secondary-text);

  ${mq.lg} {
    font-size: 14px;
  }
  ${mq.md} {
    font-size: 15px;
    padding-left: 4px;
  }
`;

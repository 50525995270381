/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useGetCartQuery, useGetRidersQuery } from "../rider/services/rider";
import styled from "styled-components/macro";
import * as mq from "../../styles/mediaQueries";
import { useDispatch, useSelector } from "react-redux";
import RiderCardNew, { HideRider } from "./RiderCardNew";
import { removeAddedRiders, removeShortName } from "../QuotesPage/Quotes.slice";
import RiderSkeletonLoader from "../../components/Loaders/RiderLoader/RiderSkeletonLoader";
import HdfcDistributor from "./components/Hdfc/HdfcDistributor";
function RiderNew({ item, children, loader, setLoader, setLoad, ...props }) {
  const { data: cartData } = useGetCartQuery();
  const alias = cartData?.product?.company?.alias;
  return (
    <div>
      <Outer {...props}>
        {alias === "hdfc_life" && <HdfcDistributor />}
        <RidersList loader={loader} setLoader={setLoader} setLoad={setLoad} />
      </Outer>
    </div>
  );
}

const Outer = styled.div`
  width: 100%;
  padding: 0 15px;
  margin-bottom: 20px;
  ${mq.sm} {
    width: 100%;
    margin-top: 10px;
  }
`;

export default RiderNew;

export function RidersList({ loader, setLoader, setLoad }) {
  const { data: cartData } = useGetCartQuery();
  const { shortNameToRemove } = useSelector(state => state.quotes);
  const dispatch = useDispatch();
  const {
    product: { id: productId },
  } = cartData;

  const {
    data: riders,
    isError,
    error,
    isUninitialized,
    isLoading,
    isFetching,
  } = useGetRidersQuery({
    productId: cartData?.data?.product?.id,
    investment_insurance_id: cartData?.data?.investment_insurance_id,
  });

  const [recalculateRiders, setRecalculateRiders] = useState([]);
  const [hideRiders, setHideRiders] = useState([]);
  const addToReCalculateRiders = rider_shortname => {
    setRecalculateRiders([...recalculateRiders, ...rider_shortname]);
  };
  const removeFromReCalculateRiders = recalc_riders => {
    setRecalculateRiders(
      recalculateRiders.filter(
        rider => !recalc_riders.includes(rider.rider_shortname),
      ),
    );
  };

  useEffect(() => {
    cartData?.investment_riders?.forEach(e => {
      e?.hide_riders?.map(item => setHideRiders(prev => [...prev, item]));
    });
  }, [cartData]);

  const addToHideRiders = rider_shortname =>
    setHideRiders([...hideRiders, ...rider_shortname]);

  const removeFromHideRiders = rider_shortname =>
    setHideRiders(hideRiders.filter(rider => rider_shortname === rider));
  const handleRiderAddRemove = (rider, isAdded) => {
    const { recalc_rider, hide_riders } = rider;
    if (hide_riders) {
      if (isAdded) {
        addToHideRiders(hide_riders);
      } else removeFromHideRiders(hide_riders);
    }

    dispatch(
      removeAddedRiders({
        id: rider.id,
        productId,
      }),
    );
    if (shortNameToRemove !== rider.rider_shortname) {
      dispatch(removeShortName(rider.rider_shortname));
    }

    if (rider.recalc_rider) {
      if (isAdded) {
        addToReCalculateRiders(rider.recalc_rider);
      } else removeFromReCalculateRiders(recalc_rider);
    }
  };

  if (isError) {
    return (
      <div>
        <p>Status code: {error.status}</p>
        <p>Message: {error.data.message || error.data.error}</p>
      </div>
    );
  }

  if (isUninitialized || isLoading || isFetching) {
    return (
      <div>
        <LoadingRiders />
      </div>
    );
  }
  if (!riders) {
    return (
      <p
        css={`
          font-size: 14px;
        `}
      >
        No Add - Ons are available for this Product!
      </p>
    );
  }

  return (
    <>
      <ul
        css={`
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 0.6em;
          ${mq.sm} {
            margin-bottom: 5rem;
          }
          ${mq.xs} {
            flex-direction: column;
            margin-bottom: 5rem;
          }
        `}
      >
        {riders?.map(rider => (
          <li
            key={`${rider.rider_shortname}${rider.rider_id}`}
            css={`
              flex: 0 0 calc(50% - 0.3em);
              display: flex;
              width: 100%;
            `}
          >
            {rider.rider_type !== "Free" && (
              <RiderCardWrapper>
                {loader !== false ? <HideRider /> : null}
                <RiderCardNew
                  rider={rider}
                  onRiderAddRemove={handleRiderAddRemove}
                  recalculateRiders={recalculateRiders}
                  hideRiders={hideRiders}
                  setLoader={setLoader}
                  setLoad={setLoad}
                  productId={productId}
                />
              </RiderCardWrapper>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}
function LoadingRiders() {
  return (
    <div
      css={`
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        ${mq.sm} {
          flex-direction: column;
        }
        & > #u_0_v {
          & .lightui1 {
            margin: 0;
          }
          flex: 1 0 calc(50% - 1em);
          width: 50%;
          ${mq.sm} {
            // width: 100%;
            width: 92vw;
          }
        }
      `}
    >
      <RiderSkeletonLoader noOfCards={6} />
    </div>
  );
}

const RiderCardWrapper = styled.div`
  margin: 0;
  border-radius: 0.3em;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

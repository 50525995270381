import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../../../custom-hooks/useUrlQuery";
import { setData } from "../../../Landing/input.slice";
import { patchEnquiries } from "../../../Landing/serviceApi";
import { useInputFormsHook, useInputPageHook } from "../../hooks";
import { eligibilityFormSchema } from "./validation";

const useEligibilityForm = (current, setCurrent) => {
  const { investmentSuitabilityFlagStatus } = useInputPageHook();
  const { getEligibilityFormData, setEligibilityFormData } =
    useInputFormsHook();
  const patchData = useSelector(state => state.inputForm?.data);
  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(eligibilityFormSchema),
    defaultValues: getEligibilityFormData(),
    mode: "all",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const enquiryId = useUrlQuery("enquiryId");
  const education = watch("education");
  const occupation = watch("occupation");
  const pinCode = watch("pincode");

  const viewQuotesHandler = async e => {
    e.preventDefault();
    await patchEnquiries({ input: { ...patchData } }).then(() => {
      navigate(`/quote?enquiryId=${enquiryId}`);
    });
  };

  const onSubmit = data => {
    dispatch(setData(data));
    setEligibilityFormData(data, pinCode);
    if (investmentSuitabilityFlagStatus === "Yes") setCurrent(current + 1);
    else setCurrent(current + 2);
  };

  return {
    handleSubmit,
    onSubmit,
    errors,
    setValue,
    occupation,
    education,
    pinCode,
    viewQuotesHandler,
  };
};

export default useEligibilityForm;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import useGetInWords from "../../../../../../../custom-hooks/useGetInWords";
import { setSelectedPolicyPayoutFilter } from "../../../../../Quotes.slice";
import { CustomButton, CustomGroup } from "./customButton.styled";
import { payFrequencyOptions } from "./modalBody.constant";

const PayoutTermBodyMobile = ({
  quoteData,
  getMoneyAs,
  setShow,
  setPayoutTermToShow,
  payoutTermToShow,
  differentQuote,
}) => {
  const selectedFrequency = payFrequencyOptions.find(
    item => item.code === quoteData.pay_frequency,
  );
  const [activeIndex, setActiveIndex] = useState();
  const investmentAmount = useGetInWords(quoteData?.investment_amount);
  const optionsList = quoteData?.payout_term_options?.map(
    val => val?.display_name,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (payoutTermToShow) {
      setActiveIndex(
        optionsList.findIndex(item => item === payoutTermToShow) || "0",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutTermToShow]);

  return (
    <>
      <DivWrapper>
        <DivFlex>
          <ParentSpan>
            <FirstChildSpan>Investment Amount :</FirstChildSpan>
            <SecondChildSpan>{investmentAmount}</SecondChildSpan>
          </ParentSpan>

          <ParentSpan>
            <FirstChildSpan>Premium Payment Term :</FirstChildSpan>
            <SecondChildSpan>{quoteData.pay_term} Years</SecondChildSpan>
          </ParentSpan>
        </DivFlex>

        <Divider />

        <DivFlex>
          <ParentSpan>
            <FirstChildSpan>Pay Frequency :</FirstChildSpan>
            <SecondChildSpan>{selectedFrequency?.display_name}</SecondChildSpan>
          </ParentSpan>

          {getMoneyAs === "I" || getMoneyAs === "B" ? (
            <ParentSpan>
              <FirstChildSpan>Income Term :</FirstChildSpan>
              <SecondChildSpan>{quoteData.income_for} Years</SecondChildSpan>
            </ParentSpan>
          ) : (
            <ParentSpan>
              <FirstChildSpan>Policy Term :</FirstChildSpan>
              <SecondChildSpan>{quoteData.policy_term} Years</SecondChildSpan>
            </ParentSpan>
          )}
        </DivFlex>
      </DivWrapper>

      <CustomGroup>
        {optionsList.map((value, index) => {
          return (
            <CustomButton
              key={index}
              selected={index === parseInt(activeIndex)}
              onClick={() => {
                setShow(false);
                setPayoutTermToShow(value);
                dispatch(
                  setSelectedPolicyPayoutFilter({
                    company_alias: differentQuote
                      ? `${quoteData?.company_alias}_different`
                      : quoteData?.company_alias,
                    value: {
                      selected_payout_term: value?.display_name,
                      investment_insurance_id:
                        quoteData?.investment_insurance_id,
                      product_id: quoteData?.product?.id,
                      policy_term: quoteData?.policy_term,
                    },
                  }),
                );
              }}
              value={payoutTermToShow}
            >
              {value}
            </CustomButton>
          );
        })}
      </CustomGroup>
    </>
  );
};

export default PayoutTermBodyMobile;

const DivWrapper = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  margin: 0 0 15px 0;
  padding: 6px;
  display: flex;
  justify-content: space-evenly;
`;

const Divider = styled.div`
  border: 0.5px solid rgba(79, 92, 111, 0.2);
`;

const DivFlex = styled.div`
  display: flex;
  gap: 0.5em;
  flex-direction: column;
`;

const ParentSpan = styled.span`
  display: flex;
  gap: 1em;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;
const FirstChildSpan = styled.span`
  color: rgb(77, 77, 79);
  font-size: 10px;
`;

const SecondChildSpan = styled.span`
  font-weight: bold;
  font-size: 11px;
`;

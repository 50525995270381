import { InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { SiWhatsapp } from "react-icons/si";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNumberInput } from "../../../utils";
import TextInput from "../../QuotesPage/components/EditDetailsSection/components/TextInput";
import ShareViaForm from "../../QuotesPage/components/Share/ShareForm";
import { useShareQuoteMutation } from "../../rider/services/rider";
import { selectCompareQuotes } from "../ComparePlanPopupSlice";

const WhatsAppShareCompare = () => {
  const { data } = useSelector(state => state.inputForm);
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const [phone, handleChange] = useNumberInput(data.mobile);
  const { showDifference, selectedFeatures } = useSelector(
    state => state.compare,
  );
  const compareQuotes = useSelector(selectCompareQuotes);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (phone.length === 10) setError(false);
  }, [phone]);
  const handleSubmit = async evt => {
    evt.preventDefault();

    if (phone.length === 10) {
      setError(false);
      shareQuotes({
        purpose: "compare",
        groupCode: "10003511",
        mode: ["WHATSAPP"],
        show_difference: showDifference,
        product_ids: compareQuotes,
        selected_features: selectedFeatures,
        via: "whatsapp",
        stage: "COMPARE",
        whatsapp: phone,
      }).then(data => {
        if (data.error) {
          toast.error("Sharing Failed!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          toast.success("Shared Successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });
    } else setError("Please enter a valid whatsapp no.");
  };

  return (
    <ShareViaForm onSubmit={handleSubmit} isLoading={isLoading}>
      <TextInput
        label=" Whatsapp Number"
        variant="outlined"
        error={error}
        value={phone}
        name="phone"
        onChange={handleChange}
        inputProps={{ minLength: 2, maxLength: 10, pattern: "[0-9]*" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SiWhatsapp className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
    </ShareViaForm>
  );
};
export default WhatsAppShareCompare;

// import { useSelector } from "react-redux";
import { useInputPageHook } from "../hooks";
import {
  InputFormsProgressBarStyled,
  ProgressDotStyled,
  ProgressLineStyled,
} from "../styles/inputForms.styled";

const InputFormsProgressBar = ({ contentToDisplay, ...props }) => {
  const { investmentSuitabilityFlagStatus, isEnquiry } = useInputPageHook();
  // const { tenant } = useSelector(state => state.frontendBoot?.frontendData);
  return (
    <InputFormsProgressBarStyled {...props}>
      {/* {tenant?.name !== "Fyntune" ? (
        <>
          <ProgressDotStyled
            state={contentToDisplay >= 0 ? "past" : "future"}
          />
          <ProgressLineStyled
            state={contentToDisplay >= 1 ? "past" : "future"}
          />
        </>
      ) : (
        <></>
      )} */}

      <ProgressDotStyled state={contentToDisplay >= 1 ? "past" : "future"} />
      <ProgressLineStyled state={contentToDisplay >= 2 ? "past" : "future"} />
      <ProgressDotStyled state={contentToDisplay >= 2 ? "past" : "future"} />
      {!isEnquiry && (
        <>
          {investmentSuitabilityFlagStatus === "Yes" && (
            <>
              <ProgressLineStyled
                state={contentToDisplay >= 3 ? "past" : "future"}
              />
              <ProgressDotStyled
                state={contentToDisplay >= 3 ? "past" : "future"}
              />
            </>
          )}
          <ProgressLineStyled
            state={contentToDisplay >= 4 ? "past" : "future"}
          />
          <ProgressDotStyled
            state={contentToDisplay >= 4 ? "past" : "future"}
          />
        </>
      )}
    </InputFormsProgressBarStyled>
  );
};

export default InputFormsProgressBar;

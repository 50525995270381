/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar-Component";
import TataDocumentUpload from "../ProposalPage/companies/Tata/Document/TataDocumentUpload";
import styled from "styled-components/macro";
import { UploadPanel } from "../ProposalPage/ProposalPage.style";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import api from "../../api/api";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import {
  proposal_status,
  updateProposal,
} from "../ProposalPage/ProposalPage.slice";
import { useGetCartQuery } from "../rider/services/rider";
import BajajDocumentUpload from "../ProposalPage/companies/Bajaj/Document/BajajDocumentUpload";
import RiderNewPlanCard from "../RiderPageNew/RiderPlanCard/RiderNewPlanCard";

const FormByIC = ({
  company_alias = "tata_aia",
  setDisable,
  disable,
  setSuccessBajajDocUpload,
  successBajajDocUpload,
}) => {
  if (company_alias === "tata_aia")
    return <TataDocumentUpload setDisable={setDisable} disable={disable} />;
  if (company_alias === "bajaj_life")
    return (
      <BajajDocumentUpload
        setDisable={setDisable}
        disable={disable}
        setSuccessBajajDocUpload={setSuccessBajajDocUpload}
        successBajajDocUpload={successBajajDocUpload}
      />
    );
  else return <></>;
};

const DocumentUploadPage = () => {
  const { data } = useGetCartQuery();
  const company_alias = data?.data?.product?.company?.alias;
  const [disable, setDisable] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const enquiryId = useUrlQuery("enquiryId");
  const [successBajajDocUpload, setSuccessBajajDocUpload] = useState(0);
  const { doc_length } = useSelector(state => state.proposalForm);
  useEffect(() => {
    if (doc_length == successBajajDocUpload) {
      setDisable(false);
    }
  }, [successBajajDocUpload]);
  useEffect(() => {
    dispatch(updateProposal(proposal_status.DOCUMENT_PENDING));
  }, []);
  useEffect(() => {
    api.post("companies/investment-quotes", {
      company_alias: company_alias,
    });
  }, []);
  const validFileTypes =
    company_alias === "icici_pru"
      ? "JPG, JPEG, PDF, PNG, BMP, TIFF"
      : company_alias === "tata_aia"
      ? "JPG, PDF"
      : "JPEG, JPG, PDF, TIF, TIFF";
  return (
    <div>
      <Navbar />

      <Outer>
        <div className="col">
          <RiderNewPlanCard hideBuy />
        </div>
        <div>
          <UploadPanel>
            {/* ============================== HEADINGS =============================== */}
            <div className="col-md-12 panel_heading ">
              <p className="text-center">
                Almost done! Please upload documents.
              </p>
            </div>
            <div className="col-md-12 panel_heading_desc">
              <p>
                Please upload {validFileTypes} file types only size (upto 5MB)
              </p>
            </div>
            {/* ======================= accordions contain forms =================== */}
            <>
              <FormByIC
                company_alias={company_alias}
                setDisable={setDisable}
                disable={disable}
                successBajajDocUpload={successBajajDocUpload}
                setSuccessBajajDocUpload={setSuccessBajajDocUpload}
              />
              {/* ===================== agree confirmation section ================= */}
              <div className="text-center panel-footer">
                <p>*All fields are required</p>
                <p>*File should not be password protected.</p>
                <div className="col-md-4 mx-auto">
                  <SubmitButton
                    disabled={Object.values(disable).includes(true) || false}
                    onClick={async () => {
                      dispatch(
                        updateProposal(proposal_status.DOCUMENT_SUBMITTED),
                      );
                      await api
                        .get("investment/after-payment")
                        .then(({ data }) => {
                          if (data.data.medicalStatus) {
                            navigate(`/confirm?enquiryId=${enquiryId}`);
                          } else navigate(`/success?enquiryId=${enquiryId}`);
                        })
                        .catch(err => console.error(err));
                    }}
                    className="btn"
                  >
                    SUBMIT
                  </SubmitButton>
                </div>
              </div>
            </>
          </UploadPanel>
        </div>
      </Outer>
    </div>
  );
};

export default DocumentUploadPage;

const Outer = styled.div`
  width: 1200px;
  display: flex;
  margin: 20px auto;
  font-family: Inter;
  justify-content: center;
  ${
    "" /* ${mq.sm} {
    width: 100%;
  } */
  }
`;

const SubmitButton = styled.button`
  ${
    "" /* ${mq.sm} {
    width: 50% !important;
    border-radius: 12px !important;
  } */
  }
`;

import { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { BsBookmark } from "react-icons/bs";
import { BsFillBookmarkFill } from "react-icons/bs";
import * as mq from "../../../styles/mediaQueries";
import "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import {
  setShortListedPlan,
  removeShortListedPlan,
  removeShortListedPlansData,
  setShortListedPlansData,
  removeShortListedPlanTemp,
  setShortListedPlanTemp,
} from "../Quotes.slice";
import { updateEnquiries } from "../../Landing/input.slice";
import { OverlayTrigger } from "react-bootstrap";
import renderTooltip from "../../../components/Tooltip/Tooltip";

export default function BookmarkQuote({ investmentId, plans }) {
  const dispatch = useDispatch();
  const { ShortListedPlan, ShortListedPlansData } = useSelector(
    state => state.quotes,
  );

  const onSwitch = () => {
    if (ShortListedPlan?.includes(investmentId)) {
      dispatch(removeShortListedPlan(investmentId));
      dispatch(removeShortListedPlansData(investmentId));
      dispatch(removeShortListedPlanTemp(investmentId));
      const afterIdRemovedArray = ShortListedPlan?.filter(
        item => item !== plans?.product?.id,
      );
      dispatch(
        updateEnquiries({
          input: {
            shortlistedIds: afterIdRemovedArray,
          },
        }),
      );
    } else {
      dispatch(setShortListedPlan(investmentId));
      dispatch(setShortListedPlansData(plans));
      dispatch(setShortListedPlanTemp(plans));
      dispatch(
        updateEnquiries({
          input: {
            shortlistedIds: [...ShortListedPlan, plans?.product?.id],
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (ShortListedPlan?.includes(investmentId)) {
      const [p] = ShortListedPlansData.filter(
        item => item?.product?.id === investmentId,
      );
      if (p !== plans) {
        dispatch(removeShortListedPlansData(investmentId));
        dispatch(removeShortListedPlansData(investmentId));
        dispatch(setShortListedPlansData(plans));
        dispatch(setShortListedPlanTemp(plans));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 0, hide: 100 }}
      overlay={renderTooltip({
        desc: `Add To ShortList`,
      })}
    >
      <Checkbox
        css={`
          &.MuiCheckbox-root {
            padding: 0px !important;
            &:hover {
              background: #ffff;
            }
          }
        `}
        size="large"
        icon={
          <BsBookmark
            className="bookmark-icon"
            css={`
              color: black;
              height: 18px;
              width: 18px;
              ${mq.lg} {
                height: 18px;
                width: 18px;
              }
              ${mq.xs} {
                height: 18px;
                width: 18px;
              }
            `}
          />
        }
        onClick={onSwitch}
        checkedIcon={
          <BsFillBookmarkFill
            className="bookmark-icon"
            css={`
              height: 18px;
              width: 18px;
              ${mq.lg} {
                height: 18px;
                width: 18px;
              }
              ${mq.xs} {
                height: 18px;
                width: 18px;
              }
            `}
          />
        }
        checked={ShortListedPlan?.includes(investmentId) ? true : false}
      />
    </OverlayTrigger>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-useless-computed-key */
import "styled-components/macro";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetCartQuery,
  useGetEnquiriesQuery,
  useGetProposalDataQuery,
} from "../../../../rider/services/rider";
import moment from "moment";
import {
  checkDocType,
  checkSize,
  convert,
} from "../../../../DocumentUpload/doc-utils";
import {
  clearDocumentUploadLoading,
  initDocumentUploadLoading,
} from "../../../ProposalPage.slice";
import { uploadDoc } from "../../../serviceApi";
import { toast } from "react-toastify";
import {
  ErrorMsg,
  FileInputWrapper,
  HR,
  UploadWrap,
  UploaderBody,
} from "../../../ProposalPage.style";
import Select from "../../../components/Select";
import {
  addressProofOptions,
  ageProofOptions,
  idProofOption,
  incomeProofOption,
} from "../../../components/options/bajaj/occupationDetailsOptions";
import ButtonLoader from "../../../../../components/Loaders/ButtonLoader/ButtonLoader";

const BajajDocumentUpload = ({
  setDisable,
  disable,
  setSuccessBajajDocUpload,
  successBajajDocUpload,
}) => {
  // Queries
  const { data, isLoading: isEnquiryLoading } = useGetEnquiriesQuery();
  const { data: cartData } = useGetCartQuery();
  const { data: proposalData } = useGetProposalDataQuery();
  const occupationData = proposalData?.data["Insured Occupation Details"];
  const {
    doc_required,
    isLoading,
    proposal_reference_id,
    isDocumentUploadLoading,
  } = useSelector(state => state.proposalForm);
  const mainDoc = [
    "address_proof",
    "age_proof",
    "id_proof",
    "income_proof",
    "recent_photograph",
  ];
  const appNo = proposal_reference_id;
  const requiredDocuments =
    isLoading === false
      ? doc_required?.map(val => val?.document_type)
      : mainDoc;
  // useState
  const [documents, setDocuments] = useState({});
  const [errors, setErrors] = useState({});
  const [previews, setPreviews] = useState({});
  const [income, setIncome] = useState();
  const [uploaded, setUploaded] = useState();
  const currentDate = moment().format("DDMMYYYY");
  const [docName, setDocName] = useState({
    address_proof: `${appNo}_AddressProof_${currentDate}_1.pdf`,
    age_proof: `${appNo}_AgeProof_${currentDate}_2.pdf`,
    id_proof: `${appNo}_IdProof_${currentDate}_3.pdf`,
    income_proof: `${appNo}_IncomeProof_${currentDate}_4.pdf`,
    recent_photograph: `${appNo}_recentPhotograph_${currentDate}_5.pdf`,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEnquiryLoading && data) {
      setIncome(occupationData?.income_proof);
      setDocuments({
        ...documents,
        ["Address Proof"]: {
          ...(documents["Address Proof"] ? documents["Address Proof"] : {}),
          file_type: occupationData?.address_proof,
        },
        ["Age Proof"]: {
          ...(documents["Age Proof"] ? documents["Age Proof"] : {}),
          file_type: occupationData?.age_proof,
        },
        ["ID Proof"]: {
          ...(documents["ID Proof"] ? documents["ID Proof"] : {}),
          file_type: occupationData?.id_proof,
        },
        ["Income Proof"]: {
          ...(documents["Income Proof"] ? documents["Income Proof"] : {}),
          file_type: occupationData?.income_proof,
        },
      });
    }
  }, [isEnquiryLoading, occupationData]);

  useEffect(() => {
    if (
      documents["Age Proof"]?.upload_file === undefined ||
      documents["ID Proof"]?.upload_file === undefined ||
      documents["Address Proof"]?.upload_file === undefined ||
      documents["Income Proof"]?.upload_file === undefined
    ) {
      return setDisable(prev => ({
        ...prev,
        ["Age Proof"]: true,
        ["ID Proof"]: true,
        ["Address Proof"]: true,
        ["Income Proof"]: true,
      }));
    }
  }, []);

  // Blob
  const createPreview = fileInput => {
    const blob = new Blob(fileInput?.target?.files, {
      type: fileInput?.target?.files[0]?.type,
    });
    return URL.createObjectURL(blob);
  };
  // Remove Document
  const removeDocument = document => {
    setDocuments(prev => {
      let temp = { ...prev };
      temp[document].upload_file = null;
      temp[document].upload_file_id = null;
      temp[document].upload_file_name = null;
      return temp;
    });
    setPreviews(prev => {
      let temp = { ...prev };
      temp[document] = {};
      return temp;
    });
    setDisable(prev => ({ ...prev, [document]: true }));
  };

  // onSubmit
  const onSubmit = async (docObject, item, key) => {
    const newData = await convert(docObject.upload_file);
    try {
      await uploadDoc({
        company_alias: cartData?.product?.company?.alias,
        upload_file_name: docObject?.upload_file_name,
        upload_file_id: docObject?.upload_file_id,
        upload_file: newData.split(",")[1],
        shortname: docObject?.file_type
          ? docObject?.file_type
          : "recent_photograph",
        docType: "IP",
      });
      setDisable(prev => ({ ...prev, [item]: false }));
      toast.success(`${item} Uploaded!`, {
        toastId: item,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      setSuccessBajajDocUpload(successBajajDocUpload + 1);
    } catch (err) {
      console.error(err);
      toast.error(`${item} upload failed`, {
        toastId: `${item}_error`,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      setDocName(prev => ({
        ...prev,
        [key]: `${appNo}_${item.replace(" ", "")}_${currentDate}_2.pdf`,
      }));
      setDocuments(prev => ({
        ...prev,
        [item]: { file_type: documents[item]?.file_type },
      }));
      setPreviews(prev => ({ ...prev, [item]: {} }));
    } finally {
      dispatch(clearDocumentUploadLoading());
    }
  };
  // Final Return
  return (
    <div className="doc-form__wrapper my-4">
      {/* ================================= Age ============================ */}
      {requiredDocuments.includes("age_proof") && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["Age Proof"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={ageProofOptions}
                label={"Age Proof*"}
                selectedValue={occupationData?.age_proof}
                noSearch
                readOnly={true}
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.age_proof}
                onClick={e => (e.target.value = null)}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["Age Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setUploaded("Age Proof");
                    dispatch(initDocumentUploadLoading());
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 1,
                      upload_file_name: docName.age_proof,
                      file_type: occupationData?.age_proof,
                    };
                    setDocName(prev => ({
                      ...prev,
                      age_proof: `${appNo}_AgeProof_${currentDate}_2.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setErrors({ ...errors, ["Age Proof"]: "" });
                    setDocuments({
                      ...documents,
                      ["Age Proof"]: {
                        ...(documents["Age Proof"]
                          ? documents["Age Proof"]
                          : {}),
                        ...docObject,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Age Proof"]: {
                        ...(previews["Age Proof"] ? previews["Age Proof"] : {}),
                        blob_file: createPreview(e),
                      },
                    });
                    onSubmit(docObject, "Age Proof", `age_proof`);
                  }
                }}
              />
              {/* <span className="w-100 file_name_container">
                {docName.age_proof}
              </span> */}
            </FileInputWrapper>
            {!documents["Age Proof"]?.upload_file ? (
              <label className="Choose_file_btn" htmlFor={docName.age_proof}>
                Choose a file
              </label>
            ) : uploaded === "Age Proof" && isDocumentUploadLoading ? (
              <label className="Choose_file_btn">
                <ButtonLoader />
              </label>
            ) : (
              <>
                {previews["Age Proof"]?.blob_file && (
                  <a
                    className={`col-4 upload_btn btn `}
                    href={previews["Age Proof"]?.blob_file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                )}
                {documents["Age Proof"]?.upload_file && (
                  <i
                    className="fas fa-trash-alt trash-icon"
                    onClick={() => removeDocument("Age Proof")}
                  />
                )}
              </>
            )}
          </div>
          {errors["Age Proof"] && <ErrorMsg>{errors["Age Proof"]}</ErrorMsg>}
        </UploaderBody>
      )}
      <HR style={{ marginTop: "12px" }} />
      {/* ================================= ID ============================ */}
      {requiredDocuments.includes("id_proof") && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["ID Proof"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={idProofOption}
                label={"ID Proof*"}
                selectedValue={occupationData?.id_proof}
                readOnly={true}
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.id_proof}
                onClick={e => (e.target.value = null)}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["ID Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setUploaded("ID Proof");
                    dispatch(initDocumentUploadLoading());
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 2,
                      upload_file_name: docName.id_proof,
                      file_type: occupationData?.id_proof,
                    };
                    setDocName(prev => ({
                      ...prev,
                      id_proof: `${appNo}_IDProof_${currentDate}_3.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setErrors({ ...errors, ["ID Proof"]: "" });
                    setDocuments({
                      ...documents,
                      ["ID Proof"]: {
                        ...(documents["ID Proof"] ? documents["ID Proof"] : {}),
                        ...docObject,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["ID Proof"]: {
                        ...(previews["ID Proof"] ? previews["ID Proof"] : {}),
                        blob_file: createPreview(e),
                      },
                    });

                    onSubmit(docObject, "ID Proof", `id_proof`);
                  }
                }}
              />
              {/* <span className="w-100 file_name_container">
                {docName.id_proof}
              </span> */}
            </FileInputWrapper>

            {!documents["ID Proof"]?.upload_file ? (
              <label className="Choose_file_btn" htmlFor={docName.id_proof}>
                Choose a file
              </label>
            ) : uploaded === "ID Proof" && isDocumentUploadLoading ? (
              <label className="Choose_file_btn">
                <ButtonLoader />
              </label>
            ) : (
              <>
                {previews["ID Proof"]?.blob_file && (
                  <a
                    className={`col-4 upload_btn btn `}
                    href={previews["ID Proof"]?.blob_file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                )}

                {documents["ID Proof"]?.upload_file && (
                  <i
                    className="fas fa-trash-alt trash-icon"
                    onClick={() => removeDocument("ID Proof")}
                  />
                )}
              </>
            )}
          </div>
          {errors["ID Proof"] && <ErrorMsg>{errors["ID Proof"]}</ErrorMsg>}
        </UploaderBody>
      )}
      <HR style={{ marginTop: "10px" }} />
      {/* ================================= Address ============================ */}
      {requiredDocuments.includes("address_proof") && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["Address Proof"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={addressProofOptions}
                label={"Address Proof*"}
                selectedValue={occupationData?.address_proof}
                readOnly={true}
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.address_proof}
                onClick={e => (e.target.value = null)}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["Address Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setUploaded("Address Proof");
                    dispatch(initDocumentUploadLoading());
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 3,
                      upload_file_name: docName.address_proof,
                      file_type: occupationData?.address_proof,
                    };
                    setDocName(prev => ({
                      ...prev,
                      address_proof: `${appNo}_AddressProof_${currentDate}_1.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setErrors({ ...errors, ["Address Proof"]: "" });
                    setDocuments({
                      ...documents,
                      ["Address Proof"]: {
                        ...(documents["Address Proof"]
                          ? documents["Address Proof"]
                          : {}),
                        ...docObject,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Address Proof"]: {
                        ...(previews["Address Proof"]
                          ? previews["Address Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                    onSubmit(docObject, "Address Proof", `address_proof`);
                  }
                }}
              />
              {/* <span className="w-100 file_name_container">
                {docName.address_proof}
              </span> */}
            </FileInputWrapper>

            {!documents["Address Proof"]?.upload_file ? (
              <label
                className="Choose_file_btn"
                htmlFor={docName.address_proof}
              >
                Choose a file
              </label>
            ) : uploaded === "Address Proof" && isDocumentUploadLoading ? (
              <label className="Choose_file_btn">
                <ButtonLoader />
              </label>
            ) : (
              <>
                {previews["Address Proof"]?.blob_file && (
                  <a
                    className={`col-4 upload_btn btn `}
                    href={previews["Address Proof"]?.blob_file}
                    target="_blank"
                  >
                    Preview
                  </a>
                )}
                {documents["Address Proof"]?.upload_file && (
                  <i
                    className="fas fa-trash-alt trash-icon"
                    onClick={() => removeDocument("Address Proof")}
                  />
                )}
              </>
            )}
          </div>
          {errors["Address Proof"] && (
            <ErrorMsg>{errors["Address Proof"]}</ErrorMsg>
          )}
        </UploaderBody>
      )}
      <HR style={{ marginTop: "12px" }} />
      {/* ================================= Income ============================ */}
      {requiredDocuments.includes("income_proof") && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["Income Proof"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={incomeProofOption}
                label={"Income Proof*"}
                selectedValue={income}
                readOnly={true}
                handleChange={value =>
                  setDocuments({
                    ...documents,
                    ["Income Proof"]: {
                      ...(documents["Income Proof"]
                        ? documents["Income Proof"]
                        : {}),
                      file_type: value,
                    },
                  })
                }
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.income_proof}
                onClick={e => (e.target.value = null)}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["Income Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setUploaded("Income Proof");
                    dispatch(initDocumentUploadLoading());
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 4,
                      upload_file_name: docName.income_proof,
                      file_type: occupationData?.income_proof,
                    };
                    setDocName(prev => ({
                      ...prev,
                      income_proof: `${appNo}_IncomeProof_${currentDate}_4.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setErrors({ ...errors, ["Income Proof"]: "" });
                    setDocuments({
                      ...documents,
                      ["Income Proof"]: {
                        ...(documents["Income Proof"]
                          ? documents["Income Proof"]
                          : {}),
                        ...docObject,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Income Proof"]: {
                        ...(previews["Income Proof"]
                          ? previews["Income Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });

                    onSubmit(docObject, "Income Proof", `income_proof`);
                  }
                }}
              />
              {/* <span className="w-100 file_name_container">
                {docName.income_proof}
              </span> */}
            </FileInputWrapper>

            {!documents["Income Proof"]?.upload_file ? (
              <label className="Choose_file_btn" htmlFor={docName.income_proof}>
                Choose a file
              </label>
            ) : uploaded === "Income Proof" && isDocumentUploadLoading ? (
              <label className="Choose_file_btn">
                <ButtonLoader />
              </label>
            ) : (
              <>
                {previews["Income Proof"]?.blob_file && (
                  <a
                    className={`col-4 upload_btn btn `}
                    href={previews["Income Proof"]?.blob_file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                )}
                {documents["Income Proof"]?.upload_file && (
                  <i
                    className="fas fa-trash-alt trash-icon"
                    onClick={() => removeDocument("Income Proof")}
                  />
                )}
              </>
            )}
          </div>
          {errors["Income Proof"] && (
            <ErrorMsg>{errors["Income Proof"]}</ErrorMsg>
          )}
        </UploaderBody>
      )}
      <HR style={{ marginTop: "12px" }} />
      {/* ================================= Recent Photograph ============================ */}
      {requiredDocuments.includes("recent_photograph") && (
        <>
          <h6>Recent Photograph</h6>
          <UploaderBody
            className="col-md-12 select_wrapper"
            active={documents["Recent Photograph"]?.upload_file ? true : false}
          >
            <div className="additional-body">
              <FileInputWrapper className="col-md-6 additional-doc-input">
                <input
                  type="file"
                  name="myfile"
                  id={docName.recent_photograph}
                  onClick={e => (e.target.value = null)}
                  onChange={e => {
                    if (
                      !checkSize(e, 5) ||
                      !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                    ) {
                      setErrors({
                        ...errors,
                        ["Recent Photograph"]: "File type or size not allowed",
                      });
                    } else {
                      setUploaded("Recent Photograph");
                      dispatch(initDocumentUploadLoading());
                      const docObject = {
                        upload_file: e.target.files[0],
                        upload_file_id: 5,
                        upload_file_name: docName.recent_photograph,
                      };
                      setDocName(prev => ({
                        ...prev,
                        recent_photograph: `${appNo}_recentPhotograph_${currentDate}_5.${
                          e.target.files[0]?.name?.split(".")?.reverse()[0]
                        }`,
                      }));
                      setErrors({ ...errors, ["Recent Photograph"]: "" });
                      setDocuments({
                        ...documents,
                        ["Recent Photograph"]: {
                          ...(documents["Recent Photograph"]
                            ? documents["Recent Photograph"]
                            : {}),
                          ...docObject,
                        },
                      });
                      setPreviews({
                        ...previews,
                        ["Recent Photograph"]: {
                          ...(previews["Recent Photograph"]
                            ? previews["Recent Photograph"]
                            : {}),
                          blob_file: createPreview(e),
                        },
                      });
                      onSubmit(
                        docObject,
                        "Recent Photograph",
                        `recent_photograph`,
                      );
                    }
                  }}
                />
                {/* <span className="w-100 file_name_container">
                  {docName.recent_photograph}
                </span> */}
              </FileInputWrapper>
              {!documents["Recent Photograph"]?.upload_file ? (
                <label
                  className="Choose_file_btn"
                  htmlFor={docName.recent_photograph}
                >
                  Choose a file
                </label>
              ) : uploaded === "Recent Photograph" &&
                isDocumentUploadLoading ? (
                <label className="Choose_file_btn">
                  <ButtonLoader />
                </label>
              ) : (
                <>
                  {previews["Recent Photograph"]?.blob_file && (
                    <a
                      className={`col-4 upload_btn btn `}
                      href={previews["Recent Photograph"]?.blob_file}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Preview
                    </a>
                  )}
                  {documents["Recent Photograph"]?.upload_file && (
                    <i
                      className="fas fa-trash-alt trash-icon"
                      onClick={() => removeDocument("Recent Photograph")}
                    />
                  )}
                </>
              )}
            </div>
            {errors["Recent Photograph"] && (
              <ErrorMsg rel>{errors["Recent Photograph"]}</ErrorMsg>
            )}
          </UploaderBody>
        </>
      )}
    </div>
  );
};

export default BajajDocumentUpload;

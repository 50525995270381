import React, { useEffect, useState } from "react";
import { SiWhatsapp } from "react-icons/si";
import { useSelector } from "react-redux";
import { useNumberInput } from "../../../../utils";
import ShareViaForm from "./ShareForm";
import TextInput from "../EditDetailsSection/components/TextInput";
import { InputAdornment } from "@material-ui/core";
import { toast } from "react-toastify";
import { useShareQuoteMutation } from "../../../rider/services/rider";
import { selectSelectedQuotesToShare } from "../../Quotes.slice";
const WhatsAppShare = ({ mobile, imageUrl }) => {
  const { data } = useSelector(state => state.inputForm);
  const allProducts = useSelector(selectSelectedQuotesToShare);
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const [phone, handleChange] = useNumberInput(data.mobile);
  //   const addedRiders = useSelector(selectAddedRiders);

  const [error, setError] = useState(false);
  useEffect(() => {
    if (phone.length === 10) setError(false);
  }, [phone]);
  const handleSubmit = async evt => {
    evt.preventDefault();

    if (phone.length === 10) {
      setError(false);
      shareQuotes({
        purpose: "quote",
        groupCode: "10003511",
        mode: ["WHATSAPP"],
        image_to_send: imageUrl,
        via: "whatsapp",
        product_ids: allProducts,
        stage: "QUOTE",
        whatsapp: phone,
      }).then(data => {
        if (data.error) {
          toast.error("Sharing Failed!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          toast.success("Shared Successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });

      // const form = document.createElement("form");
      // form.setAttribute("action", "https://api.whatsapp.com/send");
      // form.setAttribute("target", "_blank");
      // form.setAttribute("method", "GET");

      // const phoneInput = document.createElement("input");
      // phoneInput.setAttribute("value", "91" + phone);
      // phoneInput.setAttribute("name", "phone");

      // const textInput = document.createElement("input");
      // textInput.setAttribute("value", window.location);
      // textInput.setAttribute("name", "text");

      // form.appendChild(phoneInput);
      // form.appendChild(textInput);

      // document.body.appendChild(form);
      // form.submit();
      // document.body.removeChild(form);
    } else setError("Please enter a valid whatsapp no.");
  };

  return (
    <ShareViaForm onSubmit={handleSubmit} isLoading={isLoading}>
      <TextInput
        label=" Whatsapp Number"
        variant="outlined"
        error={error}
        value={phone}
        name="phone"
        onChange={handleChange}
        inputProps={{ minLength: 2, maxLength: 10, pattern: "[0-9]*" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SiWhatsapp className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
      {/* <ShareFormInput
        type="tel"
        maxLength={10}
        value={phone}
        onChange={handleChange}
        placeholder="Enter your Whatsapp Number"
      /> */}
    </ShareViaForm>
  );
};

export default WhatsAppShare;

/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";
import PlanDetails from "./PlanDetails";
import AdditionalFeatures from "./AdditionalFeatures";
import AboutComany from "./AboutComany";
import { DetailsFooter } from "./DetailsFooter";
import CancelCircleIcon from "../../../../assets/svg/CancelCircleIcon";
import { useDispatch, useSelector } from "react-redux";
import { selectICLogo } from "../../../frontendBoot/frontendBoot.slice";
import * as mq from "../../../../styles/mediaQueries";
import BenefitTimeline from "./BenefitTimeline";
import PlanDetailsMobile from "./PlanDetailsMobile";
import { clearKnowMoreData, setShowGetDetails } from "../../Quotes.slice";
import MobileUIHeader from "./MobileUIHeader";
import useMediaQuery from "../../../../custom-hooks/useMediaQuery";
import AddonCoveragesWrap from "./AddOnCoverage/AddonCoveragesWrap";
import { useGetBiQuery } from "../../../rider/services/rider";
const GetDetails = ({ company_alias, show, setShow, quoteData }) => {
  const matches = useMediaQuery(`${mq.mdForHook}`);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(clearKnowMoreData());
    setShow(false);
    dispatch(setShowGetDetails(false));
  };
  const { enquiryLoading } = useSelector(state => state.inputForm);
  const [currentSection, setCurrentSection] = useState(1);
  const logo = useSelector(selectICLogo(quoteData?.company_alias));
  const prevNum = React.useRef();
  const swipe = "forward";
  const slideCss = `
  ${mq.md} {
    width: 100%;
    animation-duration: 0.45s;
    animation-name: ${
      currentSection < prevNum.current ? "slideLeft" : "slideRight"
    };
    @keyframes slideLeft {
      0% {transform: translateX(-300px);}
      100% {transform: translate(0);}
    }
    @keyframes slideRight {
      0% {transform: translateX(300px);}
      100% {transform: translate(0);}
    }
  }
  `;

  const {
    data: biData,
    isLoading: isBiLoading,
    isFetching: isBiFetching,
  } = useGetBiQuery(
    { company_alias },
    {
      skip: enquiryLoading || !show || company_alias === "max_life",
      refetchOnMountOrArgChange: true,
    },
  );
  return (
    <div>
      <div>
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <Modal
            show={show}
            onHide={handleClose}
            css={matches ? modalMobileCss : modalCss}
            fullscreen={matches}
          >
            <Modal.Header>
              {matches && (
                <MobileUIHeader
                  quoteData={quoteData}
                  currentSection={currentSection}
                  setCurrentSection={setCurrentSection}
                  prevNum={prevNum}
                />
              )}
              <CancelCircleIcon width="30" onClick={() => handleClose()} />
              <ButtonGroup id="btn-group">
                <ButtonInd
                  className={currentSection === 1 && "active"}
                  onClick={() => setCurrentSection(1)}
                >
                  Benefit Timeline
                </ButtonInd>
                <ButtonInd
                  className={currentSection === 2 && "active"}
                  onClick={() => setCurrentSection(2)}
                >
                  Plan Details
                </ButtonInd>
                <ButtonInd
                  className={currentSection === 3 && "active"}
                  onClick={() => setCurrentSection(3)}
                >
                  Additional Benefits
                </ButtonInd>
                <ButtonInd
                  className={currentSection === 4 && "active"}
                  onClick={() => setCurrentSection(4)}
                >
                  Add On Coverage
                </ButtonInd>
                <ButtonInd
                  className={currentSection === 5 && "active"}
                  onClick={() => setCurrentSection(5)}
                >
                  About Company
                </ButtonInd>
              </ButtonGroup>
            </Modal.Header>
            <ModalBody>
              {currentSection === 1 && (
                <div css={slideCss} className={swipe}>
                  <BenefitTimeline
                    quoteData={quoteData}
                    biData={biData}
                    isBiLoading={isBiFetching || isBiLoading}
                  />
                </div>
              )}
              {currentSection === 2 && (
                <div css={slideCss} className={swipe}>
                  <PlanDetails />
                  <PlanDetailsMobile quoteData={quoteData} />
                </div>
              )}
              {currentSection === 3 && (
                <div css={slideCss} className={swipe}>
                  <AdditionalFeatures />
                </div>
              )}
              {currentSection === 4 && (
                <div css={slideCss} className={swipe}>
                  <AddonCoveragesWrap quoteData={quoteData} />
                </div>
              )}
              {currentSection === 5 && (
                <div css={slideCss} className={swipe}>
                  <AboutComany />
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <DetailsFooter
                imageUrl={logo}
                company_alias={company_alias}
                quoteData={quoteData}
                isBiLoading={isBiFetching || isBiLoading || enquiryLoading}
              />
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  );
};

const modalCss = `
.modal{
  height:100vh;
  width:100vw;
  overflow:hidden;
  z-index:1085;
}
.modal-header{
  position: sticky;
  top: 0;
  height: 90px;
  overflow: none;
  border: 0px;
}
.fade{
  height:100vh;
  width:100vw;
  overflow:hidden;
}
.modal-content{
height:100%;
width:100%;
position:relative;
}
.modal-dialog {
height:93.9%;
margin-bottom:0px;
margin-top:20px;
width:92%;
max-width:92%;

${mq.lg} {
  width:94.5%;
  max-width:94.5%;
}
}
`;

const modalMobileCss = `
.modal-header{
 padding:0px;
 display:flex;
 flex-direction:column;
 height: 160px !important;
}
`;

// Modal Header
const ButtonGroup = styled.div`
  width: 93%;
  margin: auto;
  height: 58px;
  background-color: var(--primary-color-light);
  border-radius: 45px;
  display: flex;
  justify-content: space-between;
  ${mq.md} {
    display: none;
  }
  ${mq.lg} {
    height: 50px;
  }
`;

const ButtonInd = styled.button`
  height: 58px;
  width: 19%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  box-sizing: border-box;
  border-radius: 45px;
  outline: none;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  &.active {
    background-color: rgba(0, 86, 254, 0.15);
    border: 1px solid var(--primary-color);
    transition: border 0.5s, all 0.3s ease-in-out;
  }

  ${mq.lg} {
    height: 50px;
    font-size: 14px;
  }
`;

//Modal Body

const ModalBody = styled.div`
  height: calc(100% - 165px);
  padding-bottom: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  width: 100%;
  margin: 0px auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }

  ${mq.lg} {
    height: calc(100% - 155px);
  }
  ${mq.md} {
    height: calc(100% - 216px);
    box-sizing: border-box;
  }
`;

export const ModalBodyInner = styled.div`
  width: 85%;
  padding-top: 10px;
  margin: auto;
  height: fit-content;
  ${mq.lg} {
    width: 90%;
  }
  ${mq.md} {
    width: 90%;
  }
`;

// Modal Footer

const ModalFooter = styled.div`
  position: absolute;
  bottom: 0px;
  height: px;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border-top: 1px solid var(--border-light);
  padding: 0px 0px;
  bottom: 10px;
  margin-top: 10px;
  ${mq.lg} {
    height: 65px;
    position: absolute;
    bottom: 0px;
  }
  ${mq.md} {
    height: 60px;
  }
`;

export default GetDetails;

import "styled-components/macro";
import { Logo, NavWrapper } from "./components/navbar/Navbar-Component";
import styled from "styled-components";

function ErrorBoundaryComp({ error }) {
  return (
    <>
      <ErrorNav />
      <ErrorWrapper role="alert">
        <ErrorMessage>
          {process.env.NODE_ENV === "development"
            ? "An Error Occurred"
            : "OOPS Something Went Wrong !"}
        </ErrorMessage>
        {process.env.NODE_ENV === "development" && (
          <ErrorSubMessage>{error.message}, Check console</ErrorSubMessage>
        )}
        <ButtonWrapper>
          <RefreshButton onClick={() => window.location.reload(false)}>
            {"Try Again"}
          </RefreshButton>
          <RefreshButton
            onClick={() => {
              window.history.go(-1);
            }}
          >
            {"Go Back"}
          </RefreshButton>
        </ButtonWrapper>
      </ErrorWrapper>
    </>
  );
}

function ErrorNav() {
  return (
    <>
      <div className="navbar border-bottom p-7 flex-nowrap">
        <NavWrapper>
          <Logo />
        </NavWrapper>
      </div>
    </>
  );
}

const ErrorWrapper = styled.div`
  /* height: fit-content; */
  width: 100%;
  overflow: none;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-family: "Inter";
  font-weight: 600;
  font-size: 30px;
  text-transform: capitalize;
`;

const ErrorSubMessage = styled.div`
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
`;

const RefreshButton = styled.button`
  outline: none;
  border: none;
  color: white;
  background: var(--primary-color);
  height: 50px;
  width: 200px;
  display: flex;
  font-size: 18px;
  font-family: "Inter";
  font-weight: 600;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
`;

export default ErrorBoundaryComp;

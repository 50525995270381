export const aadhaarRegex = /^[2-9]{1}[0-9]{11}$|^$/;
export const panRegex =
  /^([a-zA-Z]{3}[P]{1}[a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})$|^$/;
export const drivingLicenseRegex =
  /^([A-Z]{2}[0-9]{2})( )((19|20)[0-9][0-9])[0-9]{7}$/;
export const passportRegex = /^[a-zA-Z]{1}[0-9]{7}$|^$/;
export const voterIdRegex = /^([a-zA-Z]){3}([0-9]){7}?$/g;
export const nationalPopulationRegistrarRegex = /^[A-Z]{2}\d{6}[A-Z]{2}$/;
export const jobCardRegex = /^[A-Z]{2}\d{3}-\d{5}$/;
export const pinCodeRegex = /^[1-9][0-9]{5}$/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const mobileRegex = /^[6-9]\d{9}$/;
export const amountRegex = /^[1-9][0-9]*$/;
export const ifscRegex = /^([a-zA-Z]{4}[0]{1})([0-9a-zA-Z]{6})$|^$/;

import React from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import magic from "../../../assets/images/magic-wand.svg";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router";
import TraceId from "../../QuotesPage/components/TraceId";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import * as mq from "../../../styles/mediaQueries";

function CompareHeader() {
  const navigate = useNavigate();
  const matches = useMediaQuery(`${mq.smForHook}`);
  return (
    <div className="container">
      <BoxStyled>
        <StackStyled
          spacing={0.5}
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoIosArrowRoundBack
            fontSize="1.5rem"
            style={{
              color: "#367CFF",
              background: "#F6F8FC",
              borderRadius: "50%",
              margin: "0",
            }}
          />
          <Typography variant="p">Go Back</Typography>
        </StackStyled>
      </BoxStyled>

      <HeaderBoxWrapper>
        <HeaderBox>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <img src={magic} alt="magic-band" height={30} width={30} />
            <Typography variant="h6" fontWeight={600}>
              Create Custom Comparison :
            </Typography>
          </Stack>
          <Stack>
            <TraceId label={matches ? "CRN : " : "CRN/Trace Id : "} />
          </Stack>
        </HeaderBox>
      </HeaderBoxWrapper>
    </div>
  );
}
export default CompareHeader;

const BoxStyled = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const StackStyled = styled(Stack)`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
`;

const HeaderBoxWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { questionFormSchema } from "./validation";
import { setData } from "../../../Landing/input.slice";
import { useInputFormsHook } from "../../hooks";

const useQuestionForm = (current, setCurrent) => {
  const { getQuestionFormData, setQuestionFormData } = useInputFormsHook();
  const dispatch = useDispatch();
  const {
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(questionFormSchema),
    defaultValues: getQuestionFormData(),
    mode: "all",
  });

  const [risk, setRisk] = useState(getQuestionFormData().risk_profile);
  const [life, setLife] = useState(getQuestionFormData().life_stage);
  const [financial, setFinancial] = useState(
    getQuestionFormData().financial_and_familyGoal,
  );

  const onSubmit = data => {
    setCurrent(current + 1);
    dispatch(setData({ suitability: { ...data } }));
    setQuestionFormData(data);
  };

  return {
    errors,
    setValue,
    onSubmit,
    handleSubmit,
    risk,
    setRisk,
    life,
    setLife,
    financial,
    setFinancial,
  };
};

export default useQuestionForm;

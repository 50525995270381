import React from "react";
import styled from "styled-components/macro";
import logo from "../../../../../assets/images/logo/logo.png";
import * as mq from "../../../../../styles/mediaQueries";
import PersonalDetails from "../../EditDetailsSection/components/PersonalDetails";

function NavbarMobile() {
  return (
    <NavMobileWrapper>
      <a href="/">
        <NavMobileLogo />
      </a>
      <PersonalDetails />
    </NavMobileWrapper>
  );
}

const NavMobileWrapper = styled.div`
  margin: 0px;
  width: 100%;
  padding: 10px 15px 5px 15px;
  display: flex;
  justify-content: space-between;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 8px 0px;
  @media screen and (max-width: 768px) {
    padding: 10px 12px 10px 12px;
  }
`;

const NavMobileLogo = styled.img`
  height: 25px;
  content: url(${logo});
  ${mq.sm} {
    // height: 23px;
  }
  ${mq.md} {
    width: 136px;
    height: auto;
    // height: 23px;
  }
  ${mq.xs} {
    height: 20px;
  }
  @media screen and (max-width: 768px) {
    width: 136px;
    height: auto;
  }
`;

export default NavbarMobile;

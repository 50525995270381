import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setDefaultFilters } from "../frontendBoot/frontendBoot.slice";
import { patchEnquiries } from "../Landing/serviceApi";
import {
  replaceQuotes,
  clearQuotes,
  clearShortListedPlansData,
  resetPolicyDefermentPayoutFilters,
} from "../QuotesPage/Quotes.slice";
import { setFilters } from "../Landing/input.slice";

const filterSlice = createSlice({
  name: "filterStore",
  initialState: {
    getMoneyAs: "L",
    investedAmount: "300000",
    investFor: "5",
    returnsAfter: "10",
    incomeFor: "3",
    payFrequency: "0",
    isFilterUpdating: false,
    defFilters: {},
  },
  reducers: {
    setDefFilters: (state, action) => {
      state.defFilters = action.payload;
    },
    setGetMoneyAs: (state, action) => {
      state.getMoneyAs = action.payload;
    },
    setReturnsAfter: (state, action) => {
      state.returnsAfter = action.payload;
    },
    setInvestedAmount: (state, action) => {
      state.investedAmount = action.payload;
    },
    setIncomeFor: (state, action) => {
      state.incomeFor = action.payload;
    },
    setInvestFor: (state, action) => {
      state.investFor = action.payload;
    },
    setPayFrequency: (state, action) => {
      state.payFrequency = action.payload;
    },
    initFilterUpdating: (state, { payload }) => {
      state.isFilterUpdating = true;
    },
    clearFilterUpdating: (state, { payload }) => {
      state.isFilterUpdating = false;
    },
  },
});

export const {
  setGetMoneyAs,
  resetDefFilters,
  setDefFilters,
  setIncomeFor,
  setInvestedAmount,
  setPayFrequency,
  setReturnsAfter,
  setInvestFor,
  initFilterUpdating,
  clearFilterUpdating,
} = filterSlice.actions;
let cancelPreviousFilterRequest = {};

export const changeFilter = (changedFilters, noRefetch) => {
  return async dispatch => {
    Object.keys(changedFilters).forEach(key => {
      cancelPreviousFilterRequest[key] && cancelPreviousFilterRequest[key]();
    });
    dispatch(resetPolicyDefermentPayoutFilters());
    try {
      dispatch(initFilterUpdating());
      const { data } = await patchEnquiries(
        { input: { ...changedFilters } },
        {
          cancelToken: new axios.CancelToken(function executor(c) {
            Object.keys(changedFilters).forEach(key => {
              cancelPreviousFilterRequest[key] = c;
            });
          }),
        },
      );
      const {
        gender,
        dob,
        pincode,
        tobacco_habit,
        occupation,
        education,
        annual_income,
        shortlistedIds,
        suitability,
        ...filters
      } = data?.data?.input;
      dispatch(setInvestFor(filters?.pay_term));
      dispatch(setReturnsAfter(filters?.policy_term));
      dispatch(setIncomeFor(filters?.income_for));
      dispatch(setInvestedAmount(filters?.investment_amount));
      dispatch(setPayFrequency(filters?.pay_frequency));
      dispatch(setGetMoneyAs(filters?.get_money_as));
      dispatch(setDefaultFilters(data?.investmentdefaultfilters));
      dispatch(
        setFilters({
          ...data?.data?.input,
          name: data?.data.name,
          email: data?.data.email,
          mobile: data?.data.mobile,
        }),
      );
      if (!noRefetch) {
        dispatch(replaceQuotes({}));
        dispatch(clearQuotes());
        dispatch(clearShortListedPlansData());
      }
    } catch (err) {
      console.error(err);
    }
  };
};
export default filterSlice.reducer;

export const selectFilters = state => state.filters;
export const selectInvestmentFilters = state => state.filters.filters;
export const selectDefaultFilters = state => state.filters.defaultFilters;
export const selectFilterOptions = state => state.filters.filterOptions;
export const selectSelectedInsurersFilter = state =>
  state.filters.filters.selected_insurers;

import { useEffect, useState } from "react";
import Navbar from "../../../../components/navbar/Navbar-Component";
import useMediaQuery from "../../../../custom-hooks/useMediaQuery";
import * as mq from "../../../../styles/mediaQueries";
import { BiArrowBack } from "react-icons/bi";
import Quotes from "../Quotes";
import "styled-components/macro";
import NavbarMobile from "../Mobile/components/NavbarMobile";
import useUrlQuery from "../../../../custom-hooks/useUrlQuery";
import { useNavigate } from "react-router";
import ComparePlanPopup from "../../../ComparePage/ComparePlanPopup-Component";
import { useDispatch, useSelector } from "react-redux";
import { setShortListedPlan } from "../../Quotes.slice";
import DifferentQuotes from "../DifferentQuotes";

export const ShortListedPlansPage = () => {
  const matches = useMediaQuery(`${mq.mdForHook}`);
  const [showAddOn, setShowAddOn] = useState(false);
  const enquiryId = useUrlQuery("enquiryId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.inputForm);
  const { ShortListedPlan } = useSelector(state => state.quotes);

  useEffect(() => {
    if (data.shortlistedIds) {
      data?.shortlistedIds?.map(id => {
        if (!ShortListedPlan?.includes(id)) {
          dispatch(setShortListedPlan(id));
        }
        return null;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.shortlistedIds]);

  return (
    <div>
      {matches ? <NavbarMobile /> : <Navbar />}
      {/* <Navbar /> */}
      <div
        css={`
          width: 1200px;
          display: flex;
          margin: 10px auto;
          font-family: "Inter";
          // padding: 0 15px;
          align-items: center;
          justify-content: center;
          position: relative;

          ${mq.sm} {
            width: 100%;
            padding: 0;
          }
        `}
      >
        <div
          css={`
            font-size: 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 20px;
            position: absolute;
            left: 0;
            ${mq.sm} {
              // padding-left: 14px;
              // height: 25px;
              // margin-top: 10px;
              // margin-bottom: 10px;
              // padding-top: 14px;
              // padding-bottom: 14px;
            }
            ${mq.md} {
              padding-left: 15px;
              position: static;
              text-align: start;
              width: 100%;
            }
            // @media screen and (max-width: 820px) {
            //   margin-left: 16px;
            // }
          `}
          onClick={() => navigate(`/quote?enquiryId=${enquiryId}`)}
        >
          {!matches ? (
            <>
              <BiArrowBack /> Go Back to quotes
            </>
          ) : (
            <>
              <BiArrowBack /> Back to quotes
            </>
          )}
        </div>

        <div
          css={`
            display: flex;
            justify-content: center;
            font-size: 18px;
            font-weight: 600;
            ${mq.sm} {
              display: none;
            }
            ${mq.md} {
              display: none;
            }
          `}
        >
          Shortlisted Plans
        </div>
      </div>

      <div>
        <div>
          <Quotes
            showAddOn={showAddOn}
            setShowAddOn={setShowAddOn}
            css={`
              margin: auto;
              width: 60%;
            `}
          />
          <DifferentQuotes
            showAddOn={showAddOn}
            setShowAddOn={setShowAddOn}
            css={`
              margin: auto;
              width: 60%;
            `}
          />
        </div>
      </div>
      <div>
        <ComparePlanPopup />
      </div>
    </div>
  );
};

export default ShortListedPlansPage;

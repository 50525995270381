import { ShortListQuotesMobile } from "./../ShortListQuotes/ShortListQuotesMobile";
import React, { useState } from "react";
import styled from "styled-components/macro";
import * as mq from "../../../../styles/mediaQueries";
import { FiFilter, FiShare2, FiPhoneCall } from "react-icons/fi";
import FiltersMobile from "./components/FiltersMobile";
import ContactUsBtnComp from "../EditDetailsSection/components/ContactUsBtn-Comp";
import ShareQuoteBtnComp from "../Share/ShareQuoteBtn-Comp";
import Quotes from "../Quotes";
import TraceId from "../TraceId";
import { useNavigate } from "react-router";
import useUrlQuery from "../../../../custom-hooks/useUrlQuery";
import { setShowDifferentQuotes } from "../../Quotes.slice";
import DifferentQuotes from "../DifferentQuotes";
import { useSelector } from "react-redux";
import useTotalQuotes from "../../../../custom-hooks/useTotalQuotes";
import useGetDefaultFilters from "../../../Filters/useGetDefaultFilters";
import { RiFilterOffLine } from "react-icons/ri";

function QuotesMobile({ showAddOn, setShowAddOn }) {
  const [showContactModal, setShowContactModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const { showDifferentQuotes } = useSelector(state => state.quotes);
  const diffQuotes = useSelector(setShowDifferentQuotes);
  const navigate = useNavigate();
  const enquiryId = useUrlQuery("enquiryId");
  const length = useTotalQuotes();

  const {
    isDefaultFilters,
    isLoading: isDefaultFiltersLoading,
    resetFilters,
  } = useGetDefaultFilters();
  return (
    <>
      <QuotesMobileWrapper>
        <FiltersMobile />

        <div
          css={`
            display: flex;
            justify-content: space-between;
            padding: 0px 10px;
            margin-top: 10px;
          `}
        >
          <div>
            <TraceId label="CRN / Trace Id : " />
            {!isDefaultFilters && !isDefaultFiltersLoading && (
              <button
                css={`
                  display: inline;
                  border: none;
                  background: none;
                `}
                onClick={resetFilters}
              >
                <div
                  style={{
                    color: "var(--primary-color)",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "11px",
                    display: "inline",
                  }}
                >
                  Clear Filters{" "}
                  <RiFilterOffLine
                    style={{
                      color: "#b0b6c1",
                      marginTop: "-3px",
                      fontSize: "13px",
                    }}
                  />
                </div>
              </button>
            )}
          </div>
          <ShowingPlan>
            Showing {length} {length > 1 ? "plans" : "plan"}
          </ShowingPlan>
        </div>
        <Quotes />
        {showDifferentQuotes && diffQuotes && (
          <Options>
            Insurer's offering Product - Plans with slightly different criteria
            are shown below
          </Options>
        )}
        <DifferentQuotes showAddOn={showAddOn} setShowAddOn={setShowAddOn} />
      </QuotesMobileWrapper>
      <QuotesMobileFooter>
        <div
          css={`
            display: flex;
            flex-direction: column;
            color: rgb(128, 128, 128);
            cursor: pointer;
          `}
        >
          <FiFilter
            css={`
              margin: auto;
              font-size: 20px;
            `}
          />
          <FooterText>Filter</FooterText>
        </div>
        <ShortListQuotesMobile navigate={navigate} enquiryId={enquiryId} />
        <div
          css={`
            display: flex;
            flex-direction: column;
            color: rgb(128, 128, 128);
            cursor: pointer;
            padding-top: -5px;
          `}
          onClick={() => setShowShareModal(true)}
        >
          <FiShare2
            css={`
              margin: auto;
              font-size: 20px;
            `}
          />
          <FooterText>Share</FooterText>
        </div>
        <div
          css={`
            display: flex;
            flex-direction: column;
            color: rgb(128, 128, 128);
            cursor: pointer;
          `}
          onClick={() => setShowContactModal(true)}
        >
          <FiPhoneCall
            css={`
              margin: auto;
              font-size: 20px;
            `}
          />
          <FooterText>Contact Us</FooterText>
        </div>
      </QuotesMobileFooter>
      <ContactUsBtnComp show={showContactModal} setShow={setShowContactModal} />
      <ShareQuoteBtnComp
        showPopup={showShareModal}
        setShowPopup={setShowShareModal}
      />
    </>
  );
}

const QuotesMobileWrapper = styled.div`
  display: none;
  ${mq.md} {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    width: 100%;
    margin-bottom: 55px;
  }
`;
const QuotesMobileFooter = styled.div`
  display: none;
  ${mq.md} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    background-color: white;
    padding: 12px 20px 5px 20px;
    border-top: 1px solid rgb(240, 240, 240);
    z-index: 100;
  }
`;
const ShowingPlan = styled.div`
  font-size: 10px;
  ${mq.md} {
    margin-right: 10px;
  }
`;

const Options = styled.div`
  height: fit-content;
  padding: 5px;
  background-color: rgba(44, 212, 74, 0.15);
  border: 1px solid #2cd44a;
  padding: 10px;
  margin: 10px auto;
  width: 96%;
  border-radius: 5px;
  text-align: center;
`;
const FooterText = styled.p`
  font-size: 10px;
  font-family: Inter;
  font-weight: 500;
  margin: 0px auto;
`;
export default QuotesMobile;

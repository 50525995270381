import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import * as mq from "../../../styles/mediaQueries";
import { addQuote, removeQuote } from "../../ComparePage/ComparePlanPopupSlice";

import comapreBlack from "../../../assets/images/comapreBlack.png";
import compareBlue from "../../../assets/images/comapreBlue.png";

import { OverlayTrigger } from "react-bootstrap";
import renderTooltip from "../../../components/Tooltip/Tooltip";
export function CompareCheckboxSymbol({ quoteData }) {
  const dispatch = useDispatch();
  const matches = useMediaQuery(`${mq.mdForHook}`);
  const compareQuotes = useSelector(state => state.compareQuotes.compareQuotes);
  // const productIdOfCompareQuotes = compareQuotes.map(i => i?.product?.id);
  const [isSelected, setIsSelected] = useState(false);

  // Check if the current quote is already in the compare list
  const isPresent = compareQuotes.some(
    item => item?.product?.id === quoteData?.product?.id,
  );

  const addToCompare = () => {
    if (compareQuotes.length < (matches ? 2 : 3) && !isPresent) {
      dispatch(addQuote(quoteData));
      setIsSelected(true); // Set isSelected to true on selection
    } else {
      dispatch(removeQuote(quoteData));
      setIsSelected(false); // Set isSelected to false on removal
    }
  };

  return (
    <div
      onClick={() => addToCompare()}
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1px;
        ${mq.lg} {
          gap: 0px;
        }
      `}
    >
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 0, hide: 100 }}
        overlay={renderTooltip({
          desc: `Add To Comapre`,
        })}
      >
        <img
          src={isSelected ? compareBlue : comapreBlack}
          alt="Your Logo"
          style={{
            width: "28px", // Set the width and height of your logo
            height: "28px",
            marginRight: "0px", // Adjust as needed
            marginTop: "84px",
            cursor: "pointer",
            // color: isSelected ? "#0a87ff" : "#121212", // Apply blue color if isSelected is true, otherwise black color
          }}
        />
      </OverlayTrigger>
      <div
        css={`
          font-size: 11.5px;
          margin-left: 3px;
          font-weight: 700;
          ${mq.lg} {
            font-size: 11px;
            font-weight: 600;
          }
          ${mq.sm} {
            font-weight: 400;
          }
        `}
      >
        {matches ? "Compare" : ""}
      </div>
    </div>
  );
}

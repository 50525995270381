/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import ButtonLoader from "../../../../components/Loaders/ButtonLoader/ButtonLoader";
import { saveOtpData } from "../../../Landing/input.slice";
import {
  allowOnlyNumbers,
  allowOnlyUniqueAlphabetsNoSpace,
  lowerCase,
} from "../../../ProposalPage/InputUtils/onInput";
import { InputFormsTitle, InputPageTextInput } from "../../components";
import { useInputPageHook } from "../../hooks";
import {
  InputFormBackButtonStyled,
  InputFormButtonWrapperStyled,
  InputFormContinueButtonStyled,
  InputWrapperStyled,
} from "../../styles/inputForms.styled";
import OTPPopupForm from "../OTPPopupForm";
import useCommunicationForm from "./useCommunicationForm";
import { useGetWhitelistedEmailsMutation } from "../../../rider/services/rider";

const CommunicationForm = ({
  current,
  setCurrent,
  setIsConfettiVisible,
  isConfettiVisible,
}) => {
  const {
    investmentInputPage3,
    investmentSuitabilityFlagStatus,
    isOTPJourney,
    dispatch,
    investmentEnableCorporateValue,
  } = useInputPageHook();
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    trigger,
    setValue,
    clearErrors,
    email,
    setEmail,
    mobileNumber,
    setMobileNumber,
    otp,
    setOtp,
    name,
    onNameChange,
    isEnquiryLoading,
    otpResponse,
    otpVerified,
  } = useCommunicationForm();
  const [getWhitelistedEmails, { isLoading }] =
    useGetWhitelistedEmailsMutation();
  useEffect(() => {
    const fetchWhitelistedEmails = async () => {
      if (investmentEnableCorporateValue === "Yes" && email) {
        try {
          const response = await getWhitelistedEmails({
            domain: email,
          });
          if (response?.data?.status) {
            setIsConfettiVisible(true);
            setValue("domain_whitelisted", response?.data?.status);
            setValue("whitelisted_data", response?.data?.data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchWhitelistedEmails();
  }, [investmentEnableCorporateValue]);
  return (
    <InputFormsTitle
      title={investmentInputPage3}
      current={current}
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputWrapperStyled>
        <InputPageTextInput
          {...register("name")}
          label="Full Name*"
          size="large"
          capitalize
          onInput={allowOnlyUniqueAlphabetsNoSpace}
          onChange={e => {
            onNameChange(e);
            register("name").onChange(e);
          }}
          error={errors.name && errors.name.message}
          value={name}
          inputProps={{ minLength: 2, maxLength: 30, autoFocus: true }}
        />
      </InputWrapperStyled>
      <InputWrapperStyled>
        <InputPageTextInput
          label="Email*"
          size="large"
          {...register("email")}
          onChange={e => {
            e.target.value = e.target.value.replace(" ", "");
            lowerCase(e);
            register("email").onChange(e);
            setEmail(e.target.value);
          }}
          onBlur={async e => {
            if (investmentEnableCorporateValue === "Yes") {
              const response = await getWhitelistedEmails({ domain: email });
              if (response?.data?.status) {
                setIsConfettiVisible(true);
                setValue("domain_whitelisted", response?.data?.status);
                setValue("whitelisted_data", response?.data?.data);
              }
            }
          }}
          onFocus={() => setIsConfettiVisible(false)}
          error={errors.email && errors.email.message}
          value={email}
          inputProps={{ minLength: 2, maxLength: 50, inputMode: "email" }}
        />
        {isConfettiVisible && !isLoading && (
          <div
            style={{
              color: "var(--primary-color)",
              fontWeight: "600",
              letterSpacing: ".5px",
              margin: "5px 5px",
            }}
          >
            Congratulations! Your email ID from {<b>{email?.split("@")[1]}</b>}{" "}
            is eligible for exclusive discounts and corporate offers.`
          </div>
        )}
      </InputWrapperStyled>
      <InputWrapperStyled>
        <InputPageTextInput
          label="Mobile Number*"
          size="large"
          {...register("mobile")}
          onChange={e => {
            setMobileNumber(e);
            register("mobile").onChange(e);
            trigger("mobile");
            clearErrors("mobile");
          }}
          error={errors.mobile}
          helperText={errors.mobile && errors.mobile.message}
          value={mobileNumber}
          onInput={allowOnlyNumbers}
          inputProps={{ maxLength: 10, inputMode: "numeric" }}
        />
      </InputWrapperStyled>
      <InputFormButtonWrapperStyled>
        <InputFormBackButtonStyled
          disabled={(isEnquiryLoading && true) || isLoading}
          onClick={() => {
            if (investmentSuitabilityFlagStatus === "Yes")
              setCurrent(prev => prev - 1);
            else setCurrent(prev => prev - 2);
          }}
        >
          Back
        </InputFormBackButtonStyled>
        {isOTPJourney === "Yes" && otpVerified === false ? (
          <InputFormContinueButtonStyled
            onClick={e => {
              e.preventDefault();
              trigger("name");
              trigger("email");
              trigger("mobile");
              if (email && !errors?.email && mobileNumber && !errors?.mobile) {
                dispatch(
                  saveOtpData({
                    mobile: mobileNumber,
                    email: email,
                    section: "term",
                  }),
                );
                setOtp(true);
              }
            }}
            disabled={isLoading}
          >
            View Quotes
          </InputFormContinueButtonStyled>
        ) : otpResponse?.status === true &&
          otpResponse.message === "Verified" ? (
          <InputFormContinueButtonStyled
            type="submit"
            disabled={(isEnquiryLoading && true) || isLoading}
          >
            {isEnquiryLoading || isLoading ? <ButtonLoader /> : "View Quotes"}
          </InputFormContinueButtonStyled>
        ) : (
          <InputFormContinueButtonStyled
            type="submit"
            disabled={(isEnquiryLoading && true) || isLoading}
          >
            {isEnquiryLoading || isLoading ? <ButtonLoader /> : "View Quotes"}
          </InputFormContinueButtonStyled>
        )}
      </InputFormButtonWrapperStyled>
      <OTPPopupForm
        mobileNumber={mobileNumber}
        email={email}
        setOtp={setOtp}
        otp={otp}
      />
    </InputFormsTitle>
  );
};

export default CommunicationForm;

import { setData } from "../../Landing/input.slice";
import { useInputPageHook } from "../hooks";
import { PreferredICRadioButtonStyled } from "../styles/inputForms.styled";
const PreferredICCard = ({
  productName,
  insuranceCompanyName,
  setValue,
  preferredProduct,
}) => {
  const { investmentCompanies = {}, dispatch } = useInputPageHook();
  return (
    <>
      <PreferredICRadioButtonStyled>
        <input
          type="radio"
          id={`${productName}`}
          value={`${insuranceCompanyName}`}
          name="preferredIC"
          checked={preferredProduct === productName}
          onChange={e => {
            setValue("preferred_ic", e.target.value);
            setValue("preferred_product", productName);
            dispatch(
              setData({
                preferred_ic: e.target.value,
                preferred_product: productName,
              }),
            );
          }}
        />
        <label htmlFor={`${productName}`}>
          <img
            src={investmentCompanies?.[insuranceCompanyName]?.logo}
            alt="Preferred IC Logo"
          />
          <p>{productName}</p>
        </label>
      </PreferredICRadioButtonStyled>
    </>
  );
};

export default PreferredICCard;

import React from "react";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import { useGetCartQuery } from "../rider/services/rider";
import * as mq from "../../styles/mediaQueries";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader/FullScreenLoader";
import Navbar from "../../components/navbar/Navbar-Component";
import RiderHeaderNew from "./RiderHeaderNew";
import { ToastContainer } from "react-toastify";
import RiderSection from "./RiderSection";
import RiderPageFooterMobile from "./RiderPageFooterMobile";

function RiderPageNew() {
  const { isLoading, data } = useGetCartQuery();
  const matches = useMediaQuery(`${mq.smForHook}`);

  if (isLoading || !data) return <FullScreenLoader />;
  return (
    <div>
      <Navbar rider={true} />
      <RiderHeaderNew />
      <RiderSection />
      <ToastContainer limit={1} />
      {matches && <RiderPageFooterMobile />}
    </div>
  );
}

export default RiderPageNew;

import moment from "moment";

export const getAge = dob => {
  const date = moment(dob, "YYYY-MM-DD");
  const { years, months, days } = convertDateToYearsMonthsDays(date);
  // const age = moment().diff(date, "year");
  if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""}`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""}`;
  } else {
    return `${days} day${days > 1 ? "s" : ""}`;
  }
};

export const convertDateToYearsMonthsDays = startDate => {
  const start = moment(startDate);
  const end = moment();
  // Calculate the difference in years, months, and days
  const years = end.diff(start, "years");
  start.add(years, "years");
  const months = end.diff(start, "months");
  start.add(months, "months");
  const days = end.diff(start, "days");
  return { years, months, days };
};

export const getAge2 = dob => {
  const date = moment(dob, "DD/MM/YYYY");

  const age = moment().diff(date, "year");
  return age;
};
export const tobaccoHabit = tobacco_habit => {
  switch (tobacco_habit) {
    case "S":
      return "Smoker";
    case "NS":
    default:
      return "Non-Smoker";
  }
};
export const getGender = gender => {
  switch (gender) {
    case "F":
      return "Female";
    case "M":
    default:
      return "Male";
  }
};
export const getAnnualIncome = annualIncome => {
  const length = String(annualIncome).length;
  //100000
  if (length >= 8) {
    return `${+(
      Math.round(annualIncome / Math.pow(10, 7) + "e+2") + "e-2"
    )} Cr`;
  }
  if (length >= 6) {
    return `${+(
      Math.round(annualIncome / Math.pow(10, 5) + "e+2") + "e-2"
    )} Lacs`;
  }
  if (length >= 4) {
    return `${+(
      Math.round(annualIncome / Math.pow(10, 3) + "e+2") + "e-2"
    )} Th`;
  }
};

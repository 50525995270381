import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { BiRightArrow } from "react-icons/bi";
import styled from "styled-components";
import * as mq from "../../../styles/mediaQueries";
import { progressStateColors } from "../constants/inputForms.constant";

export const InputPageFormContainerStyled = styled.div`
  width: 50%;
  ${mq.sm} {
    display: block;
    width: 100%;
  }
  ${mq.md} {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
`;
export const InputPageFormCardStyled = styled.div`
  width: 500px;
  padding: 0px;
  margin: auto;
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
  border-radius: 0px;
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px,
    rgb(0 0 0 / 19%) 0px 6px 20px 0px;
  position: relative;
  ${mq.sm} {
    width: 90%;
  }
  ${mq.md} {
    width: 90%;
  }
`;
export const InputPageFormWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const InputFormProgressBarWrapperStyled = styled.div`
  padding: 17px;
  width: 100%;
  & h1 {
    font-size: 24px;
    font-weight: 900;
    color: rgb(74, 89, 113);
    ${mq.sm} {
      font-size: 18px;
    }
  }
`;
export const InputFormsProgressBarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3em;
  margin-top: 12px;
  margin-bottom: 20px;
`;
export const ProgressDotStyled = styled.div`
  height: 0.76em;
  width: 0.76em;
  border-radius: 50%;
  background-color: ${({ state = "present" }) => progressStateColors[state]};
`;
export const ProgressLineStyled = styled.div`
  height: 0.21em;
  width: 2em;
  border-radius: 1em;
  background-color: ${({ state = "present" }) => progressStateColors[state]};
`;
export const DisclaimerStyled = styled.div`
  font-size: 0.8rem;
  width: 500px;
  margin: 20px auto;
  ${mq.md} {
    width: 90%;
  }
`;
export const SuitabilityContainerStyled = styled.div`
  display: flex;
  font-size: 0.8rem !important;
  width: 500px;
  margin: 10px auto;
  ${mq.md} {
    width: 90%;
  }
`;
export const SuitabilityCheckBoxStyled = styled(Checkbox)`
  padding: 0px 9px 9px 0px !important;
`;
export const WhatsappContainerStyled = styled.div`
  display: flex;
  font-size: 0.8rem !important;
  width: 500px;
  margin: auto;
  input {
    padding: 9px px 9px 9px 0px !important;
  }
  ${mq.md} {
    width: 90%;
  }

  h6 {
    margin-top: 11px;
    img {
      height: 40px;
      width: 20px;
      margin-bottom: 2px;
      ${mq.sm} {
        height: 35px;
        width: 18px;
        margin-bottom: 4px;
      }
    }
  }
`;
export const WhatsappCheckboxStyled = styled(Checkbox)`
  padding: 9px 9px 9px 0px !important;
`;
export const InputPageMessageContainerStyled = styled.div`
  width: 50%;
  margin-left: 30px;
  padding: 0 20px;
  ${mq.sm} {
    margin: 0;
    width: 100%;
  }
  ${mq.md} {
    margin: 0;
    width: 100%;
    padding: 0;
  }
`;
export const InputPageMessageWrapperStyled = styled.div`
  padding: 27px;
  margin: auto;
  color: rgb(0, 0, 0);
  background: rgba(237, 240, 244, 0.62);
  border-radius: 0px;
  max-width: 580px;

  & h3 {
    color: var(--primary-color);
    background-color: rgb(236, 246, 255);
    border: 1px solid var(--primary-color);
    border-radius: 15px;
    width: max-content;
    font-size: 24px;
    padding: 3px 9px;
    margin-bottom: 13px;
    ${mq.sm} {
      font-size: 12px;
    }
  }

  & h1 {
    font-weight: 400;
    font-size: 36px;
    margin-bottom: 24px;
    ${mq.sm} {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
  & .down {
    display: none;
    ${mq.xs} {
      display: inline;
      font-size: 9px;
    }
  }
  ${mq.md} {
    max-width: 90%;
    margin-bottom: 20px;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px;
  }
  ${mq.sm} {
    padding: 10px;
    background: rgb(235, 239, 243);
    height: 100%;
    margin-bottom: 20px;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px;
  }
  ${mq.xs} {
    padding: 10px;
    background: rgb(235, 239, 243);
    height: 100%;
    margin-bottom: 20px;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px;
  }
`;
export const DescriptionWrapperStyled = styled.div`
  display: flex;
  gap: 5px;
`;
export const BiRightArrowStyled = styled(BiRightArrow)`
  display: none;
  ${mq.xs} {
    display: inline;
    height: 13px;
    width: 13px;
    cursor: pointer;
    color: rgb(50, 50, 50);
    transition: all 0.2s ease;
    transform: ${props => (props?.show ? "rotate(90deg)" : "rotate(0deg)")};
  }
`;
export const DynamicWrapperStyled = styled.div`
  width: 100%;
  ul {
    list-style-type: none;
    display: auto;
    padding-left: 0px;
    ${mq.md} {
      display: auto;
      width: 100%;
    }
    ${mq.sm} {
      display: auto;
      margin-bottom: 0;
    }
    ${mq.xs} {
      display: ${props => (props.show ? "block" : "none")};
      width: 100%;
      transition: all 0.2s ease;
      margin-left: -14px;
    }
  }
  li {
    font-size: 16px;
    margin: 0px 0px;
    ${mq.sm} {
      font-size: 10px;
      margin: 0px;
      padding: 0 5px;
    }
  }
  li::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f058";
    margin-right: 10px;
    color: var(--primary-color);
    display: inline;
    ${mq.md} {
      margin-right: 8px;
    }
    ${mq.sm} {
      margin-right: 6px;
    }
  }
`;
export const ListItemWrapperStyled = styled.div`
  display: auto;
  ${mq.md} {
    display: auto;
    width: 100%;
  }
  ${mq.sm} {
    display: auto;
  }
  ${mq.xs} {
    display: ${props => (props?.show ? "block" : "none")};
    width: 100%;
    transition: all 0.2s ease;
  }
`;
export const ListItemStyled = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 12px;
  font-size: 16px;
  & i {
    color: var(--primary-color);
    display: inline;
  }
  & p {
    margin: 0px 8px;
    line-height: 16px;
    ${mq.sm} {
      margin: 0px;
      padding: 0 5px;
    }
  }
  ${mq.sm} {
    font-size: 9px;
    margin-bottom: 0;
  }
`;
export const InputFormTitleContainerStyled = styled.form`
  width: 100%;
`;
export const InputFormRadioButtonWrapperStyled = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
  ${mq.md} {
    gap: 30px;
  }
  ${mq.sm} {
    gap: 15px;
  }
`;
export const InputFormRadioButtonStyled = styled.div`
  flex: 1 1;
  cursor: pointer;
  & input {
    display: none;
    &:checked + label {
      border: 2px solid var(--primary-color);
      color: var(--primary-color);
      font-weight: 600;
      & img {
        filter: grayscale(0%);
      }
    }
  }
  & label {
    cursor: pointer;
    width: 100%;
    height: 65px;
    border: 2px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    & img {
      height: 39px;
      width: auto;
      filter: grayscale(100%);
      margin-right: 20px;
      transition: all 0.5s;
      ${mq.sm} {
        height: 25px;
        margin-right: 10px;
      }
    }
    & span {
      flex: 2 2;
    }
    transition: border 0.5s;
    ${mq.md} {
      width: 100%;
      padding: 0 10px;
      font-size: 14px;
    }
    ${mq.sm} {
      width: 100%;
      padding: 0 10px;
      font-size: 12px;
    }
  }
`;
export const InputFormBirthDateStyled = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;
export const InputFormContinueButtonStyled = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--primary-color);
  padding: 1rem 0;
  color: #fff;
  font-size: 1.2rem;
  font-family: Inter;
  font-weight: 800;
  ${mq.md} {
    font-size: 0.9rem;
  }
  letter-spacing: 0.5px;
`;
export const InputFormBackButtonStyled = styled(InputFormContinueButtonStyled)`
  background-color: rgba(220, 220, 220, 0.5);
  color: var(--secondary-text);
`;
export const InputWrapperStyled = styled.div`
  width: 100%;
  margin-bottom: 20px;
  position: relative;
`;
export const SelectAutoCompleteStyled = styled(Autocomplete)({
  "& .MuiOutlinedInput-root": {
    padding: "0px 12px !important",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0 !important",
  },
});
export const CustomFieldStyled = styled(TextField)({
  minHeight: "65px",
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },

  "& .MuiOutlinedInput-input": {
    padding: "0px 14px",
    backgroundColor: "rgb(255, 255, 255)",
    textTransform: prop => prop.capitalize && "capitalize",
    height: "65px",
  },
  "& label.Mui-focused, & label.MuiFormLabel-filled, & label.Mui-focused.Mui-error":
    {
      transform: "translate(14px, -13px) scale(0.75)",
      color: "var(--primary-color)",
    },
  "& label.Mui-error": {
    color: "rgba(0, 0, 0, 0.6)",
  },
  "& .MuiInputLabel-root": {
    top: "6px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    height: "65px",

    "&.Mui-focused fieldset": {
      borderColor: "var(--primary-color)",
    },
    "&.Mui-focused.Mui-error fieldset": {
      borderColor: "#d32f2f",
    },
  },
});
export const InputFormButtonWrapperStyled = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  margin: auto;
  ${mq.sm} {
    width: 100%;
    gap: 1px;
  }
`;
export const PreferredICLogoContainerStyled = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr;
  max-height: 240px;
  overflow-y: scroll;
  margin-bottom: 20px;
  @media screen and (max-width: 540px) {
    max-height: 200px;
    gap: 3px;
  }
  ::-webkit-scrollbar {
    display: block;
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dbdad5;
    outline: none;
    display: block;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #babac0;
  }
`;
export const PreferredICRadioButtonStyled = styled.div`
  cursor: pointer;
  & input {
    display: none;
    &:checked + label {
      border: 2px solid var(--primary-color);
      color: var(--primary-color);
      & img {
        filter: grayscale(0%);
      }
    }
  }
  & label {
    width: 100%;
    height: fill-available;
    height: -webkit-fill-available;
    height: -ms-fill-available;
    height: -moz-fill-available;
    cursor: pointer;
    border: 2px solid #ced4da;
    border-radius: 4px;
    text-align: center;
    & img {
      height: 32px;
      width: auto;
      margin: 5px 10px;
      transition: all 0.5s;
      ${mq.sm} {
        height: 28px;
      }
    }
    & p {
      font-size: 0.64rem;
      font-weight: 600;
      padding: 0 2px 0 2px;
      margin: 1px 0;
      width: 100%;
    }
  }
`;

import { allowOnlyNumbers } from "../../../ProposalPage/InputUtils/onInput";
import {
  InputFormsTitle,
  InputPageSelect,
  InputPageTextInput,
} from "../../components";
import {
  educationOptions,
  occupationOptions,
} from "../../constants/inputForms.constant";
import { useInputPageHook } from "../../hooks";
import {
  InputFormBackButtonStyled,
  InputFormButtonWrapperStyled,
  InputFormContinueButtonStyled,
  InputWrapperStyled,
} from "../../styles/inputForms.styled";
import useEligibilityForm from "./useEligibilityForm";

const EligibilityForm = ({ setCurrent, current }) => {
  const { isEnquiry, investmentInputPage2 } = useInputPageHook();
  const {
    handleSubmit,
    onSubmit,
    errors,
    setValue,
    occupation,
    education,
    pinCode,
    viewQuotesHandler,
  } = useEligibilityForm(current, setCurrent);
  return (
    <InputFormsTitle
      title={investmentInputPage2}
      current={current}
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputWrapperStyled>
        <InputPageSelect
          noSearch
          label="Occupation*"
          options={occupationOptions}
          error={errors?.occupation?.message}
          handleChange={val => {
            setValue("occupation", val, "occupation");
          }}
          selectedValue={occupation}
        />
      </InputWrapperStyled>
      <InputWrapperStyled>
        <InputPageSelect
          label="Education*"
          options={educationOptions}
          error={errors?.education?.message}
          handleChange={val => {
            setValue("education", val, "education");
          }}
          noSearch
          selectedValue={education}
        />
      </InputWrapperStyled>
      <InputWrapperStyled>
        <InputPageTextInput
          label="Pincode*"
          size="large"
          capitalize
          onInput={allowOnlyNumbers}
          onChange={e => {
            setValue("pincode", e.target.value, "pincode");
          }}
          error={errors?.pincode?.message}
          value={pinCode}
          inputProps={{ minLength: 6, maxLength: 6 }}
        />
      </InputWrapperStyled>
      <InputFormButtonWrapperStyled>
        <InputFormBackButtonStyled onClick={() => setCurrent(prev => prev - 1)}>
          Back
        </InputFormBackButtonStyled>
        {isEnquiry ? (
          <InputFormContinueButtonStyled onClick={viewQuotesHandler}>
            View Quotes
          </InputFormContinueButtonStyled>
        ) : (
          <InputFormContinueButtonStyled type="submit">
            Continue
          </InputFormContinueButtonStyled>
        )}
      </InputFormButtonWrapperStyled>
    </InputFormsTitle>
  );
};

export default EligibilityForm;

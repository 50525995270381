import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../../../custom-hooks/useUrlQuery";
import useGetInWords from "../../../../custom-hooks/useGetInWords";
// import useGetAmount from "../../../../custom-hooks/useGetAmount";
import ShortlistCardLoader from "../../../../components/Loaders/CardSkeletonLoader/ShortlistLoader/ShortlistCardLoader";
import * as mq from "../../../../styles/mediaQueries";
const ShortListQuotes = () => {
  const {
    frontendData: { investmentcompanies },
  } = useSelector(state => state.frontendBoot);
  const { ShortListedPlansData } = useSelector(state => state.quotes);
  const { investFor = "10", incomeFor = "20" } = useSelector(
    state => state.filters,
  );
  const {
    investment_amount,
    irr_value,
    additional_lumpsum,
    additional_rop,
    premium,
    income_benfit,
    income_benefit4,
    income_benefit8,
    maturity_benefit,
    maturity_benefit4,
    maturity_benefit8,
    product_type,
  } = ShortListedPlansData[ShortListedPlansData.length - 1] || {};
  const investInWord = useGetInWords(investment_amount);
  const navigate = useNavigate();
  const enquiryId = useUrlQuery("enquiryId");
  // For Lumpsum Plans
  const totalAmountToBeReceived =
    income_benfit * incomeFor +
    Number(maturity_benefit) +
    additional_lumpsum +
    additional_rop;
  const totalAmountInvested = premium * investFor;
  const returnPercentage =
    Math.round((totalAmountToBeReceived / totalAmountInvested) * 100) / 100;
  // For 4% SWAG PAR Plans
  const totalAmountToBeReceived4 =
    income_benefit4 * incomeFor +
    Number(maturity_benefit4) +
    additional_lumpsum +
    additional_rop;
  const totalAmountInvested4 = premium * investFor;
  const returnPercentage4 =
    Math.round((totalAmountToBeReceived4 / totalAmountInvested4) * 100) / 100;
  // For 8% SWAG PAR Plans
  const totalAmountToBeReceived8 =
    income_benefit8 * incomeFor +
    Number(maturity_benefit8) +
    additional_lumpsum +
    additional_rop;
  const totalAmountInvested8 = premium * investFor;
  const returnPercentage8 =
    Math.round((totalAmountToBeReceived8 / totalAmountInvested8) * 100) / 100;

  const getMoneyAs = useSelector(state => state.filters.getMoneyAs);
  const { quotesLoading } = useSelector(state => state.quotes);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    const companies = Object.keys(quotesLoading);
    if (
      companies?.length &&
      companies?.some(item => quotesLoading[item] === true)
    ) {
      setLoad(true);
    } else {
      setLoad(false);
    }
  }, [quotesLoading]);

  return (
    <ShortListWrapper>
      <ShortListHeader>
        {load
          ? `Shortlisted Plans`
          : ShortListedPlansData.length >= 1
          ? `Shortlisted Plans: ${ShortListedPlansData.length}`
          : `Shortlisted Plans`}
        {!load && ShortListedPlansData.length >= 1 ? (
          <ViewAll
            onClick={() => {
              navigate(`/shortlisted-plans?enquiryId=${enquiryId}`);
            }}
          >
            View All
          </ViewAll>
        ) : (
          <></>
        )}
      </ShortListHeader>
      <ShortListContent>
        {load ? (
          <ShortlistCardLoader />
        ) : ShortListedPlansData.length ? (
          <ShortListedPlanLast>
            <div className="splantop-section">
              <div
                css={`
                  width: 37%;
                `}
              >
                <img
                  src={
                    investmentcompanies[
                      ShortListedPlansData[ShortListedPlansData.length - 1]
                        ?.company_alias
                    ]?.logo
                  }
                  alt="LOGO"
                  className="splan-image"
                />
              </div>
              <ProductNameText>
                <div className="spname">
                  {
                    ShortListedPlansData[
                      ShortListedPlansData.length - 1
                    ]?.product_name.split("-")[0]
                  }
                </div>
                <div className="sptype">
                  {
                    ShortListedPlansData[
                      ShortListedPlansData.length - 1
                    ]?.product_name.split("-")[1]
                  }
                </div>
              </ProductNameText>
            </div>
            <div className="splantop-section">
              <div
                className="splantop-section"
                css={`
                  width: 48%;
                `}
              >
                <div
                  css={`
                    font-size: 12px;
                    ${mq.lg} {
                      font-size: 11px;
                    }
                  `}
                >
                  Investment :{" "}
                  <span
                    css={`
                      font-weight: 600;
                    `}
                  >
                    {investInWord}
                  </span>
                </div>
              </div>
              <div
                className="splantop-section"
                css={`
                  width: 48%;
                `}
              >
                {getMoneyAs === "L" ? (
                  <div
                    css={`
                      font-size: 12px;
                      ${mq.lg} {
                        font-size: 11px;
                      }
                    `}
                  >
                    IRR :{" "}
                    <span
                      css={`
                        font-weight: 600;
                      `}
                    >
                      {irr_value}%
                    </span>
                  </div>
                ) : (
                  <>
                    <div
                      css={`
                        font-size: 12px;
                        ${mq.lg} {
                          font-size: 11px;
                        }
                      `}
                    >
                      Returns :{" "}
                      <span
                        css={`
                          font-weight: 600;
                        `}
                      >
                        {product_type === "PAR" ? (
                          <>
                            <div>@ 4% p.a. ({returnPercentage4} Times)</div>
                            <div>@ 8% p.a. ({returnPercentage8} Times)</div>
                          </>
                        ) : (
                          `${returnPercentage} times`
                        )}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </ShortListedPlanLast>
        ) : (
          <NoPlans>No Plans Shortlisted</NoPlans>
        )}
      </ShortListContent>
    </ShortListWrapper>
  );
};

export const ShortListWrapper = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 5px;
  margin-top: 10px;
  background: white;
`;
const ShortListHeader = styled.div`
  width: 100%;
  background: var(--primary-color);
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  border-radius: 5px 5px 0px 0px;
  color: white;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  position: relative;
  ${mq.lg} {
    height: 26px;
    font-size: 11.5px;
    align-items: center;
  }
`;

const ShortListContent = styled.div`
  height: fit-content;
  border: 0.12rem solid var(--primary-color);
  ${mq.lg} {
    border: 1px solid var(--primary-color);
    padding: 4px 10px;
  }
  border-top: 0px;
  padding: 8px 10px;
  border-radius: 0px 0px 5px 5px;
`;

export const ShortListedPlanLast = styled.div`
  width: 100%;
  min-height: 84px;
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  .splantop-section {
    display: flex;
    justify-content: space-between;
    height: fit-content;
  }
  .splan-image {
    height: 38px;
    max-width: 100%;
    ${mq.lg} {
      height: 33px;
    }
  }
`;
export const ProductNameText = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 1.4;
  .spname {
    font-size: 12px;
    font-weight: 700;
    ${mq.lg} {
      font-size: 11px;
    }
  }
  .sptype {
    font-size: 11px;
    font-family: Inter;
    ${mq.lg} {
      font-size: 10px;
    }
  }
`;

const NoPlans = styled.div`
  width: 100%;
  display: flex;
  height: 82px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700;
  ${mq.lg} {
    font-size: 12px;
    font-weight: 700;
    height: 82px;
  }
`;

const ViewAll = styled.div`
  font-size: 12px;
  background: white;
  padding: 3px 5px;
  position: absolute;
  right: 10px;
  border-radius: 10px;
  color: var(--primary-text);
  cursor: pointer;
  ${mq.lg} {
    font-size: 9px;
    padding: 1px 4px;
    right: 6px;
  }
`;

export default ShortListQuotes;

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "styled-components/macro";
import styled from "styled-components";
import * as mq from "../../styles/mediaQueries";
import { AiOutlineArrowLeft } from "react-icons/ai";
// import FilterSelect from "../Filters/FilterDropDown";
import Navbar from "../../components/navbar/Navbar-Component";
import RiderCardMobile from "./mobile/RiderCardMobile";
import RiderHeaderMobile from "./mobile/RiderHeaderMobile";
import RiderFooterMobile from "./mobile/RiderFooterMobile";
import {
  useGetCartQuery,
  useGetRedirectionMutation,
  useGetRidersQuery,
  useUpdateCartMutation,
} from "./services/rider";
import {
  redirectToIc,
  riderResponseFormat,
  sendProductFormat,
} from "../../utils/helper";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import { useEffect } from "react";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader/FullScreenLoader";
import PlanCard from "./PlanCard";
import RiderCardNew from "./RiderCardNew";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function RiderPage({ rider }) {
  const navigate = useNavigate();
  const { selectedQuote } = useSelector(state => state?.quotes);
  const enquiryId = useUrlQuery("enquiryId");
  const {
    data: cartData,
    isLoading: isCartLoading,
    isFetching: isCartFetching,
  } = useGetCartQuery();
  const { data } = useGetRidersQuery(
    {
      productId: cartData?.data?.product?.id,
      investment_insurance_id: cartData?.data?.investment_insurance_id,
    },
    {
      skip: isCartLoading || isCartFetching,
    },
  );
  const [getRedirection, { isLoading: isRedirectionLoading }] =
    useGetRedirectionMutation();

  const [updateCart, { data: updateCartData }] = useUpdateCartMutation();
  const [ridersAdded, setRidersAdded] = useState([]);

  useEffect(() => {
    setRidersAdded(
      updateCartData?.data?.investment_riders?.map(rider => ({
        ...rider,
      })) || [],
    );
  }, [updateCartData]);

  // eslint-disable-next-line no-unused-vars
  const [cardData, setCardData] = useState([]);

  // const sendProductData = sendProductFormat(cartData);

  // React.useEffect(() => {
  //   if (data) {
  //     setCardData(data?.data);
  //   }
  // }, [data?.data]);

  let initialRider = { ...rider };
  // eslint-disable-next-line no-unused-vars
  const [finalRider, setFinalRider] = useState(initialRider);
  // const { default_sum_assured } = finalRider;
  // let defaultCoverAmount = default_sum_assured;
  // const [coverAmount, setCoverAmount] = useState(defaultCoverAmount);

  if (isCartLoading || !cartData)
    return (
      <LoaderContainer>
        <FullScreenLoader />{" "}
      </LoaderContainer>
    );

  const {
    investment_insurance_id: investmentInsuranceId,
    product: { id: productId },
  } = cartData;
  const handleContinueClick = () => {
    getRedirection({
      product_id: productId,
      investment_insurance_id: investmentInsuranceId,
    }).then(res => {
      if (
        res?.data?.data[0]
          ?.journey_type /* && redirectionApiStatus !== 'rejected' */
      ) {
        const { data } = res.data;
        if (
          data[0] &&
          ["R", "PF"].includes(data[0].journey_type) &&
          data[0].redirection_url
        ) {
          redirectToIc(res.data.data[0]);
          return;
        } else {
          if (selectedQuote?.company_alias !== "pnb_metlife")
            navigate(`/investment/proposal?enquiryId=${enquiryId}`);
        }
      } else {
        toast.error(
          res?.error?.data?.message
            ? res?.error?.data?.message
            : "Something went wrong",
          {
            hideProgressBar: true,
            toastId: "failed1",
            autoClose: 3000,
            position: "bottom-center",
            theme: "colored",
          },
        );
      }
    });
  };

  const handleAddToCart = item => {
    if (
      ridersAdded?.some(riders => riders?.id !== item?.id) ||
      ridersAdded?.length === 0 ||
      ridersAdded === undefined
    ) {
      if (ridersAdded?.length < 1 || ridersAdded === undefined) {
        updateCart({
          ...sendProductFormat(cartData),
          riders: [{ ...riderResponseFormat({ ...item, policy_term: 10 }) }],
        });
      } else {
        updateCart({
          ...sendProductFormat(cartData),
          riders: [...ridersAdded, { ...riderResponseFormat({ ...item }) }],
        });
      }
    } else {
      updateCart({
        ...sendProductFormat(cartData),
        riders: ridersAdded.filter(rider => rider.id !== item.id),
      });
    }
  };

  if (cardData)
    return (
      <div>
        <div>
          <Navbar rider={true} />
          <MobileContainer>
            <RiderHeaderMobile cardData={cardData} />
            <div style={{ marginTop: 150 }}>
              {data ? (
                <RiderCardMobile
                  cardData={cardData}
                  handleAddToCart={handleAddToCart}
                />
              ) : (
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Riders Not Available
                </div>
              )}
            </div>
            <RiderFooterMobile
              cardData={cardData}
              cartData={cartData}
              handleContinueClick={handleContinueClick}
              isRedirectionLoading={isRedirectionLoading}
            />
          </MobileContainer>
          <Container>
            <MainContainer>
              <Header>
                <Link
                  to={`/quote?enquiryId=${enquiryId}`}
                  css={`
                    text-decoration: none;
                    color: rgb(33, 37, 41);
                  }
                `}
                >
                  <LeftIcon>
                    <AiOutlineArrowLeft />
                    <div>Go Back To Quote</div>
                  </LeftIcon>
                </Link>
                <HeaderTitle>
                  Great choice, customize your plan with Add-ons
                </HeaderTitle>
                <RightIcon
                  onClick={() => {
                    if (selectedQuote?.company_alias !== "pnb_metlife")
                      navigate(`/investment/proposal?enquiryId=${enquiryId}`);
                  }}
                >
                  Skip
                </RightIcon>
              </Header>

              <CardContainer>
                <PlanCard
                  handleContinueClick={handleContinueClick}
                  cartData={cartData}
                  isRedirectionLoading={isRedirectionLoading}
                />
                <RiderCardSection>
                  {data ? (
                    <>
                      <RiderCardNew />
                      <RiderCardNew />
                    </>
                  ) : (
                    <div style={{ fontSize: "16px", fontWeight: "800" }}>
                      Riders Not Available
                    </div>
                  )}
                </RiderCardSection>
              </CardContainer>
            </MainContainer>
          </Container>
        </div>
      </div>
    );
}

// function RiderDropdown({ disabled = false, ...props }) {
//   return (
//     <FilterSelect
//       {...props}
//       searchable
//       inputStyle={{
//         backgroundColor: "#fff",
//         padding: 0,
//         textAlign: "left",
//         height: "auto",
//         fontSize: "0.76rem",
//         margin: 0,

//         marginTop: "0.5rem",
//       }}
//       arrowStyle={{
//         right: 0,
//         position: "absolute",
//         display: disabled ? "none" : "static",
//       }}
//       wrapperStyle={{
//         padding: 0,
//         fontSize: "0.873em",
//         marginLeft: "-0.397em",
//         marginTop: "0.3em",
//         pointerEvents: disabled ? "none" : "all",
//       }}
//       listStyle={{ width: "100%", left: 0 }}
//     />
//   );
// }

export default RiderPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0px;
  ${mq.sm} {
    display: none;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85rem;
  margin: 0 auto;
  padding: 0px;
  ${mq.sm} {
    display: none;
  }
`;

const MobileContainer = styled.div`
  display: none;
  ${mq.sm} {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Inter;
  }
`;

export const Header = styled.div`
  width: 89%;
  display: flex;
  margin: 20px auto 20px auto;
  font-family: "Inter";
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${mq.sm} {
    max-width: 100%;
  }
  ${mq.sm} {
    display: none;
  }
`;

export const LeftIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  font-size: 14px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
`;

export const RightIcon = styled.div`
  padding: 5px;
  text-align: center;
  width: 150px;
  cursor: pointer;
  font-size: 16px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 89%;
  margin: 0px auto;
  ${mq.sm} {
    display: none;
  }
`;
const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const RiderCardSection = styled.div`
  width: calc(100% - 350px);
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

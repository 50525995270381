import React, { Suspense, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components/macro";
import "./App.scss";
import ErrorBoundaryComp from "./ErrorBoundaryComp";
import FullScreenLoader from "./components/Loaders/FullScreenLoader/FullScreenLoader";
import useUpdateFilterWithEnquiry from "./custom-hooks/useUpdateFilterWithEnquiry";
import ComparePage from "./pages/ComparePage/ComparePage";
import DocumentUploadPage from "./pages/DocumentUpload/DocumentUploadPage";
import NewComparePage from "./pages/NewCompare/NewCompare";
import PaymentStatusPage from "./pages/PaymentStatus/PaymentStatusPage";
import InvestInfo from "./pages/QuotesPage/components/InvestInfo";
import { DifferencesNav } from "./pages/QuotesPage/components/MoreQuestions/DifferencesNav";
import RiderInfo from "./pages/QuotesPage/components/RiderInfo";
import { ShortListedPlansPage } from "./pages/QuotesPage/components/ShortListQuotes/ShortListedPlansPage";
import RiderPageNew from "./pages/RiderPageNew/RiderPageNew";
import ThankyouPage from "./pages/ThankYouPage/ThankyouPage";
import { useGetFrontendBootQuery } from "./pages/rider/services/rider";
import InputPage from "./pages/InputPage";
import Footer from "./components/Footer";
const QuotesPage = lazy(() => import("./pages/QuotesPage/QuotesPage"));
const ProposalPage = lazy(() => import("./pages/ProposalPage/ProposalPage"));

function App() {
  const { isLoading } = useGetFrontendBootQuery();
  useUpdateFilterWithEnquiry();

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="app">
        <BrowserRouter>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryComp}
            onReset={() => {
              window.history.back();
            }}
          >
            <Routes>
              <Route exact path="/" element={<InputPage />} />
              <Route
                exact
                path="/quote"
                element={
                  <Suspense fallback={<Loader />}>
                    <QuotesPage />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/riders"
                element={
                  <Suspense fallback={<Loader />}>
                    <RiderPageNew />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/investment/proposal"
                element={
                  <Suspense fallback={<Loader />}>
                    <ProposalPage />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/investment/thankyou"
                element={
                  <Suspense fallback={<Loader />}>
                    <ThankyouPage />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/success"
                element={
                  <Suspense fallback={<Loader />}>
                    <PaymentStatusPage />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/document_upload"
                element={
                  <Suspense fallback={<Loader />}>
                    <DocumentUploadPage />
                  </Suspense>
                }
              ></Route>
              <Route
                exact
                path="/differences"
                element={<DifferencesNav />}
              ></Route>
              <Route exact path="/compare" element={<ComparePage />}></Route>
              <Route
                exact
                path="/shortlisted-plans"
                element={<ShortListedPlansPage />}
              />
              <Route path="/investment-information" element={<InvestInfo />} />
              <Route path="/rider-information" element={<RiderInfo />} />
              <Route exact path="/new-compare" element={<NewComparePage />} />
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
}

export default App;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
const Loader = () => {
  return (
    <>
      <LoaderContainer>
        <FullScreenLoader />
      </LoaderContainer>
    </>
  );
};

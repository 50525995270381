import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import * as mq from "../../../../styles/mediaQueries";

function PlanDetails() {
  const { planDetails } = useSelector(state => state.quotes.knowMoreData);
  const [current, setCurrent] = useState(0);

  return (
    <PlanWrapper>
      <PlanLeft>
        {planDetails.map((item, idx) => (
          <ButtonWrapper css={current === idx && selected2}>
            <PlanButton
              key={item.feature_key}
              css={current === idx && selected}
              active={idx === current}
              onClick={() => {
                setCurrent(idx);
              }}
            >
              <div className="button-head">{item.feature_key}</div>
              <div className="button-text">{item.feature_description}</div>
            </PlanButton>
          </ButtonWrapper>
        ))}
      </PlanLeft>
      <PlanRight id="planRight">
        {planDetails[current]?.feature_values.map(item => (
          <RightIndv>
            <div className="right-head">
              {item.feature_key_title}
              {" : "}
              {item.feature_key_value ? item.feature_key_value : "NA"}
            </div>
            <div className="right-text"> {item.feature_key_description}</div>
          </RightIndv>
        ))}
      </PlanRight>
    </PlanWrapper>
  );
}

export default PlanDetails;

const PlanWrapper = styled.div`
  width: 85%;
  margin: 0px auto 50px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  ${mq.md} {
    display: none;
  }
`;

const PlanLeft = styled.div`
  width: 40%;
  height: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const selected = `
      z-index:9;
     box-shadow: rgb(0,0,0,0.16) 0px 3px 3px 0px;  
     border-color:white;
     .button-head{
        color:var(--primary-color);
     }
`;
const selected2 = `
background-color:yellow;
`;

const PlanButton = styled.button`
  width: calc(100% - 8px);
  height: 100%;
  padding: 0px;
  border: none;
  background-color: white;
  outline: none;
  transition: all 0.1s ease;
  border-left: 2px solid var(--border-light);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  &:hover {
    z-index: 10;
    box-shadow: rgb(0, 0, 0, 0.16) 0px 3px 3px 0px;
  }

  .button-head {
    font-size: clamp(12px, 2vw, 20px);
    font-family: Inter;
    font-weight: 600;
    transition: all 0.1s ease;
    ${mq.lg} {
      font-size: clamp(11px, 1.6vw, 16px);
    }
  }

  .button-text {
    font-family: Inter;
    font-size: 13px;
    color: var(--primary-text);
    text-align: left;
    ${mq.lg} {
      font-size: 12px;
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: 33%;
  display: flex;
  justify-content: flex-end;
  border-radius: 10px 0px 0px 10px;
`;

const PlanRight = styled.div`
  width: 58%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 23px;
  background-color: var(--tertiary-shade);
  overflow: auto;
  padding: 20px;
  padding-top: 25px;
  &#planRight::-webkit-scrollbar {
    display: block;
    width: 4px;
  }
  &#planRight::-webkit-scrollbar-thumb {
    background-color: rgb(219, 218, 213);
    outline: none;
    display: block;
  }

  &#planRight::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 16px 0px;
  }
`;

const RightIndv = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;

  .right-head {
    color: var(--secondary-text);
    font-size: 16px;
    font-family: Inter;
    font-weight: 600;
    ${mq.lg} {
      font-size: 14px;
    }
  }
  .right-text {
    font-family: Inter;
    font-size: 12px;
    color: var(--primary-text);
    ${mq.lg} {
      font-size: 11px;
    }
  }
`;

import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../Landing/input.slice";

const useInputPageHook = () => {
  const {
    investmentinputpage: investmentInputPage,
    investmentsettings,
    investmentinputcompany,
    investmentcompanies,
  } = useSelector(state => state.frontendBoot?.frontendData);
  const {
    investmentsuitabilityflagstatus,
    investmentsuitabilitydisclaimer = "",
    investmentwhatsappflagstatus,
    investmentdisclaimer = "",
    investmentinputpage1,
    investmentinputpage2,
    investmentinputpage3,
    investmentinputpage4,
    investmentinsurancedescription = "",
    investmentleadpagestatus,
  } = investmentInputPage || {};
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const isEnquiry = window.location.href.includes("enquiryId");
  console.log({ isEnquiry: window.location.href.includes("enquiryId") });
  useEffect(() => {
    dispatch(setData({ whatsapp_flag: checked ? "Yes" : "No" }));
  }, [checked, dispatch]);

  return {
    checked,
    setChecked,
    isEnquiry,
    dispatch,
    investmentSuitabilityFlagStatus: investmentsuitabilityflagstatus,
    investmentSuitabilityDisclaimer: investmentsuitabilitydisclaimer,
    parsedInvestmentSuitabilityDisclaimer: parse(
      investmentsuitabilitydisclaimer ? investmentsuitabilitydisclaimer : "",
    ),
    investmentWhatsappFlagStatus: investmentwhatsappflagstatus,
    investmentDisclaimer: investmentdisclaimer,
    parsedInvestmentDisclaimer: parse(
      investmentdisclaimer ? investmentdisclaimer : "",
    ),
    investmentInputPage1:
      investmentinputpage1 || "<h1>Let's start with some basic details</h1>",
    investmentInputPage2:
      investmentinputpage2 ||
      "<h1>Tell us about your Professional Details</h1>",
    investmentInputPage3:
      investmentinputpage3 || "<h1>How can we reach out to you ?</h1>",
    investmentInputPage4:
      investmentinputpage4 || "<h1>Select Preferred Insurance Company</h1>",
    investmentLeadPageStatus: investmentleadpagestatus,
    investmentInsuranceDescription: investmentinsurancedescription,
    parsedInvestmentInsuranceDescription: parse(
      investmentinsurancedescription ? investmentinsurancedescription : "",
    ),
    inputPageBackgroundImage: investmentsettings?.background_image,
    isOTPJourney: investmentsettings?.otp_journey,
    investmentSettings: investmentsettings,
    investmentInputsCompany: investmentinputcompany,
    investmentCompanies: investmentcompanies,
    investmentEnableCorporateValue:
      investmentsettings?.investment_enable_corporate_value,
  };
};

export default useInputPageHook;

import React from "react";
import styled from "styled-components";
import { ModalBodyInner } from "./GetDetails";
import * as mq from "../../../../styles/mediaQueries";
import { useSelector } from "react-redux";

function AdditionalFeatures() {
  const { additionalBenefits } = useSelector(
    state => state.quotes.knowMoreData,
  );

  return (
    <ModalBodyInner>
      <AdditionalWrapper>
        {Object.values(additionalBenefits).map(item => {
          if (item.feature_key_description) {
            return (
              <AdditionalInd>
                <AdditionalHead className="additionalHead">
                  {item.feature_key_title}
                </AdditionalHead>

                <AdditionalText className="additional-features-description">
                  {item.feature_key_description}
                </AdditionalText>
              </AdditionalInd>
            );
          }
          return <></>;
        })}
      </AdditionalWrapper>
    </ModalBodyInner>
  );
}

const AdditionalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto 30px;
  ${mq.md} {
    width: 100%;
  }
`;
const AdditionalInd = styled.div`
  width: calc(50% - 10px);
  height: auto;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 8px 0px;
  background-color: rgb(255, 255, 255);
  padding: 15px;
  transition: all 0.3s ease;
  .additionalHead {
    transition: all 0.3s ease;
  }
  &:hover {
    border: 1px solid #0056fe;
    .additionalHead {
      color: var(--primary-color);
    }
  }

  ${mq.lg} {
    padding: 10px;
  }
  ${mq.md} {
    width: 100%;
    margin: 0px;
    height: auto;
  }
`;
export const AdditionalHead = styled.div`
  font-family: "Inter";
  font-size: 18px;
  width: fit-content;
  font-weight: 600;
  margin-bottom: 15px;

  ${mq.lg} {
    font-size: 16px;
    margin-bottom: 10px;
  }

  ${mq.md} {
    font-size: 15px;
    margin-bottom: 8px;
    width: 100%;
  }
  ${mq.xs} {
    font-size: 13px;
    margin-bottom: 5px;
  }
`;
export const AdditionalText = styled.div`
  font-family: "Inter";
  font-size: 13px;
  color: var(--primary-shade);
  margin: 0px;
  padding-right: 10px;

  ${mq.lg} {
    font-size: 12px;
  }

  ${mq.md} {
    font-size: 12px;
  }
  ${mq.xs} {
    font-size: 11px;
  }
`;

export default AdditionalFeatures;

import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import useGetInWords from "../../../../custom-hooks/useGetInWords";
import * as mq from "../../../../styles/mediaQueries";
import { useFrontendData } from "../../../frontendBoot/useFrontendData";
// import CancelCircleIcon from "../../../../assets/svg/CancelCircleIcon";
import CancleCircular from "../../../../assets/svg/CancelCircle";
import { findFrequency } from "../../../../utils/helper";
function ViewDetailsPopupMobile({
  show,
  handleClose,
  downloadDisabled,
  quoteData,
  download,
}) {
  const { getCompanyLogo } = useFrontendData();

  const {
    name,
    company: { alias },
  } = quoteData?.product
    ? quoteData.product
    : {
        name: "",
        company: {},
      };
  const { tax_amount } = quoteData ? quoteData : {};

  const [planName, planVariant, planVariant2] = name.split("-");
  const logoSrc = getCompanyLogo(alias);
  const filterData = useSelector(state => state.filters);
  const { selectedQuote } = useSelector(state => state.quotes);
  const { getMoneyAs, investedAmount, investFor, returnsAfter, incomeFor } =
    filterData;

  const totalAmount = parseInt(investedAmount) + parseInt(tax_amount);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      css={`
        animation-duration: 1s;
        animation-name: slidein;
        @keyframes slidein {
          from {
            margin-top: 200%;
          }
        }

        & .modal-dialog {
          position: absolute;
          bottom: 0;
          left: 0;
          margin: 0;
          z-index: 9999;
          width: 100%;
          max-width: 100%;
        }
        & .modal-content {
          border-top-left-radius: 35px;
          border-top-right-radius: 35px;
        }
      `}
    >
      <CancleCircular
        width="27"
        onClick={() => handleClose()}
        style={{
          position: "absolute",
          right: "11px",
          top: "10px",
          zIndex: "100",
          background: "none",
          boxShadow: "none",
        }}
      />
      <Modal.Body
        css={`
          background: transparent;
          padding: 0;
        `}
      >
        {/* <CancleCircular
          style={{
            top: "0px",
            right: "0px",
          }}
          width="30"
          onClick={() => handleClose()}
        /> */}
        <PlanCardWrapper>
          <PlanCardHeader>
            <div
              css={`
                width: 35%;
                height: 100%;
                overflow: hidden;
              `}
            >
              <img src={logoSrc} alt="company_logo" />
            </div>
            <PlanHeadData>
              <div
                css={`
                  font-family: Inter;
                  font-weight: 600;
                `}
              >
                {planName}
              </div>
              <div
                css={`
                  font-family: Inter;
                  font-weight: 300;
                `}
              >
                {planVariant + " " + (planVariant2 ? planVariant2 : "")}
              </div>
              <a
                href={download}
                target={"_blank"}
                rel="noreferrer"
                disabled={downloadDisabled}
                download
                css={`
                  cursor: pointer;
                `}
              >
                Download Brochure
              </a>
            </PlanHeadData>
          </PlanCardHeader>
          <div
            css={`
              padding: 14px;
              display: flex;
              flex-direction: column;
              gap: 10px;
            `}
          >
            <PlanCardDataInd>
              <div className="planCardDataInd-head">Plan Details</div>
              <div className="planCardDataInd-text">
                <div>Investment Amount</div>
                <div>{useGetInWords(investedAmount)}</div>
              </div>
              <div className="planCardDataInd-text">
                <div>Premium Payment Term</div>
                <div>{investFor} Year</div>
              </div>
              {getMoneyAs === "L" ? (
                <div className="planCardDataInd-text">
                  <div>Guaranteed Return After</div>
                  <div>{returnsAfter} Years</div>
                </div>
              ) : (
                <div className="planCardDataInd-text">
                  <div>Income Term</div>
                  <div>
                    {incomeFor > 100 ? "Till Death" : `${incomeFor} Years`}
                  </div>
                </div>
              )}

              <div className="planCardDataInd-text">
                <div>Pay Frequency</div>
                <div>{findFrequency(selectedQuote?.frequency)}</div>
              </div>
            </PlanCardDataInd>
            <PlanCardDataInd>
              <div className="planCardDataInd-head">Premium Details</div>
              <div className="planCardDataInd-text">
                <div>Investment Premium</div>
                <div>{useGetInWords(investedAmount)}</div>
              </div>
              <div className="planCardDataInd-text">
                <div>Goods & Service Tax</div>
                <div>₹ {tax_amount}</div>
              </div>
            </PlanCardDataInd>
            <PlanTotalPay>
              <div>Total Premium Payable</div>
              <div>₹ {totalAmount}</div>
            </PlanTotalPay>
          </div>
        </PlanCardWrapper>
      </Modal.Body>
      {/* </Slide> */}
    </Modal>
  );
}

const PlanCardWrapper = styled.div`
  width: 320px;
  box-shadow: rgb(0 0 0 / 13%) 0px 1px 5px 0px;
  ${mq.md} {
    width: 100%;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px;
  }
`;

const PlanCardDataInd = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  font-family: Inter;

  .planCardDataInd-head {
    font-size: 16px;
    border-bottom: 1px solid rgb(222, 222, 255);
    color: var(--primary-color);
    padding-bottom: 12.5px;
  }

  .planCardDataInd-text {
    font-size: 12.2px;
    color: rgb(102, 102, 102);
    display: flex;
    justify-content: space-between;
  }
`;

const PlanCardHeader = styled.div`
  background: var(--tertiary-shade);
  padding: 14px;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  border-bottom: 1px solid rgb(222, 222, 255);
  ${mq.sm} {
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
  }
`;

const PlanHeadData = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  font-size: 12.3px;
  ${mq.sm} {
    margin-right: 18px;
  }
`;

const PlanTotalPay = styled.div`
  background: var(--tertiary-shade);
  padding: 14px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

// const ContinueBuy = styled.button`
//   width: 100%;
//   height: 45px;
//   border: none;
//   outline: none;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: var(--primary-color);
//   color: white;
//   cursor: pointer;
// `;

export default ViewDetailsPopupMobile;

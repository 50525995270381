import { Checkbox } from "@mui/material";
import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components/macro";
import ButtonLoader from "../../components/Loaders/ButtonLoader/ButtonLoader";
import useGetInWords from "../../custom-hooks/useGetInWords";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import * as mq from "../../styles/mediaQueries";
import { selectQuotes } from "../QuotesPage/Quotes.slice";
import { useSortQuotes } from "../QuotesPage/components/Quotes";
import { selectICLogo } from "../frontendBoot/frontendBoot.slice";
import { usePutCompareProductsMutation } from "../rider/services/rider";
import { setShowDifference } from "./Compare.slice";
import { addQuote, removeQuote } from "./ComparePlanPopupSlice";

function CompareButtonPopupMobile({ show, handleClose }) {
  const quotes = useSelector(selectQuotes);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let companies = Object.keys(quotes);
  const { sortedQuotes } = useSortQuotes(
    companies.map(company => quotes[company]),
  );
  const sortedAndFilteredQuotes = sortedQuotes.map(quote => quote);

  const compareQuotes = useSelector(state => state.compareQuotes.compareQuotes);
  const enquiryId = useUrlQuery("enquiryId");

  const [putCompareProducts, { isLoading }] = usePutCompareProductsMutation();

  const handleCompareClick = () => {
    putCompareProducts({
      products: compareQuotes.map(quote => ({
        company_alias: quote?.company_alias,
        product_id: quote?.product?.id,
        investment_insurance_id: quote?.investment_insurance_id,
        different_quote: quote?.differentQuote ? "Y" : "N",
      })),
    }).then(() => {
      compareQuotes.length >= 2
        ? navigate(`/compare?enquiryId=${enquiryId}`)
        : toast.error("Select 2 plans to compare", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
    });
    dispatch(setShowDifference(false));
  };

  return (
    <div className="w-100">
      <Modal show={show} onHide={handleClose} css={modalCss}>
        <Modal.Header closeButton>
          <Modal.Title> Select Plan To Compare</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sortedAndFilteredQuotes.map((quote, index) => {
            return (
              <>
                {quote.length > 1 ? (
                  quote.map((quote, i) => {
                    return (
                      <CompareQuoteCard key={i} quote={quote} index={index} />
                    );
                  })
                ) : (
                  <CompareQuoteCard key={index} quote={quote} index={index} />
                )}
              </>
            );
          })}
        </Modal.Body>
        <Modal.Footer
          style={{
            height: "50px",
            padding: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CompareButton
            style={{
              margin: "0px",
            }}
            onClick={handleCompareClick}
          >
            {isLoading ? <ButtonLoader /> : "Compare Plans"}
          </CompareButton>
        </Modal.Footer>
        {/* </Slide> */}
      </Modal>
    </div>
  );
}

export default CompareButtonPopupMobile;

const modalCss = `
.modal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative; 
}
.modal-header{
}
.fade{

}
.modal-content{
 height:100%;
 width:100%;
}
.modal-dialog {
height:50%;
margin-bottom:0px;
margin-top:160px;


.modal-body{
  width:100%;
  overflow :auto;
  padding-top:0px;
  &::-webkit-scrollbar {
    width: 0px;
  };
  }

.modal-title{
  font-size:13px;
  font-family:Inter;
  font-weight:500;
  text-align:center;
}  

}
`;

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Quotes = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid 1px rgb(10, 135, 255, 0.5);
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  height: 5em;
`;

const CheckBox = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Logo = styled.div`
  width: 30%;
`;
const Details = styled.div`
  display: flex;
  width: 20%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Name = styled.div`
  font-size: 10px;
  color: rgba(0, 0, 0, 0.7);
`;
const Price = styled.div`
  font-size: 12px;
  font-family: "Inter";
  font-weight: 600;
  margin-top: 5px;
`;

const CompareButton = styled.div`
  width: 40%;
  margin: 20px auto 10px auto;
  display: flex;
  color: white;
  background: var(--primary-color);
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
`;

function CompareQuoteCard({ quote }) {
  const dispatch = useDispatch();
  const quoteData = quote.length === 1 ? quote[0] : quote;
  const matches = useMediaQuery(`${mq.mdForHook}`);
  const compareQuotes = useSelector(state => state.compareQuotes.compareQuotes);
  const logo = useSelector(
    selectICLogo(quote[0]?.company_alias || quote?.company_alias),
  );
  const benefit = quoteData.maturity_benefit;
  const investForValue = useSelector(state => state.filters.investFor) || "10";

  const incomeForValue = useSelector(state => state.filters.incomeFor) || "20";

  const totalAmountToBeReceived =
    quoteData.income_benfit * incomeForValue +
    benefit +
    quoteData.additional_lumpsum +
    quoteData.additional_rop;

  const totalAmountInvested = quoteData.premium * investForValue;
  const returns =
    Math.round((totalAmountToBeReceived / totalAmountInvested) * 100) / 100;

  const totalInWord = useGetInWords(totalAmountInvested);
  const totalRecvWord = useGetInWords(totalAmountToBeReceived);
  // const productIdOfCompareQuotes = compareQuotes.map(i => i?.product?.id);
  let isPresent = compareQuotes.some(
    item => item?.product?.id === quoteData?.product?.id,
  );
  const [planName] = quoteData.product_name
    .split("-")
    .map(item => item && item.trim());

  const addToCompare = () => {
    if (compareQuotes.length >= (matches ? 2 : 3) && !isPresent) {
      toast.error("You can compare only 2 plans", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    if (compareQuotes.length < (matches ? 2 : 3) && !isPresent) {
      dispatch(addQuote(quoteData));
    } else {
      dispatch(removeQuote(quoteData));
    }
  };

  return (
    <Main>
      <Quotes>
        <CheckBox>
          <div
            onClick={() => addToCompare()}
            css={`
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <Checkbox
              inputProps={{
                "aria-label": "controlled",
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 18,
                },
              }}
              css={`
                padding: 3px !important;
                padding-left: 0px !important;
              `}
              checked={isPresent}
            />
          </div>
        </CheckBox>
        <Logo>
          <img
            css={`
              height: 20px;
            `}
            src={logo}
            alt="logo"
          />
          <div
            style={{
              fontSize: "10px",
              color: "rgba(0, 0, 0, 0.7)",
              fontWeight: "500",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: " ellipsis",
              maxWidth: "80px",
              textAlign: "center",
            }}
          >
            {planName}
          </div>
        </Logo>
        <Details>
          <Name>You Invest</Name>
          <Price>{totalInWord}</Price>
        </Details>
        <Details>
          <Name>You Receive</Name>
          <Price>{totalRecvWord}</Price>
        </Details>
        <Details>
          <Name>Returns</Name>
          <Price>{returns} x</Price>
        </Details>
      </Quotes>
    </Main>
  );
}

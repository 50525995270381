/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonLoader from "../../../../../components/Loaders/ButtonLoader/ButtonLoader";
import {
  checkDocType,
  checkSize,
  convert,
} from "../../../../DocumentUpload/doc-utils";
import {
  useGetApplicationNumberQuery,
  useGetCartQuery,
  useGetProposalDataQuery,
} from "../../../../rider/services/rider";
import { addressProofOptions } from "../../../components/options/TataAia/addressOptions";
import { incomeProofOptions } from "../../../components/options/TataAia/eduAndOccupOptions";
import {
  age_proof,
  bankProofOptions,
  identityProofOptions,
} from "../../../components/options/TataAia/proposerOptions";
import Selectt from "../../../components/Selectt";
import Title from "../../../components/Title";
import {
  clearDocumentUploadLoading,
  initDocumentUploadLoading,
} from "../../../ProposalPage.slice";
import {
  ErrorMsg,
  FileInputWrapper,
  UploaderBody,
  UploadWrap,
} from "../../../ProposalPage.style";
import { uploadDoc } from "../../../serviceApi";

const TataDocumentUpload = ({ disable, setDisable }) => {
  const { data: cartData } = useGetCartQuery();
  const appData = useGetApplicationNumberQuery();
  const appNo = appData?.data?.data.proposal_reference_id;
  const { data: proposalData, isLoading: isProposalDataLoading } =
    useGetProposalDataQuery();
  const [errors, setErrors] = useState({});
  const [previews, setPreviews] = useState({});
  const [uploaded, setUploaded] = useState("");
  const [documents, setDocuments] = useState({});
  const currentDate = moment().format("DDMMYYYY");
  const [docName, setDocName] = useState({
    insured_photo: `InsuredPhoto_${currentDate}_1.pdf`,
    id_proof: `IdProof_${currentDate}_2.pdf`,
    age_proof: `AgeProof_${currentDate}_3.pdf`,
    address_proof: `ResidentialAddressProof_${currentDate}_4.pdf`,
    income_proof: `IncomeProof_${currentDate}_5.pdf`,
    business_cert: `BusinessCertificate_${currentDate}_6.pdf`,
    other_doc: `OtherProof_${currentDate}_7.pdf`,
    bank_proof: `BankProof_${currentDate}_8.pdf`,
  });
  const { isDocumentUploadLoading } = useSelector(state => state.proposalForm);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isProposalDataLoading && proposalData) {
      setDocuments({
        ...documents,
        ["Identity Proof"]: {
          ...(documents["Identity Proof"] ? documents["Identity Proof"] : {}),
          file_type:
            proposalData?.data["Personal Details"]?.identity_proof_type,
          doc_type: "FORM",
          doc_id: proposalData?.data["Personal Details"]?.identity_proof_type,
        },
        ["Age Proof"]: {
          ...(documents["Age Proof"] ? documents["Age Proof"] : {}),
          file_type: proposalData?.data["Personal Details"]?.age_proof_type,
          doc_type: "FORM",
          doc_id: proposalData?.data["Personal Details"]?.age_proof_type,
        },
        ["Residential Address Proof"]: {
          ...(documents["Residential Address Proof"]
            ? documents["Residential Address Proof"]
            : {}),
          file_type: proposalData?.data["Address Details"]?.address_proof,
          doc_type: "FORM",
          doc_id: proposalData?.data["Address Details"]?.address_proof,
        },
        ["Income Proof"]: {
          ...(documents["Income Proof"] ? documents["Income Proof"] : {}),
          file_type:
            proposalData?.data["Education and Occupation Details"]
              ?.income_proof,
          doc_type: "FORM",
          doc_id:
            proposalData?.data["Education and Occupation Details"]
              ?.income_proof,
        },

        ["Bank Proof"]: {
          ...(documents["Bank Proof"] ? documents["Bank Proof"] : {}),
          file_type: proposalData?.data["Bank Details"]?.bank_details,
          doc_type: "FORM",
          doc_id: proposalData?.data["Bank Details"]?.bank_details,
        },
      });
    }
  }, [isProposalDataLoading, proposalData]);
  useEffect(() => {
    if (
      documents["Insured Photo"]?.upload_file === undefined ||
      documents["Residential Address Proof"]?.upload_file === undefined ||
      documents["Income Proof"]?.upload_file === undefined ||
      documents["Age Proof"]?.upload_file === undefined ||
      documents["Identity Proof"]?.upload_file === undefined ||
      // documents["Other Document"]?.upload_file === undefined ||
      documents["Bank Proof"]?.upload_file === undefined
    ) {
      return setDisable(prev => ({
        ...prev,
        ["Insured Photo"]: true,
        ["Age Proof"]: true,
        ["Residential Address Proof"]: true,
        ["Income Proof"]: true,
        ["Identity Proof"]: true,
        // ["Other Document"]: true,
        ["Bank Proof"]: true,
      }));
    }
  }, []);
  // Blob
  const createPreview = fileInput => {
    const blob = new Blob(fileInput?.target?.files, {
      type: fileInput?.target?.files[0]?.type,
    });
    return URL.createObjectURL(blob);
  };
  // Remove Document
  const removeDocument = document => {
    setDocuments(prev => {
      let temp = { ...prev };
      temp[document].upload_file = null;
      temp[document].upload_file_id = null;
      temp[document].upload_file_name = null;
      return temp;
    });
    setPreviews(prev => {
      let temp = { ...prev };
      temp[document] = {};
      return temp;
    });
    setDisable(prev => ({ ...prev, [document]: true }));
  };
  // On Submit
  const onSubmit = async (docObject, item, key) => {
    const newData = await convert(docObject.upload_file);
    try {
      await uploadDoc({
        company_alias: cartData?.product?.company?.alias,
        application_no: appNo,
        upload_file_name: docObject?.upload_file_name,
        upload_file_id: docObject?.upload_file_id,
        upload_file: newData.split(",")[1],
        doc_id: docObject?.doc_id,
        doc_type: docObject?.doc_type,
        doc_format: docObject?.doc_format,
      });
      setDisable(prev => ({ ...prev, [item]: false }));
      toast.success(`${item} Uploaded!`, {
        toastId: item,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      // setUploadedText(item);
    } catch (err) {
      toast.error(`${item} Upload Failed! ${err}`, {
        toastId: `${item}_error`,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      setDocName(prev => ({
        ...prev,
        [key]: `${item.replace(" ", "")}_${currentDate}_1.pdf`,
      }));
      setDocuments(prev => ({
        ...prev,
        [item]: { file_type: documents[item]?.file_type },
      }));
      setPreviews(prev => ({ ...prev, [item]: {} }));
    } finally {
      dispatch(clearDocumentUploadLoading());
    }
  };

  return (
    <div className="doc-form__wrapper my-4">
      {/* ================================= Insured Photo ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Insured Photo"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Title label={"Insured Photo"} />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.insured_photo} */}
              <input
                type="file"
                name="myfile"
                id={docName.insured_photo}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Insured Photo"]: "File type or size not allowed",
                    });
                  } else {
                    setUploaded("Insured Photo");
                    dispatch(initDocumentUploadLoading());
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 1,
                      upload_file_name: `${appNo}_InsuredPhoto_${currentDate}_1.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                      doc_id: "1022010142",
                      doc_type: "PHOTOGRAPH",
                    };
                    setErrors({
                      ...errors,
                      ["Insured Photo"]: "",
                    });
                    setDocName(prev => ({
                      ...prev,
                      insured_photo: docObject?.upload_file_name,
                    }));
                    setDocuments({
                      ...documents,
                      ["Insured Photo"]: {
                        ...(documents["Insured Photo"]
                          ? documents["Insured Photo"]
                          : {}),
                        ...docObject,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Insured Photo"]: {
                        ...(previews["Insured Photo"]
                          ? previews["Insured Photo"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                    onSubmit(docObject, "Insured Photo", `insured_photo`);
                  }
                }}
              />
            </span>
          </FileInputWrapper>
          {!documents["Insured Photo"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.insured_photo}>
              Choose a file
            </label>
          ) : uploaded === "Insured Photo" && isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Insured Photo"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Insured Photo"]?.blob_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Insured Photo"]?.upload_file && (
                <i
                  className="fas fa-trash-alt trash-icon"
                  onClick={() => removeDocument("Insured Photo")}
                />
              )}
            </>
          )}
        </div>
        {errors["Insured Photo"] && (
          <ErrorMsg rel>{errors["Insured Photo"]}</ErrorMsg>
        )}
      </UploaderBody>
      {/* ================================= ID ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Identity Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Selectt
              readOnly
              options={identityProofOptions}
              label={"Identity Proof"}
              selectedValue={documents["Identity Proof"]?.file_type}
              noSearch
              handleChange={value =>
                setDocuments({
                  ...documents,
                  ["Identity Proof"]: {
                    ...(documents["Identity Proof"]
                      ? documents["Identity Proof"]
                      : {}),
                    file_type: value,
                  },
                })
              }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.id_proof} */}
              <input
                type="file"
                name="myfile"
                id={docName.id_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Identity Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setUploaded("Identity Proof");
                    dispatch(initDocumentUploadLoading());
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 2,
                      upload_file_name: `${appNo}_IdentityProof_${currentDate}_2.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                      doc_id: documents["Identity Proof"]?.file_type,
                      doc_type: "FORM",
                    };
                    setDocName(prev => ({
                      ...prev,
                      id_proof: docObject?.upload_file_name,
                    }));
                    setErrors({
                      ...errors,
                      ["Identity Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Identity Proof"]: {
                        ...(documents["Identity Proof"]
                          ? documents["Identity Proof"]
                          : {}),
                        ...docObject,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Identity Proof"]: {
                        ...(previews["Identity Proof"]
                          ? previews["Identity Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                    onSubmit(docObject, "Identity Proof", `insured_photo`);
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Identity Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.id_proof}>
              Choose a file
            </label>
          ) : uploaded === "Identity Proof" && isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Identity Proof"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Identity Proof"]?.blob_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Identity Proof"]?.upload_file && (
                <i
                  className="fas fa-trash-alt trash-icon"
                  onClick={() => {
                    removeDocument("Identity Proof");
                  }}
                />
              )}
            </>
          )}
        </div>
        {errors["Identity Proof"] && (
          <ErrorMsg rel>{errors["Identity Proof"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Age ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Age Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Selectt
              options={age_proof}
              label={"Age Proof"}
              selectedValue={documents["Age Proof"]?.file_type}
              noSearch
              readOnly
              handleChange={value =>
                setDocuments({
                  ...documents,
                  ["Age Proof"]: {
                    ...(documents["Age Proof"] ? documents["Age Proof"] : {}),
                    file_type: value,
                  },
                })
              }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.age_proof} */}
              <input
                type="file"
                name="myfile"
                id={docName.age_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Age Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setUploaded("Age Proof");
                    dispatch(initDocumentUploadLoading());
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 3,
                      upload_file_name: `${appNo}_AgeProof_${currentDate}_2.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                      doc_id: documents["Age Proof"]?.file_type,
                      doc_type: "FORM",
                    };
                    setDocName(prev => ({
                      ...prev,
                      age_proof: docObject?.upload_file_name,
                    }));
                    setErrors({
                      ...errors,
                      ["Age Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Age Proof"]: {
                        ...(documents["Age Proof"]
                          ? documents["Age Proof"]
                          : {}),
                        ...docObject,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Age Proof"]: {
                        ...(previews["Age Proof"] ? previews["Age Proof"] : {}),
                        blob_file: createPreview(e),
                      },
                    });
                    onSubmit(docObject, "Age Proof", `age_proof`);
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Age Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.age_proof}>
              Choose a file
            </label>
          ) : uploaded === "Age Proof" && isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Age Proof"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Age Proof"]?.blob_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Age Proof"]?.upload_file && (
                <i
                  className="fas fa-trash-alt trash-icon"
                  onClick={() => {
                    removeDocument("Age Proof");
                  }}
                />
              )}
            </>
          )}
        </div>
        {errors["Age Proof"] && <ErrorMsg rel>{errors["Age Proof"]}</ErrorMsg>}
      </UploaderBody>

      {/* ================================= Address ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={
          documents["Residential Address Proof"]?.upload_file ? true : false
        }
      >
        <div className="upload__body">
          <UploadWrap>
            <Selectt
              options={addressProofOptions}
              label={"Residential Address Proof"}
              selectedValue={documents["Residential Address Proof"]?.file_type}
              noSearch
              readOnly
              handleChange={value =>
                setDocuments({
                  ...documents,
                  ["Residential Address Proof"]: {
                    ...(documents["Residential Address Proof"]
                      ? documents["Residential Address Proof"]
                      : {}),
                    file_type: value,
                  },
                })
              }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.address_proof} */}
              <input
                type="file"
                name="myfile"
                id={docName.address_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Residential Address Proof"]:
                        "File type or size not allowed",
                    });
                  } else {
                    setUploaded("Residential Address Proof");
                    dispatch(initDocumentUploadLoading());
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 4,
                      upload_file_name: `${appNo}_ResidentialAddressProof_${currentDate}_2.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                      doc_id: documents["Residential Address Proof"]?.file_type,
                      doc_type: "FORM",
                    };
                    setDocName(prev => ({
                      ...prev,
                      address_proof: docObject?.upload_file_name,
                    }));
                    setErrors({
                      ...errors,
                      ["Residential Address Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Residential Address Proof"]: {
                        ...(documents["Residential Address Proof"]
                          ? documents["Residential Address Proof"]
                          : {}),
                        ...docObject,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Residential Address Proof"]: {
                        ...(previews["Residential Address Proof"]
                          ? previews["Residential Address Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                    onSubmit(
                      docObject,
                      "Residential Address Proof",
                      `address_proof`,
                    );
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Residential Address Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.address_proof}>
              Choose a file
            </label>
          ) : uploaded === "Residential Address Proof" &&
            isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Residential Address Proof"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Residential Address Proof"]?.blob_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Residential Address Proof"]?.upload_file && (
                <i
                  className="fas fa-trash-alt trash-icon"
                  onClick={() => removeDocument("Residential Address Proof")}
                />
              )}
            </>
          )}
        </div>
        {errors["Residential Address Proof"] && (
          <ErrorMsg rel>{errors["Residential Address Proof"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Income ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Income Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Selectt
              options={incomeProofOptions}
              label={"Income Proof"}
              selectedValue={documents["Income Proof"]?.file_type}
              noSearch
              readOnly
              handleChange={value =>
                setDocuments({
                  ...documents,
                  ["Income Proof"]: {
                    ...(documents["Income Proof"]
                      ? documents["Income Proof"]
                      : {}),
                    file_type: value,
                  },
                })
              }
            />
          </UploadWrap>

          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.income_proof} */}
              <input
                type="file"
                name="myfile"
                id={docName.income_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Income Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setUploaded("Income Proof");
                    dispatch(initDocumentUploadLoading());
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 5,
                      upload_file_name: `${appNo}_IncomeProof_${currentDate}_2.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                      doc_id: documents["Income Proof"]?.file_type,
                      doc_type: "FORM",
                    };
                    setDocName(prev => ({
                      ...prev,
                      income_proof: docObject?.upload_file_name,
                    }));
                    setErrors({
                      ...errors,
                      ["Income Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Income Proof"]: {
                        ...(documents["Income Proof"]
                          ? documents["Income Proof"]
                          : {}),
                        ...docObject,
                      },
                    });

                    setPreviews({
                      ...previews,
                      ["Income Proof"]: {
                        ...(previews["Income Proof"]
                          ? previews["Income Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                    onSubmit(docObject, "Income Proof", `income_proof`);
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Income Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.income_proof}>
              Choose a file
            </label>
          ) : uploaded === "Income Proof" && isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Income Proof"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Income Proof"]?.blob_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Income Proof"]?.upload_file && (
                <i
                  className="fas fa-trash-alt trash-icon"
                  onClick={() => removeDocument("Income Proof")}
                />
              )}
            </>
          )}
        </div>
        {errors["Income Proof"] && (
          <ErrorMsg rel>{errors["Income Proof"]}</ErrorMsg>
        )}
      </UploaderBody>
      {/* ======================= Bank Proof =====================*/}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Bank Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Selectt
              readOnly
              options={bankProofOptions}
              label={"Bank Proof"}
              selectedValue={documents["Bank Proof"]?.file_type}
              handleChange={value =>
                setDocuments({
                  ...documents,
                  ["Bank Proof"]: {
                    ...(documents["Bank Proof"] ? documents["Bank Proof"] : {}),
                    file_type: value,
                  },
                })
              }
            />
          </UploadWrap>

          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.bank_proof} */}
              <input
                type="file"
                name="myfile"
                id={docName.bank_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Bank Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setUploaded("Bank Proof");
                    dispatch(initDocumentUploadLoading());
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 6,
                      upload_file_name: `${appNo}_BankProof_${currentDate}_2.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                      doc_id: documents["Bank Proof"]?.file_type,
                      doc_type: "FORM",
                    };

                    setDocName(prev => ({
                      ...prev,
                      bank_proof: docObject?.upload_file_name,
                    }));
                    setErrors({
                      ...errors,
                      ["Bank Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Bank Proof"]: {
                        ...(documents["Bank Proof"]
                          ? documents["Bank Proof"]
                          : {}),
                        ...docObject,
                      },
                    });

                    setPreviews({
                      ...previews,
                      ["Bank Proof"]: {
                        ...(previews["Bank Proof"]
                          ? previews["Bank Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                    onSubmit(docObject, "Bank Proof", `bank_proof`);
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Bank Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName?.bank_proof}>
              Choose a file
            </label>
          ) : uploaded === "Bank Proof" && isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Bank Proof"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Bank Proof"]?.blob_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Bank Proof"]?.upload_file && (
                <i
                  className="fas fa-trash-alt trash-icon"
                  onClick={() => removeDocument("Bank Proof")}
                />
              )}
            </>
          )}
        </div>
        {errors["Bank Proof"] && (
          <ErrorMsg rel>{errors["Bank Proof"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Other Documents ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Other Document"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            {" "}
            <Title label={"Any Other Documents"} />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {/* {docName.other_doc} */}
              <input
                type="file"
                name="myfile"
                id={docName.other_doc}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Other Document"]: "File type or size not allowed",
                    });
                  } else {
                    setUploaded("Other Document");
                    dispatch(initDocumentUploadLoading());
                    const docObject = {
                      upload_file: e.target.files[0],
                      upload_file_id: 7,
                      upload_file_name: `${appNo}_OtherDocument_${currentDate}_2.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                      doc_format: e.target.files[0]?.name
                        ?.split(".")
                        ?.reverse()[0],
                      doc_id: "1021010193",
                      doc_type: "FORM",
                    };

                    setDocName(prev => ({
                      ...prev,
                      other_doc: docObject?.upload_file_name,
                    }));
                    setErrors({
                      ...errors,
                      ["Other Document"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Other Document"]: {
                        ...(documents["Other Document"]
                          ? documents["Other Document"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 7,
                        upload_file_name: docObject?.upload_file_name,
                        doc_id: "1021010193",
                        doc_type: "FORM",
                        doc_format: docObject?.doc_format,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Other Document"]: {
                        ...(previews["Other Document"]
                          ? previews["Other Document"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                    onSubmit(docObject, "Other Document", `other_doc`);
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Other Document"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.other_doc}>
              Choose a file
            </label>
          ) : uploaded === "Other Document" && isDocumentUploadLoading ? (
            <label className="Choose_file_btn">
              <ButtonLoader />
            </label>
          ) : (
            <>
              {previews["Other Document"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Other Document"]?.blob_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview
                </a>
              )}
              {documents["Other Document"]?.upload_file && (
                <i
                  className="fas fa-trash-alt trash-icon"
                  onClick={() => removeDocument("Other Document")}
                />
              )}
            </>
          )}
        </div>
        {errors["Other Document"] && (
          <ErrorMsg rel>{errors["Other Document"]}</ErrorMsg>
        )}
      </UploaderBody>
    </div>
  );
};

export default TataDocumentUpload;

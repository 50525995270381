import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
api.interceptors.request.use(
  req => {
    const urlQueryStrings = new URLSearchParams(window.location.search);
    const EnquiryId = urlQueryStrings.get("enquiryId");
    if (EnquiryId) {
      req.headers.common["Enquiry-Id"] = EnquiryId;
    }
    return req;
  },
  err => {
    console.error(err);
  },
);
export default api;

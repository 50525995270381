import api from "../../api/api";
export const contactDetails = data => api.post("contactus/share", data);
export const enquiries = data => api.post("enquiries", data);
export const getEnquiries = () => api.get("enquiries");
export const patchEnquiries = (data, options) =>
  api.patch("enquiries", data, {
    cancelToken: options?.cancelToken,
  });
export const downloadCompare = data =>
  api.post("/investment/Compare/pdf_link", data);
export const getOtp = data => api.post("fyntune/life/otp_verification", data);
export const sendOtp = data => api.post("/fyntune/life/verify_mobile", data);

import React from "react";
import styled from "styled-components";
import * as mq from "../../../styles/mediaQueries";
import { FiChevronLeft } from "react-icons/fi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../../components/navbar/Navbar-Component";
import copy from "../../../assets/images/Copy.svg";
import { useGetEnquiriesQuery } from "../services/rider";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import { useSelector } from "react-redux";

function RiderHeaderMobile({ cardData }) {
  const { data } = useGetEnquiriesQuery();
  const navigate = useNavigate();
  const enquiryId = useUrlQuery("enquiryId");
  const { selectedQuote } = useSelector(state => state?.quotes);
  return (
    <Card>
      <HeaderContainer>
        <HeaderNav>
          <HeaderNavLeft>
            <Logo />
          </HeaderNavLeft>
          <HeaderNavRight>
            CRN : {data?.data?.trace_id}
            <div className="ps-1">
              <img
                src={copy}
                alt=""
                onClick={() => {
                  navigator.clipboard.writeText(data?.data?.trace_id);
                  toast.success("Copied to clipboard");
                }}
              />
            </div>
          </HeaderNavRight>
        </HeaderNav>
        <HeaderButton>
          <div
            className="d-flex justify-content-center align-items-center color-black fw-bold "
            onClick={() => {
              navigate("/quote");
            }}
          >
            <BackIcon>
              <FiChevronLeft />
            </BackIcon>
            Back To Quotes
          </div>
          <button
            onClick={() => {
              if (selectedQuote?.company_alias !== "pnb_metlife")
                navigate(`/investment/proposal?enquiryId=${enquiryId}`);
            }}
            className="btn btn-primary btn-sm  text-primary  bg-white border-primary fw-bold px-4 py-1 "
          >
            {cardData && cardData.length > 0 ? "Continue" : "  Skip"}
          </button>
        </HeaderButton>
        <HeaderContent>
          <span>Great Choice You Can Now</span>
          <span
            className="text-primary
  "
          >
            Customize Your Plan With Add-ons
          </span>
        </HeaderContent>
      </HeaderContainer>
    </Card>
  );
}

const Card = styled.div`
  display: none;
  ${mq.sm} {
    display: block;
    width: 100%;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  padding: 20px;
  position: fixed;
  background-color: #fff;
  top: 0;
`;

const HeaderNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const HeaderNavLeft = styled.div``;
const HeaderNavRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  font-size: 10px;
  font-weight: 600;
  color: #000;
`;

const HeaderButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
`;

const BackIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #000;
  font-weight: 600;
`;
const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
`;

export default RiderHeaderMobile;
// px-4 py-1

import { useState } from "react";
import { useInputPageHook } from "../hooks";
import {
  BiRightArrowStyled,
  DescriptionWrapperStyled,
  DynamicWrapperStyled,
  InputPageMessageContainerStyled,
  InputPageMessageWrapperStyled,
  ListItemWrapperStyled,
} from "../styles/inputForms.styled";
import ListItem from "./ListItem";

const InputPageMessage = () => {
  const {
    investmentInsuranceDescription = "",
    parsedInvestmentInsuranceDescription = "",
  } = useInputPageHook();
  const [show, setShow] = useState(false);
  return (
    <>
      <InputPageMessageContainerStyled>
        <InputPageMessageWrapperStyled>
          <h3>Endowment Plans</h3>
          {investmentInsuranceDescription ? (
            <>
              <DescriptionWrapperStyled>
                <BiRightArrowStyled
                  show={show}
                  onClick={() => setShow(show => !show)}
                />
                <DynamicWrapperStyled show={show}>
                  {parsedInvestmentInsuranceDescription}
                </DynamicWrapperStyled>
              </DescriptionWrapperStyled>
            </>
          ) : (
            <>
              <h1>
                Buy Guaranteed Return Plans In Few Simple Steps
                <i
                  className="fas fa-info-circle down ps-1"
                  onClick={e => setShow(!show)}
                />
              </h1>
              <ListItemWrapperStyled show={show}>
                <ListItem>Compare Endowment Plans</ListItem>
                <ListItem>Instant Policy Issuance</ListItem>
                <ListItem>Free Claims Assistance</ListItem>
                <ListItem>Get Plan Recommendation in Seconds</ListItem>
              </ListItemWrapperStyled>
            </>
          )}
        </InputPageMessageWrapperStyled>
      </InputPageMessageContainerStyled>
    </>
  );
};

export default InputPageMessage;

import { useEffect, useRef, useState } from "react";

import styled from "styled-components/macro";
import * as mq from "../../styles/mediaQueries";
import { createMarkup } from "../../utils";
import CriticalPopUp from "./CriticalPopup";

function RiderDescription({ rider, ...props }) {
  const { rider_description, rider_shortname } = rider;
  const riderDescriptionRef = useRef(null);

  const [show, setShow] = useState(null);

  useEffect(() => {
    if (riderDescriptionRef.current) {
      const btn = riderDescriptionRef.current.querySelector(".rider-popup");
      if (btn) {
        btn.addEventListener("click", () => setShow("critial-illness"));
      }
    }
  }, [rider_shortname]);

  const handlePopupClose = () => setShow(null);

  return (
    <RiderDescriptionWrap ref={riderDescriptionRef} {...props}>
      <p {...props} dangerouslySetInnerHTML={createMarkup(rider_description)} />
      <CriticalPopUp
        show={show === "critial-illness"}
        handleClose={handlePopupClose}
        rider={rider}
      />
      {/* <CancerPopUp handleClose={handlePopupClose} show={show === "cancer"} /> */}
    </RiderDescriptionWrap>
  );
}

export default RiderDescription;

const RiderDescriptionWrap = styled.div`
  font-size: 13px;
  & .rider-popup {
    border: none;
    color: var(--primary-color);
    background: transparent;
    margin-right: 0.3em;
    transition: all 0.2s ease;
    text-decoration: none;
    cursor: pointer;
  }
  ${mq.sm} {
    font-size: 12px;
  }
`;

import styled from "styled-components";

export const FormWrapper = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
export const CollectionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
export const HR = styled.hr`
  background-color: rgba(0, 0, 0, 0.3);
  color: inherit;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 0;
`;
export const Wrapper = styled.div`
  width: ${props => (props.fullWidth ? "100%" : "50%")};
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: ${props => (props.margin ? "12px" : "24px")};
  @media (max-width: 576px) {
    width: 100%;
    margin-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
    .css-p0rm37 {
      transform: translate(14px, 11px) scale(1);
      font-size: 14px;
    }
    .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root,
    .css-1rcvyyg {
      transform: translate(14px, -6px) scale(0.75);
      font-size: 14px;
    }
  }
`;
export const AgeWrapper = styled.p`
  position: relative;
  z-index: 9;
  height: 0;
  width: 100px;
  top: -35px;
  left: 63%;
  font-size: 14px;
  color: var(--primary-color);
  font-family: Inter;
  font-weight: 500;
  margin: 0;
`;

export const UploadPanel = styled.div`
  width: 870px;

  margin-bottom: 24px;

  hr {
    width: 90%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border: 1px solid white !important;
  }
  & .panel-footer {
    p {
      color: #e63030;
      font-size: 13px;
      line-height: 12px;
      font-weight: 900;
      padding: 10px;
      width: 100%;
      text-align: center;
      font-family: Inter;
    }
    button {
      border-radius: 2px;
      width: 100%;
      font-family: "Inter";
      line-height: 50px;
      padding: 0 40px;
      font-size: 16px;
      color: #ffffff;
      text-transform: uppercase;
      margin: 18px -7px;
      background: var(--primary-color);
    }
  }
  & .panel_heading {
    color: #000;
    font-size: 24px;
    line-height: 28px;
    padding: 10px;
    width: 100%;
    font-weight: 900;
  }
  & .panel_heading_desc {
    color: #e63030;
    font-size: 13px;
    line-height: 12px;
    font-weight: 900;
    padding: 10px;
    width: 100%;
    background: #fff2f4;
    text-align: center;
    font-family: Inter;
  }

  & .trash-icon {
    color: #e63030;
    font-size: 18px;
    cursor: pointer;
    margin: 0 15px;
  }
  & .select_wrapper {
    label {
      font-size: 1rem;
      color: #57667e;
    }
    .Choose_file_btn {
      width: 135px;
      text-align: center;
      margin-right: 15px;
      background-color: var(--primary-color);
      border-radius: 6px !important;
      padding: 11px 20px;
      cursor: pointer;
      display: inline-block;
      color: #fff;
      font-size: 14px;
      border: none;
    }
    .upload_btn {
      background: #f0f0f0;
      border: none;
      margin-right: 5px;
      width: 135px;
      text-align: center;
      border-radius: 6px !important;
      padding: 11px 20px;
      cursor: pointer;
      display: inline-block;
      color: #fff;
      font-size: 14px;
    }
  }
  p {
    margin: 0px !important;
  }
`;

export const UploaderBody = styled.div`
  margin: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 25px 5px;
  width: 100%;

  .upload_btn {
    background-color: ${props =>
      props.active ? "#f2581b !important" : "#f0f0f0 !important"};
    color: ${props => (props.active ? "white !important" : "black !important")};
  }
  & .file_name_container {
    display: ${props => (props.active ? "inline-block" : "none")};
  }
  & .additional-doc-input {
    width: 64%;
    text-align: left;
  }
  .upload__body {
    align-items: center;
    justify-content: space-between;
    display: flex;
  }
  .additional-body {
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }
`;
export const ErrorMsg = styled.div`
  color: #d8000c;
  font-size: 0.7rem;
  padding: 5px 0 0 0;
  margin-left: 5px;
`;
export const FileInputWrapper = styled.div`
  width: 30%;

  text-align: center;
  & .file_name_container {
    display: inline-block;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & input {
    text-align: center;
    width: 100%;
    display: none;
  }
  & input[type="file"]::file-selector-button {
    display: none;
  }
`;
export const UploadWrap = styled.div`
  width: 50%;
  display: inline-block;
  padding: 0 15px;
`;

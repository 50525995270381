/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import CardSkeletonLoader from "../../../components/Loaders/CardSkeletonLoader/CardSkeletonLoader";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import * as mq from "../../../styles/mediaQueries";
import { selectCompanies } from "../../frontendBoot/frontendBoot.slice";
import { useGetDifferentQuoteQuery } from "../../rider/services/rider.js";
import QuoteCard from "../QuoteCard";
import { selectDifferentQuotes } from "../Quotes.slice";
import GetDetails from "./Details/GetDetails";
import QuotesCardMobile from "./Mobile/QuotesCardMobile";
import { QuoteCardWrapper } from "./Quotes.style";

const DifferentQuotes = props => {
  let companies = useSelector(selectCompanies);
  companies = Object.keys(companies || {});

  let filteredCompanies = companies;

  let quotes = useSelector(selectDifferentQuotes);

  if (Object.keys(quotes).length > 0) {
    quotes = Object.values(quotes).map(quotes => (quotes ? quotes[0] : null));

    const filteredQuotes = quotes
      ?.filter(quote => !!quote)
      ?.map(quote => quote.company_alias)
      ?.filter(company => filteredCompanies.includes(company));

    const restCompanies = filteredCompanies?.filter(
      company => !filteredQuotes.includes(company),
    );

    filteredCompanies = [...filteredQuotes, ...restCompanies];
  }
  return (
    <div {...props}>
      {filteredCompanies.length > 0 &&
        filteredCompanies.map(company => (
          <Quote
            showAddOn={props.showAddOn}
            setShowAddOn={props.setShowAddOn}
            company_alias={company}
            key={company}
          />
        ))}
    </div>
  );
};

export default DifferentQuotes;

function Quote({ company_alias, quoteArr, ...props }) {
  const { ShortListedPlan } = useSelector(state => state?.quotes);
  const location = useLocation();
  const [selectedProductId, setSelectProductId] = useState();
  const [policyTermToShow, setPolicyTermToShow] = useState(
    quoteArr?.[0]?.policy_term,
  );
  const [payoutTermToShow, setPayoutTermToShow] = useState(
    quoteArr?.[0]?.selected_payout_term,
  );

  useEffect(() => {
    setPolicyTermToShow(quoteArr?.[0]?.policy_term);
  }, [quoteArr?.[0]?.policy_term]);
  useEffect(() => {
    setPayoutTermToShow(quoteArr?.[0]?.selected_payout_term);
  }, [quoteArr?.[0]?.selected_payout_term]);

  const matches = useMediaQuery(`${mq.mdForHook}`);
  const { isFilterUpdating } = useSelector(state => state.filters);
  const { newFilters } = useSelector(state => state.inputForm);
  const { showDetails, selectedQuote, selectedPolicyPayoutFilter } =
    useSelector(state => state?.quotes);
  const { isLoading, isFetching, isUninitialized, isError, data } =
    useGetDifferentQuoteQuery(
      {
        company_alias,
        ...newFilters,
        investment_insurance_id:
          selectedPolicyPayoutFilter[`${company_alias}_different`]
            ?.investment_insurance_id,
        product_id:
          selectedPolicyPayoutFilter[`${company_alias}_different`]?.product_id,
        selected_payout_term:
          selectedPolicyPayoutFilter[`${company_alias}_different`]
            ?.selected_payout_term,
        policy_term:
          selectedPolicyPayoutFilter[`${company_alias}_different`]?.policy_term,
      },
      { skip: _.isEmpty(newFilters) },
    );

  if (isError) return null;

  if (isUninitialized || isLoading || isFilterUpdating || isFetching)
    return <CardSkeletonLoader />;

  const { data: quotes } = data;

  if (quotes.length === 0) return null;

  const quote = quotes[0];

  if (
    location.pathname === "/shortlisted-plans" &&
    !ShortListedPlan?.some(el => el === quote?.product?.id)
  ) {
    return <></>;
  }
  if (!quote) return null;
  return (
    <>
      {matches ? (
        <>
          <QuotesCardMobile
            differentQuote
            showAddOn={props.showAddOn}
            setShowAddOn={props.setShowAddOn}
            quoteData={{ ...quote, differentQuote: true }}
            onBuyNowClick={() => {
              setSelectProductId(quote?.product?.id);
            }}
            policyTermToShow={quote?.policy_term || policyTermToShow}
            setPolicyTermToShow={setPolicyTermToShow}
            payoutTermToShow={quote?.selected_payout_term || payoutTermToShow}
            setPayoutTermToShow={setPayoutTermToShow}
            isFetching={isFetching}
          />
          <GetDetails
            show={quote?.product?.id === selectedProductId && showDetails}
            company_alias={quote?.company_alias}
            quoteData={quote}
            setShow={() => setSelectProductId(false)}
          />
        </>
      ) : (
        <QuoteCardWrapper {...props}>
          <QuoteCard
            differentQuote
            showAddOn={props.showAddOn}
            setShowAddOn={props.setShowAddOn}
            quoteData={{ ...quote, differentQuote: true }}
            onBuyNowClick={() => {
              setSelectProductId(quote?.product?.id);
            }}
            isQuoteLoading={isLoading || isFetching || isFilterUpdating}
            isFetching={isFetching}
            policyTermToShow={quote?.policy_term || policyTermToShow}
            setPolicyTermToShow={setPolicyTermToShow}
            payoutTermToShow={quote?.selected_payout_term || payoutTermToShow}
            setPayoutTermToShow={setPayoutTermToShow}
          />

          <GetDetails
            show={quote?.product?.id === selectedProductId && showDetails}
            company_alias={quote?.company_alias}
            quoteData={quote}
            setShow={() => setSelectProductId(false)}
          />
        </QuoteCardWrapper>
      )}
    </>
  );
}

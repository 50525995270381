import React from "react";
import { Container } from "react-bootstrap";
import "styled-components/macro";
import * as mq from "../../../../../styles/mediaQueries";
import AddOnCoverage from "./AddOnCoverage";
import RiderCardWithoutCovers from "./RiderCardWithoutCovers";

const AddonCoveragesWrap = ({ quoteData }) => {
  const { riders_list: riders } = quoteData || {};

  const freeRiders =
    riders !== undefined
      ? riders.filter(item => item.rider_type === "Free")
      : [];

  return (
    <Container
      css={`
        width: 90%;
        padding: 20px 0;
      `}
    >
      <>
        <h4
          css={`
            margin-bottom: 20px;
            font-family: Inter;
            font-weight: 700;
            font-size: 20px;
            ${mq.sm} {
              font-weight: bold;
              font-size: 15px;
            }
          `}
        >
          Customize Your Plan By Selecting Add-Ons
        </h4>
        <AddOnCoverage quote={quoteData} riders={riders} />
        {freeRiders.length !== 0 ? (
          <FreeAddon freeRiders={freeRiders} quoteData={quoteData} />
        ) : (
          <></>
        )}
      </>
    </Container>
  );
};

const FreeAddon = ({ freeRiders, quoteData }) => {
  return (
    <>
      <hr style={{ border: "2px solid #dfdfdf" }} />
      <h4
        css={`
          margin-bottom: 30px;
          font-family: Inter;
          font-weight: 700;
          font-size: 20px;
          ${mq.sm} {
            font-weight: bold;
            font-size: 15px;
          }
        `}
      >
        Inbuilt Coverages
      </h4>
      <div
        css={`
          width: 90%;
          ${mq.sm} {
            width: 100%;
          }
        `}
      >
        <ul
          css={`
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 0.6em;
            margin: 10px auto 80px auto;
            ${mq.sm} {
              flex-direction: column;
            }
          `}
        >
          {freeRiders.map(item => (
            <>
              <li
                css={`
                  flex: 0 0 calc(50% - 0.3em);
                  display: flex;
                  width: 100%;
                `}
              >
                <RiderCardWithoutCovers rider={item} quote={quoteData} />
              </li>
            </>
          ))}
        </ul>
      </div>
    </>
  );
};
export default AddonCoveragesWrap;

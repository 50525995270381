import { ListItemStyled } from "../styles/inputForms.styled";

const ListItem = ({ children }) => {
  return (
    <>
      <ListItemStyled>
        <i className="fas fa-check-circle"></i>
        <p>{children}</p>
      </ListItemStyled>
    </>
  );
};

export default ListItem;

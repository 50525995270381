import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { saveOtpData, sendOtpData } from "../../../Landing/input.slice";
import { allowOnlyNumbers } from "../../../ProposalPage/InputUtils/onInput";
import InputPageTextInput from "../../components/InputPageTextInput";
import { InputFormContinueButtonStyled } from "../../styles/inputForms.styled";
import useOTPPopupForm from "./useOTPPopupForm";

function OTPPopupForm({ mobileNumber, email, setOtp, otp }) {
  const { verified, inputOTP, setInputOTP, disable, dispatch } =
    useOTPPopupForm(otp, setOtp);
  return (
    <Dialog
      open={otp}
      fullWidth
      onClose={verified || (() => setOtp(false))}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle style={{ fontWeight: "600" }}>
        {"OTP Verification"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={{ marginTop: "15px" }}
        >
          <InputPageTextInput
            label="Email*"
            value={email}
            inputProps={{
              readOnly: true,
            }}
          />
          <InputPageTextInput
            className="mt-4"
            label="Enter You Mobile Number"
            value={mobileNumber}
            inputProps={{
              readOnly: true,
            }}
          />
          <InputPageTextInput
            className="mt-4"
            label="Enter OTP"
            value={inputOTP}
            onChange={e => setInputOTP(e.target.value)}
            onInput={allowOnlyNumbers}
            inputProps={{ maxLength: 6 }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          <InputFormContinueButtonStyled
            onClick={e => {
              e.preventDefault();
              dispatch(
                saveOtpData({
                  mobile: mobileNumber,
                  email: email,
                  section: "term",
                }),
              );
            }}
          >
            Resend OTP
          </InputFormContinueButtonStyled>{" "}
          <InputFormContinueButtonStyled
            style={{
              backgroundColor: `${
                disable === true ? "#969799" : "var(--primary-color)"
              }`,
            }}
            disabled={disable}
            onClick={e => {
              e.preventDefault();
              if (inputOTP.length >= 6 && disable === false) {
                dispatch(sendOtpData({ otp: inputOTP }));
              }
            }}
          >
            Verify
          </InputFormContinueButtonStyled>
        </>
      </DialogActions>
    </Dialog>
  );
}

export default OTPPopupForm;

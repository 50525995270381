import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../../Landing/input.slice.js";
import { useInputFormsHook } from "../../hooks";
import { personalFormSchema } from "./validation.js";
import { toast } from "react-toastify";

const usePersonalForm = (current, setCurrent) => {
  const { getPersonalFormData, setPersonalFormData } = useInputFormsHook();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(personalFormSchema({ minAge: 18, maxAge: 65 })),
    defaultValues: {},
    mode: "all",
  });
  const { sellerData } = useSelector(state => state.inputForm);
  const { tenant } = useSelector(state => state.frontendBoot?.frontendData);
  const url = tenant?.frontend_domains?.filter(
    item => item?.source === "login_url",
  )?.[0]?.base_url;
  const [age, setAge] = useState(getPersonalFormData()?.age);
  useEffect(() => {
    reset(getPersonalFormData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);
  const dispatch = useDispatch();
  const toaster = () => {
    toast.error(
      sellerData?.message
        ? sellerData?.message
        : "Token Expired, Login Again !!",
      {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        onClose: () => {
          window.open(url, "_self");
        },
      },
    );
  };
  const onSubmit = data => {
    if (sellerData?.status !== false) {
      dispatch(
        setData({ ...data, dob: data.dob.split(" / ").reverse().join("-") }),
      );
      setCurrent(current + 1);
      setPersonalFormData(data, age);
    } else {
      toaster();
    }
  };
  const date = watch("dob");
  const annualIncome = watch("annual_income");

  return {
    onSubmit,
    handleSubmit,
    register,
    errors,
    clearErrors,
    date,
    age,
    setAge,
    setValue,
    annualIncome,
  };
};

export default usePersonalForm;

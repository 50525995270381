import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import TextInput from "../../QuotesPage/components/EditDetailsSection/components/TextInput";
import ShareViaForm from "../../QuotesPage/components/Share/ShareForm";
import { useShareQuoteMutation } from "../../rider/services/rider";
import * as yup from "yup";
import { toast } from "react-toastify";
import { MdOutlinePhone } from "react-icons/md";
import { selectCompareQuotes } from "../ComparePlanPopupSlice";
const mobileSchema = yup.object({
  mobile: yup
    .number()
    .integer()
    .typeError("Please enter a valid phone number")
    .required("Required"),
});

function ShareTextCompare() {
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const { data } = useSelector(state => state.inputForm);
  const compareQuotes = useSelector(selectCompareQuotes);
  const { showDifference, selectedFeatures } = useSelector(
    state => state.compare,
  );
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { mobile: data.mobile },
    resolver: yupResolver(mobileSchema),
  });

  const onSubmit = async formData => {
    let selected = [];
    const key = Object.keys(selectedFeatures);
    // eslint-disable-next-line array-callback-return
    key.map(item => {
      selected.push(selectedFeatures[item]);
    });
    selected = selected.flat();

    shareQuotes({
      purpose: "compare",
      mobile: formData.mobile,
      sms: formData.mobile,
      via: "sms",
      selected_riders: {},
      show_difference: showDifference,
      product_ids: compareQuotes,
      selected_features: selectedFeatures,
      mode: ["SMS"],
      stage: "COMPARE",
      whatsapp: "",
    }).then(data => {
      if (data.error) {
        toast.error("Sharing Failed!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.success("Compare Shared Successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  };
  const [mobile, setMobile] = useState(data.mobile);

  return (
    <ShareViaForm onSubmit={handleSubmit(onSubmit)} isLoading={isLoading}>
      <TextInput
        error={errors.mobile && errors.mobile.message}
        {...register("mobile")}
        value={mobile}
        onChange={evt => {
          register("mobile").onChange(evt);
          setMobile(evt.target.value);
          setValue("mobile", evt.target.value);
        }}
        inputProps={{
          maxLength: 10,
        }}
        label="Mobile"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdOutlinePhone className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
    </ShareViaForm>
  );
}

export default ShareTextCompare;

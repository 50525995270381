import Navbar from "../../components/navbar/Navbar-Component";
import { InputPageMessage } from "./components";
import InputPageForm from "./forms";
import { useInputPageHook } from "./hooks";
import {
  InputPageContainerStyled,
  InputPageWrapperStyled,
} from "./styles/inputPage.styled";

const InputPage = () => {
  const { inputPageBackgroundImage = "" } = useInputPageHook();
  return (
    <>
      <Navbar />
      <InputPageContainerStyled bg={inputPageBackgroundImage}>
        <InputPageWrapperStyled>
          <InputPageMessage />
          <InputPageForm />
        </InputPageWrapperStyled>
      </InputPageContainerStyled>
    </>
  );
};

export default InputPage;

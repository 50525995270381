import React, { useState } from "react";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import "styled-components/macro";
import * as mq from "../../styles/mediaQueries";
import { Container, Grid } from "@mui/material";

import RiderNew from "./RiderNew";
import RiderNewPlanCard from "./RiderPlanCard/RiderNewPlanCard";

function RiderSection() {
  const [loader, setLoader] = useState(false);
  const [load, setLoad] = useState(true);
  const matches = useMediaQuery(`${mq.smForHook}`);
  return (
    <div>
      <Container
        sx={{
          p: "0 !important",
        }}
      >
        <Grid container>
          {!matches && (
            <Grid item xs={4}>
              <RiderNewPlanCard
                loader={loader}
                load={load}
                setLoader={setLoader}
              />
            </Grid>
          )}

          <Grid item sm={12} md={8}>
            <RiderNew loader={loader} setLoader={setLoader} setLoad={setLoad} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default RiderSection;

/* eslint-disable no-unused-vars */
import { styled } from "@material-ui/core";
import { InputAdornment, TextField } from "@mui/material";
import React from "react";

function TextInput(props) {
  const {
    name = "",
    label,
    type,
    register,
    errors = {},
    errorIcon,
    ...rest
  } = props;

  const { onChange, ...registerProps } = register(name);

  return (
    <div>
      <CustomField
        className="w-100"
        label={label}
        variant="outlined"
        color="primary"
        // focused
        // errors={errors}
        // errors={Boolean(errors)}
        // errors={errors.name}
        // helperText={errors.name && errors.name.message}
        // placeholder={placeholder}
        {...register(name)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {/* {errorIcon && (
                <span
                  className="fs-5"
                  css={`
                    color: #fa703a;
                  `}
                >
                  <MdError />
                </span>
              )} */}
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </div>
  );
}

export default TextInput;

const CustomField = styled(TextField)({
  minHeight: "65px",
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px 14px",
    backgroundColor: "rgb(255, 255, 255)",
    textTransform: prop => prop.capitalize && "capitalize",
    height: "65px",
  },
  "& label.Mui-focused, & label.MuiFormLabel-filled, & label.Mui-focused.Mui-error":
    {
      transform: "translate(14px, -13px) scale(0.75)",
      color: "var(--primary-color)",
    },
  "& label.Mui-error": {
    color: "rgba(0, 0, 0, 0.6)",
  },
  "& .MuiInputLabel-root": {
    top: "6px",
  },
  "& .MuiTypography-root ": {
    color: "var(--primary-color) !important",
    fontFamily: "Inter !important",
    fontWeight: "600 !important",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    height: "65px",
    "&.Mui-focused fieldset": {
      borderColor: "var(--primary-color)",
    },
    "&.Mui-focused.Mui-error fieldset": {
      borderColor: "#d32f2f",
    },
  },
});

// const CustomField = styled(TextField)({
//   "& .MuiOutlinedInput-input": {
//     padding: "14px 14px",
//     backgroundColor: "rgb(255, 255, 255)",

//     textTransform: (prop) => prop.capitalize && "capitalize",
//     "@media (max-width: 768px)": {
//       height: "1em !important",
//     },
//   },

//   "& .MuiInputAdornment-root": { color: "#d32f2f" },
//   "& .MuiInput-root ": {
//     color: (prop) => (prop.error ? "#d32f2f" : "initial"),
//     fontWeight: "600",
//     "&:hover,&:focus": {
//       color: (prop) => (prop.error ? "#d32f2f" : "var(--primary-color)"),
//     },
//   },
//   "& .MuiTypography-root ": {
//     color: "black",
//     fontWeight: "600",
//   },
//   "& .MuiFormHelperText-root": {
//     textAlign: "end",
//   },
//   "& label.Mui-focused, & label.MuiFormLabel-filled, & label.Mui-focused.Mui-error":
//     {
//       color: (prop) => (prop.error ? "#d32f2f" : "var(--primary-color)"),
//     },
//   "& label.Mui-error": {
//     color: "rgba(0, 0, 0, 0.6)",
//   },

//   "& .MuiOutlinedInput-root": {
//     borderRadius: "0",
//     "@media (max-width: 768px)": {
//       fontSize: "14px !important",
//     },

//     "&.Mui-focused fieldset": {
//       borderColor: "var(--primary-color)",
//     },
//     "&.Mui-focused.Mui-error fieldset": {
//       borderColor: "#d32f2f",
//     },
//   },
// });

import { Avatar, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components/macro";
import companyLogo from "../../assets/images/logo/logo.png";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import { clearQuotes } from "../../pages/ComparePage/ComparePlanPopupSlice";
import ContactUsBtnComp from "../../pages/QuotesPage/components/EditDetailsSection/components/ContactUsBtn-Comp";
import { ShareContactButton } from "../../pages/QuotesPage/components/EditDetailsSection/components/EditDetails.styles";
import PersonalDetails from "../../pages/QuotesPage/components/EditDetailsSection/components/PersonalDetails";
import ShareQuoteBtnComp from "../../pages/QuotesPage/components/Share/ShareQuoteBtn-Comp";
import TraceId from "../../pages/QuotesPage/components/TraceId";
import { useGetSellerInfoQuery } from "../../pages/rider/services/rider";
import * as mq from "../../styles/mediaQueries";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import { setsellerData } from "../../pages/Landing/input.slice";

const Navbar = ({ rider, proposal }) => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { quotesLoading } = useSelector(state => state.quotes);
  const token = useUrlQuery("token");
  const { frontendData } = useSelector(state => state.frontendBoot);
  const tenant = frontendData?.tenant;
  const tenant_name = tenant?.name;
  const { data: sellerData } = useGetSellerInfoQuery(
    { token: token },
    { skip: !token },
  );
  useEffect(() => {
    if (sellerData) dispatch(setsellerData(sellerData));
  }, [sellerData, dispatch]);

  const matches = useMediaQuery(`${mq.smForHook}`);
  return (
    <>
      <div
        className="navbar border-bottom p-7 flex-nowrap"
        css={`
          height: 50px;
        `}
      >
        <div
          className="container-fluid"
          css={`
            width: 96rem;
          `}
        >
          <NavWrapper>
            <Logo />

            {rider ||
            proposal ||
            location.pathname === "/investment/thankyou" ||
            location.pathname === "/success" ||
            location.pathname === "/document_upload" ? (
              <div
                css={`
                  display: flex;
                  height: 31.5px;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <TraceId label={matches ? "CRN : " : "CRN/Trace Id : "} />
              </div>
            ) : (
              <div
                css={`
                  display: flex;
                  gap: 10px;
                `}
              >
                {location.pathname === "/" ||
                location.pathname === "/investment/thankyou" ||
                location.pathname === "/success" ? (
                  <>
                    {location.pathname === "/" &&
                      token &&
                      tenant_name === "Bajaj" && (
                        <>
                          <Box display={"flex"} gap={"6px"}>
                            <Avatar
                              sx={{
                                background: "var(--primary-color)",
                              }}
                            >
                              {sellerData?.seller_name?.[0]}
                            </Avatar>
                            <Box>
                              <div
                                css={`
                                  font-weight: 600;
                                  font-size: 14px;
                                  text-transform: capitalize;
                                `}
                              >
                                {sellerData?.seller_name}
                              </div>
                              <div>{sellerData?.seller_user_name}</div>
                            </Box>
                          </Box>
                        </>
                      )}
                  </>
                ) : (
                  <>
                    <PersonalDetails />

                    <ShareContactButton
                      onClick={() => {
                        dispatch(clearQuotes());
                        if (Object.values(quotesLoading).includes(true)) {
                          toast.info("Some Quotes are loading.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                          });
                        } else setShowShareModal(true);
                      }}
                    >
                      <i
                        className="fa fa-share icon-left"
                        aria-hidden="true"
                      ></i>{" "}
                      Share Quotes
                    </ShareContactButton>
                    <ShareContactButton
                      css={`
                        margin-left: 10px;
                      `}
                      onClick={() => {
                        dispatch(clearQuotes());
                        setShowContactModal(true);
                      }}
                    >
                      <i
                        className="fa fa-phone-alt icon-left"
                        aria-hidden="true"
                      ></i>{" "}
                      Contact Us
                    </ShareContactButton>
                    <ShareQuoteBtnComp
                      showPopup={showShareModal}
                      setShowPopup={setShowShareModal}
                    />
                  </>
                )}
              </div>
            )}
          </NavWrapper>
        </div>
      </div>
      <ContactUsBtnComp show={showContactModal} setShow={setShowContactModal} />
    </>
  );
};
export default Navbar;

export function Logo(props) {
  // const {
  //   frontendData: { investmentsettings },
  // } = useSelector(state => state.frontendBoot);
  // const brokerLogo = investmentsettings?.logo || companyLogo;
  const brokerLogo = companyLogo;
  return (
    <>
      <LogoWrapper className="logo" {...props}>
        <a href="/">
          <img
            src={brokerLogo}
            alt=""
            style={{
              maxWidth: "140px",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </a>
      </LogoWrapper>
    </>
  );
}

export const LogoWrapper = styled.div`
  width: 150px;
  height: auto;
`;
export const NavWrapper = styled.div`
  width: 100%;
  padding: 0px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mq.lg} {
    padding: 0px 3.5%;
  }
  ${mq.md} {
    padding: 0px 0.6%;
  }
  ${mq.sm} {
    padding: 0px 0.1%;
  }
`;

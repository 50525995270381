import { useSelector } from "react-redux";
import { InputFormsTitle, PreferredICCard } from "../../components";
import { useInputPageHook } from "../../hooks";
import {
  InputFormButtonWrapperStyled,
  InputFormContinueButtonStyled,
  PreferredICLogoContainerStyled,
} from "../../styles/inputForms.styled";
import usePreferredICForm from "./usePreferredICForm";

const PreferredICForm = ({ setCurrent, current }) => {
  const { investmentInputPage4, investmentInputsCompany = {} } =
    useInputPageHook();
  const allProductNames = [];
  const { isLoading } = useSelector(state => state?.frontendBoot);
  Object?.keys(investmentInputsCompany)?.forEach(insuranceCompanyName => {
    const products = investmentInputsCompany[insuranceCompanyName];
    Object?.keys(products)?.forEach(productName => {
      allProductNames.push({ productName, insuranceCompanyName });
    });
  });
  const { onSubmit, handleSubmit, onSkip, setValue } =
    usePreferredICForm(setCurrent);
  const { preferred_ic, preferred_product } = useSelector(
    state => state?.inputForm?.data,
  );
  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <InputFormsTitle
          title={investmentInputPage4}
          current={current}
          onSubmit={handleSubmit(preferred_ic ? onSubmit : onSkip)}
        >
          <PreferredICLogoContainerStyled>
            {allProductNames?.map(product => {
              return (
                <PreferredICCard
                  keys={JSON.stringify(product)}
                  productName={product?.productName}
                  insuranceCompanyName={product?.insuranceCompanyName}
                  setValue={setValue}
                  preferredProduct={preferred_product}
                />
              );
            })}
          </PreferredICLogoContainerStyled>
          <InputFormButtonWrapperStyled>
            {preferred_ic ? (
              <InputFormContinueButtonStyled type="submit">
                Continue
              </InputFormContinueButtonStyled>
            ) : (
              <InputFormContinueButtonStyled type="submit">
                Skip
              </InputFormContinueButtonStyled>
            )}
          </InputFormButtonWrapperStyled>
        </InputFormsTitle>
      )}
    </>
  );
};

export default PreferredICForm;

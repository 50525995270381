import styled from "styled-components";

export const FilterWrapper = styled.div`
  width: calc(100% - 10px);
  background: ${props =>
    props.offset > 80 ? "var(--primary-shade)" : "rgb(255, 255, 255)"};
  color: ${props => props.offset > 80 && "white !important"};
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 8px 0px;
  display: flex;
  margin-bottom: ${props => (props.isComparePage ? "5px" : "0")};
  padding: 4px 0;
  margin-left: 5px;
  position: ${props => !props.isComparePage && "sticky"};
  z-index: 100;
  top: 0px;
  transition: all 0.3s ease;
`;
export const FilterSection = styled.div`
  /* width: ${props => (props.width ? "24%" : "28%")}; */
  width: 20%;
  font-size: 13px;
  border-right: ${props =>
    !props.last ? "1px solid var(--border-light);" : ""};
  margin: 5px;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  justify-content: space-evenly;
  cursor: pointer;
  .single-pay {
    text-align: center;
    font-family: Inter;
    font-weight: 500;
  }
`;
export const PaddedWrapper = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
`;
export const FilterLabel = styled.div`
  width: 100%;
  color: ${props => (props.offset > 80 ? "white" : "rgba(34, 34, 34)")};
  font-size: 11px;
`;
export const ExtraDiv = styled.div`
  color: rgba(34, 34, 34);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 2.5rem;
`;

export const FilterSwitchWrapper = styled.div`
  display: flex;
`;
export const AmountText = styled.div`
  height: 20px;
  outline: none;
  border: 0px;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  font-family: "Inter";
  font-weight: 600;
  text-transform: capitalize;
`;

export const DropDownListWithScrollBar = styled.div`
  max-height: 200px;
  position: absolute;
  left: 8px;
  top: 100%;
  width: calc(100% - 16px);
  z-index: 101;
  background-color: #fff;
  display: ${props => (props.show ? "inline-block" : "none")};
  border: 3px solid #e9ecef;
  border-top: none;
  box-shadow: 0px 50px 100px 0px rgb(229 232 235 / 20%);
  border-radius: 0 0 3px;
  overflow-y: overlay;
  ::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dbdad5;
    outline: none;
    display: block;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #babac0;
  }
`;

export const Item = styled.div`
  cursor: pointer;
  font-size: 11px;
  position: relative;
  min-height: 20px;
  width: 100%;
  color: #111;
  background-color: ${props => {
    if (props.selected) return "var(--primary-color)";
  }};
  color: ${props => {
    if (props.selected) return "#fff";
  }};
  padding: 4px 8px;
  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
`;

export const PayOnceButton = styled.div`
  width: 100%;
  bottom: 0px;
  position: sticky;
  & .custom-btn {
    width: 100%;
    padding: 6px 0;
    background-color: #fff;
    font-family: Inter;
    font-weight: 500;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
  }
`;

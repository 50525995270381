/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

function useGetInWords(value) {
  function calcFunction() {
    return value => {
      var with2Decimals = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      return with2Decimals;
    };
  }
  function getInWordsFunction() {
    return value => {
      if (!value) return null;
      value = String(value);
      const length = value.length;
      if (length >= 8) {
        return `${calc(value / Math.pow(10, 7))} Cr`;
      } else if (length >= 6) {
        return `${calc(value / Math.pow(10, 5))} Lac`;
      } else if (length >= 4) {
        return `${calc(value / Math.pow(10, 3))} K`;
      } else return value;
    };
  }
  const calc = calcFunction();
  const getInWords = getInWordsFunction();

  let temp = getInWords(parseInt(value));

  useEffect(() => {
    temp = getInWords(parseInt(value));
  }, [value]);

  if (temp) return temp.toString();
  else return "0";
}

export default useGetInWords;

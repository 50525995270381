import { AiOutlineCloudDownload } from "react-icons/ai";
import { GrAdd } from "react-icons/gr";
import styled from "styled-components/macro";
import ButtonLoader from "../../../../components/Loaders/ButtonLoader/ButtonLoader";
import useGetInvestmentData from "../../../../custom-hooks/useGetInvestmentData";
import moneyTransfer from "./../../../../assets/images/moneyTransfer.png";
import bundleImg from "./../../../../assets/svg/bundle.svg";
import incomeImg from "./../../../../assets/svg/income.svg";
import lumpsumImg from "./../../../../assets/svg/lumpsum.svg";
import stackImg from "./../../../../assets/svg/stack.svg";
import { useSelector } from "react-redux";
import { getInWords } from "../../../../utils/helper";
import * as mq from "../../../../styles/mediaQueries";
import { Box, Paper, Stack, Typography } from "@mui/material";
// import { Box, Paper, Slider, Stack, Typography } from "@mui/material";
import useMediaQuery from "../../../../custom-hooks/useMediaQuery";
import ViewDetailsPopupMobile from "./ViewDetailsPopupMobile";
import { useState } from "react";
// import { updateEnquiries } from "../../../Landing/input.slice";

function BenefitTimeline({ quoteData, biData, isBiLoading }) {
  const [showModal, setShowModal] = useState(false);
  const { documentData } = useSelector(state => state.quotes);
  const documents = documentData;
  const {
    investedAmountInWords,
    isLumpsum,
    isBoth,
    isIncome,
    frequency,
    totalInvestment,
    isSinglePay,
  } = useGetInvestmentData({ quoteData });
  const earlyIncome = quoteData?.early_income;
  const earlyIncomeStart = quoteData?.early_income_start_year;
  const earlyIncomeEnds = quoteData?.early_income_end_year;
  const { product_type, selected_payout_term = "" } = quoteData
    ? quoteData
    : {};
  const isPar = product_type === "PAR";
  const selectedPayoutTerm = selected_payout_term ? selected_payout_term : "";
  const filters = useSelector(state => state.filters);
  const d = new Date();
  const handleClose = () => setShowModal(false);
  return (
    <div
      css={`
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        ${mq.md} {
          min-height: 100%;
          width: 100%;
          flex-direction: column;
          justify-content: space-between;
        }
      `}
    >
      <TimeLine>
        <TimelineCard
          title={
            isSinglePay
              ? "You Invest"
              : `You Invest for ${filters.investFor} years`
          }
          isInvest={!isSinglePay}
          border
        >
          {isSinglePay ? (
            <Box display={"flex"} justifyContent="center" py={2}>
              <InvestColumn
                data={[
                  `${totalInvestment}`,
                  bundleImg,
                  `In : ${d.getFullYear()}`,
                ]}
              />
            </Box>
          ) : (
            <Box
              display={"flex"}
              gap="20px"
              justifyContent="center"
              flex={"1 1"}
              py={2}
              sx={{
                borderBottomLeftRadius: "14px",
                borderBottomRightRadius: "14px",
                height: "100%",
              }}
            >
              <InvestColumn
                isInvest
                data={[
                  `${getInWords(quoteData?.investment_amount)}  ${frequency}`,
                  stackImg,
                  `From : ${d.getFullYear()}`,
                ]}
                border
              />
              <InvestColumn
                isInvest
                data={[
                  `${totalInvestment} In ${filters.investFor} years`,
                  bundleImg,
                  `Till : ${d.getFullYear() + parseInt(filters.investFor)}`,
                ]}
              />
            </Box>
          )}
        </TimelineCard>

        {isPar ? (
          <>
            <TimelineCard title="You Receive (4.0% p.a)" border={isPar}>
              <Box
                display={"flex"}
                justifyContent="center"
                height="100%"
                py={2}
                gap={"20px"}
              >
                {isLumpsum ? (
                  <InvestColumn
                    data={[
                      `${getInWords(+quoteData?.maturity_benefit4)} as Lumpsum`,
                      lumpsumImg,
                      `At ${quoteData?.policy_term} years`,
                    ]}
                  />
                ) : isBoth ? (
                  <>
                    <InvestColumn
                      data={[
                        `${getInWords(
                          +quoteData?.income_benefit4,
                        )} Income ${selectedPayoutTerm} for ${
                          quoteData.income_for
                        } years`,
                        incomeImg,
                        `Starting at ${quoteData?.income_start_at} years`,
                      ]}
                      isMultiple
                    />
                    <InvestColumn
                      data={[
                        `${getInWords(
                          +quoteData?.maturity_benefit4,
                        )} Lumpsum at Policy Maturity`,
                        lumpsumImg,
                        quoteData?.additional_rop
                          ? "Return of Premium"
                          : "Lumpsum",
                      ]}
                    />
                  </>
                ) : isIncome ? (
                  <>
                    {earlyIncome > 0 && (
                      <>
                        <InvestColumn
                          data={[
                            `₹ ${getInWords(
                              earlyIncome,
                            )} as Early Income From `,
                            moneyTransfer,

                            ` ${earlyIncomeStart} Year to ${earlyIncomeEnds} Year `,
                          ]}
                          border
                        />
                      </>
                    )}
                    {earlyIncome > 0 && (
                      <>
                        <Box position="absolute" marginTop="86px">
                          <GrAdd fontSize="20px" />
                        </Box>
                      </>
                    )}
                    <InvestColumn
                      data={[
                        `₹ ${getInWords(
                          +quoteData?.income_benefit4,
                        )} ${selectedPayoutTerm} Income for ${
                          filters.incomeFor > 100
                            ? "Till Death"
                            : `${filters.incomeFor} years`
                        }`,
                        incomeImg,
                        `Starting at ${quoteData?.income_start_at}th year`,
                      ]}
                      border
                    />
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </TimelineCard>
            <TimelineCard title="You Receive (8.0% p.a)">
              <Box
                display={"flex"}
                justifyContent="center"
                height="100%"
                py={2}
                gap={"20px"}
              >
                {isLumpsum ? (
                  <InvestColumn
                    data={[
                      `${getInWords(+quoteData?.maturity_benefit8)} as Lumpsum`,
                      lumpsumImg,
                      `At ${quoteData?.policy_term} years`,
                    ]}
                  />
                ) : isBoth ? (
                  <>
                    <InvestColumn
                      data={[
                        `${getInWords(
                          +quoteData?.income_benefit8,
                        )} Income ${selectedPayoutTerm} for ${
                          quoteData.income_for
                        } years`,
                        incomeImg,
                        `Starting at ${quoteData?.income_start_at} years`,
                      ]}
                      isMultiple
                    />
                    <InvestColumn
                      data={[
                        `${getInWords(
                          quoteData?.maturity_benefit8,
                        )} Lumpsum at Policy Maturity`,
                        lumpsumImg,
                        quoteData?.additional_rop
                          ? "Return of Premium"
                          : "Lumpsum",
                      ]}
                    />
                  </>
                ) : isIncome ? (
                  <>
                    {earlyIncome > 0 && (
                      <>
                        <InvestColumn
                          data={[
                            `₹ ${getInWords(
                              earlyIncome,
                            )} as Early Income From `,
                            moneyTransfer,

                            ` ${earlyIncomeStart} Year to ${earlyIncomeEnds} Year `,
                          ]}
                          border
                        />
                      </>
                    )}
                    {earlyIncome > 0 && (
                      <>
                        <Box position="absolute" marginTop="86px">
                          <GrAdd fontSize="20px" />
                        </Box>
                      </>
                    )}
                    <InvestColumn
                      data={[
                        `₹ ${getInWords(
                          +quoteData?.income_benefit8,
                        )} ${selectedPayoutTerm} Income for ${
                          filters.incomeFor > 100
                            ? "Till Death"
                            : `${filters.incomeFor} years`
                        }`,
                        incomeImg,
                        `Starting at ${quoteData?.income_start_at}th year`,
                      ]}
                      border
                    />
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </TimelineCard>
          </>
        ) : (
          <TimelineCard title={"You Receive"}>
            <Box
              display={"flex"}
              justifyContent="center"
              height="100%"
              py={2}
              gap={"20px"}
            >
              {isLumpsum ? (
                <InvestColumn
                  data={[
                    `${getInWords(+quoteData?.maturity_benefit)} as Lumpsum`,
                    lumpsumImg,
                    `At ${quoteData?.policy_term} years`,
                  ]}
                />
              ) : isBoth ? (
                <>
                  <InvestColumn
                    data={[
                      `${getInWords(
                        quoteData?.income_benfit,
                      )} Income ${selectedPayoutTerm} for ${
                        quoteData.income_for
                      } years`,
                      incomeImg,
                      `Starting at ${quoteData?.income_start_at} years`,
                    ]}
                    isMultiple
                  />
                  <InvestColumn
                    data={[
                      `${getInWords(
                        quoteData?.additional_lumpsum ||
                          quoteData?.additional_rop,
                      )} Lumpsum at Policy Maturity`,
                      lumpsumImg,
                      quoteData?.additional_rop
                        ? "Return of Premium"
                        : "Lumpsum",
                    ]}
                  />
                </>
              ) : isIncome ? (
                <>
                  {earlyIncome > 0 && (
                    <>
                      <InvestColumn
                        data={[
                          `₹ ${getInWords(earlyIncome)} as Early Income From `,
                          moneyTransfer,
                          ` ${earlyIncomeStart} Year to ${earlyIncomeEnds} Year `,
                        ]}
                        border
                      />
                    </>
                  )}
                  {earlyIncome > 0 && (
                    <>
                      <Box position="absolute" marginTop="86px">
                        <GrAdd fontSize="20px" />
                      </Box>
                    </>
                  )}
                  <InvestColumn
                    data={[
                      `₹ ${getInWords(
                        quoteData?.income_benfit,
                      )} ${selectedPayoutTerm} Income for ${
                        filters.incomeFor > 100
                          ? "Till Death"
                          : `${filters.incomeFor} years`
                      }`,
                      incomeImg,
                      `Starting at ${quoteData?.income_start_at}th year`,
                    ]}
                    border
                  />
                </>
              ) : (
                <></>
              )}
            </Box>
          </TimelineCard>
        )}
      </TimeLine>

      <div
        css={`
          margin-top: 20px;
          margin-bottom: 30px;
          @media screen and (min-width: 820px) and (max-width: 884px) {
            padding-top: 212px;
          }
        `}
      >
        <div className="w-100 d-flex justify-content-center align-items-center">
          <DownLoadSection>
            <DownText>
              <div className="downTop">Downloads</div>
              <div className="downBottom">
                To find out more about the products, kindly refer Brochure.
                Please refer to the Benefit Illustration for year wise Premium
                Amount / Maturity Benefit / Income Benefit / Booster
                / Death Benefit.
              </div>
            </DownText>
            <div
              css={`
                display: flex;
                gap: 2%;
                width: 55%;
                align-items: center;
                ${mq.lg} {
                  width: 42%;
                }
                ${mq.md} {
                  width: 100%;
                  margin-top: 1rem;
                }
              `}
            >
              <DownButton
                href={documents?.brochure_url}
                target={"_blank"}
                disabled={!documents?.brochure_url}
              >
                Brochure
                <AiOutlineCloudDownload />
              </DownButton>
              <DownButton
                disabled={isBiLoading}
                href={biData?.data[0]?.bi_url}
                download=""
                target="_blank"
              >
                {isBiLoading ? (
                  <ButtonLoader colored={true} />
                ) : (
                  <>
                    Benefit Illustration
                    <AiOutlineCloudDownload />
                  </>
                )}
              </DownButton>
            </div>
          </DownLoadSection>
        </div>
        {/* {[888, 889, 890, 891]?.includes(quoteData?.product?.id) && (
          <>
            <div
              className="w-100 d-flex justify-content-center align-items-center gap-2"
              css={`
                width: 90% !important;
                padding: 15px 0 0 0;
                margin: 35px auto 0;
                border-radius: 10px;
              `}
            >
              <b>Share Allocation Percentage for each Variant</b>
            </div>
            <div
              className="w-100 d-flex justify-content-center align-items-center gap-2"
              css={`
                width: 90% !important;
                padding: 15px 0;
                margin: 25px auto 0;
                border-radius: 10px;
              `}
            >
              <div style={{ textAlign: "center", marginRight: "20px" }}>
                <b>{benefitSliderValue}%</b>
                <div>Early Income</div>
              </div>
              <div
                css={`
                  width: 60%;
                `}
              >
                <SliderStyled
                  valueLabelDisplay="auto"
                  aria-label=" slider"
                  defaultValue={50}
                  onChange={e => setBenefitSliderValue(e.target.value)}
                  onChangeCommitted={async () => {
                    await dispatch(
                      updateEnquiries({
                        input: {
                          selected_product: {
                            investment_insurance_id:
                              quoteData?.investment_insurance_id,
                            product_id: quoteData?.product?.id,
                            early_percentage: benefitSliderValue,
                            wealth_percentage: 100 - benefitSliderValue,
                          },
                        },
                      }),
                    );
                  }}
                  min={0}
                  max={100}
                  step={1}
                />
              </div>
              <div style={{ textAlign: "center", marginLeft: "20px" }}>
                <b>{100 - +benefitSliderValue}%</b>
                <div>Wealth Income</div>
              </div>
            </div>
          </>
        )} */}

        <div
          className="w-100 d-flex justify-content-center align-items-center  "
          css={`
            ${mq.md} {
              padding: 20px 0px 12px 0px;
            }
          `}
        >
          <DetailsSection>
            <FirstPart>
              <Label>Total Investment Amount</Label>
              {/* <Value>Rs 40,135/Year</Value> */}
              <p
                className="dataSub2"
                css={`
                  font-size: 16px;
                  font-family: "Inter";
                  font-weight: 600;
                  margin-bottom: 0px;
                  ${mq.lg} {
                    font-size: 11px;
                  }
                `}
              >
                {investedAmountInWords} {!isSinglePay ? frequency : ""}
              </p>
            </FirstPart>
            <SecondPart>
              <ViewDetails onClick={() => setShowModal(true)}>
                View Details
              </ViewDetails>

              <ViewDetailsPopupMobile
                download={documents?.brochure_url}
                show={showModal}
                handleClose={handleClose}
                quoteData={quoteData}
              />
            </SecondPart>
          </DetailsSection>
        </div>
      </div>
    </div>
  );
}
const InvestColumn = ({ isInvest, data = [], border, isMultiple }) => {
  const matches = useMediaQuery(`${mq.mdForHook}`);
  return (
    <Paper
      sx={{
        width: matches ? "140px" : "160px",
        height: "180px",
        display: "flex",
        flexDirection: "column",
      }}
      elevation={3}
    >
      <Stack
        justifyContent={"center"}
        alignItems="center"
        gap="4px"
        px={2}
        sx={{
          flex: "1 1",
          position: "relative",
        }}
      >
        <Box
          height={{
            xs: "40px",
            md: "46px",
          }}
        >
          <img src={data[1]} alt="" height={"100%"} />
        </Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontWeight: "600",
            fontSize: {
              xs: "12px",
              md: "14px",
            },
            "@media screen and (min-width:1440px)": {
              fontSize: "16px",
            },
          }}
        >
          {data[0]}
        </Typography>

        <Typography
          sx={{
            color: "#bbb",
          }}
          fontSize={{
            xs: "11px",
            md: "12px",
            "@media screen and (min-width:1440px)": {
              fontSize: "14px",
            },
          }}
        >
          {data[2]}
        </Typography>
        <Typography>{data[3]}</Typography>
        {isMultiple && (
          <>
            <Box position="absolute" right="-19px">
              <GrAdd fontSize="20px" />
            </Box>
          </>
        )}
      </Stack>
    </Paper>
  );
};
const TimelineCard = ({ title, isInvest, children, border }) => {
  const isMobileOrTablet = useMediaQuery(`${mq.mdForHook}`);
  return (
    <Box
      css={`
        width: 35%;

        ${mq.md} {
          width: 72% !important;
          margin-top: 1em !important;
        }
      `}
      sx={{
        position: "relative",
        ...(!isMobileOrTablet &&
          border && {
            "&:after": {
              content: "''",
              height: "calc(100% + 20px)",
              bgcolor: "#ccc",
              width: "1px",
              display: "inline-block",
              position: "absolute",
              top: " 0px",
              right: "-8px",
            },
          }),
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "18px",
          py: "2px",
          position: "relative",
          mb: 2,
          "&:after": {
            position: "absolute",
            content: "''",
            height: "6px",
            width: "40px",
            bgcolor: "#46c603",
            borderRadius: "8px",
            bottom: -2,
            left: "50%",
            transform: "translateX(-50%)",
          },
          "@media screen and (min-width:1440px)": {
            fontSize: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            "&:after": {
              bottom: 2,
            },
          },
        }}
      >
        {title}
      </Box>
      <Box display="flex" justifyContent="center">
        {children}
      </Box>
    </Box>
  );
};

const TimeLine = styled.div`
  margin: 1rem auto 0px;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  ${mq.md} {
    width: 100%;
    display: flex;
    /* margin-bottom: 0px; */
    flex-direction: column;
    padding: 0px;
    /* height: 380px; */
  }
`;

const DownLoadSection = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 4px 0px;
  border-radius: 10px;
  margin-top: 10px;
  ${mq.md} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ${mq.xs} {
    padding: 10px;
  }
`;

const DownText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  .downTop {
    font-size: 16px;
    font-family: "Inter";
    font-weight: 600;
    ${mq.lg} {
      font-size: 14px;
    }
    ${mq.xs} {
      font-size: 14px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 22px;
    }
  }
  .downBottom {
    font-size: 12px;
    font-family: "Inter";
    ${mq.lg} {
      font-size: 11px;
    }
    ${mq.xs} {
      font-size: 10px;
    }
  }
`;

const DownButton = styled.a`
  text-decoration: none;
  height: 55px;
  width: 49%;
  color: var(--secondary-text);
  border: 1px solid rgb(0, 86, 254, 0.1);
  background-color: var(--primary-color-light);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid rgb(0, 86, 254, 0.9);
    color: var(--secondary-text);
  }

  ${mq.lg} {
    height: 44px;
    font-size: 12.5px;
    gap: 3px;
  }

  ${mq.md} {
    height: 40px;
    flex: 1;
    margin: 5px;
    font-size: 12px;
  }
  ${mq.xs} {
    height: 30px;
    font-size: 11px;
  }
`;

const DetailsSection = styled.div`
  display: none;
  ${mq.md} {
    display: flex;
    width: 90%;
    margin-top: 1rem;
  }
`;

const FirstPart = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const SecondPart = styled.div`
  display: flex;
  flex: 1;
`;
const Label = styled.div``;
// const Value = styled.div`
//   font-size: 16px;
//   font-weight: 600;
//   ${mq.xs} {
//     font-size: 14px;
//   }
// `;
const ViewDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  width: 100%;
`;

export default BenefitTimeline;

// const SliderStyled = styled(Slider)({
//   color: "var(--primary-color)",
//   height: 10,
//   "& .MuiSlider-rail": {
//     height: 10,
//   },
//   "& .MuiSlider-track": {
//     height: 10,
//     border: "none",
//   },
//   "& .MuiSlider-thumb": {
//     height: 24,
//     width: 24,
//     backgroundColor: "#ffffff",
//     border: "3px solid tomato",
//     "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
//       boxShadow: "inherit",
//     },
//     "&::before": {
//       display: "none",
//     },
//   },
//   "& .MuiSlider-valueLabel": {
//     lineHeight: 1.2,
//     fontSize: 12,
//     background: "unset",
//     padding: 0,
//     width: 32,
//     height: 32,
//     borderRadius: "50% 50% 50% 0",
//     backgroundColor: "var(--primary-color)",
//     transformOrigin: "bottom left",
//     transform: "translate(50%, -90%) rotate(-45deg) scale(0)",
//     "&::before": { display: "none" },
//     "&.MuiSlider-valueLabelOpen": {
//       transform: "translate(50%, -90%) rotate(-45deg) scale(1)",
//     },
//     "& > *": {
//       transform: "rotate(45deg)",
//     },
//   },
// });

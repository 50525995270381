import * as yup from "yup";
import { pinCodeRegex } from "../../../../utils/regex";

export const eligibilityFormSchema = yup.object({
  occupation: yup.string().required("Occupation is required."),
  education: yup.string().required("Education is required."),
  pincode: yup
    .string()
    .matches(pinCodeRegex, "Enter a valid pincode")
    .required("Pincode is required."),
});

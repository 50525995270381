const useInputFormsHook = () => {
  const getPreferredICFormData = () => {
    const preferredICFormData = localStorage.getItem("Preferred Form Details");
    if (preferredICFormData) return JSON.parse(preferredICFormData);
    else return {};
  };
  const setPreferredICFormData = preferred_ic => {
    localStorage.setItem(
      "Preferred Form Details",
      JSON.stringify(preferred_ic),
    );
  };
  const getPersonalFormData = () => {
    const personalFormDetails = localStorage.getItem("Personal Form Details");
    if (personalFormDetails) return JSON.parse(personalFormDetails);
    else
      return {
        gender: "M",
        tobacco_habit: "NS",
      };
  };
  const setPersonalFormData = (data, age) => {
    localStorage.setItem(
      "Personal Form Details",
      JSON.stringify({
        tobacco_habit: data?.tobacco_habit,
        dob: data?.dob && data.dob.split("-").reverse().join(" / "),
        gender: data?.gender,
        age: age,
        annual_income: data?.annual_income,
      }),
    );
  };
  const getEligibilityFormData = () => {
    const eligibilityFormDetails = localStorage.getItem(
      "Eligibility Form Details",
    );
    if (eligibilityFormDetails) return JSON.parse(eligibilityFormDetails);
  };
  const setEligibilityFormData = data => {
    localStorage.setItem(
      "Eligibility Form Details",
      JSON.stringify({
        education: data?.education,
        occupation: data.occupation,
        pincode: data?.pincode,
      }),
    );
  };
  const getQuestionFormData = () => {
    const questionFormDetails = localStorage.getItem("Question Form Details");
    if (questionFormDetails) return JSON.parse(questionFormDetails);
    else
      return {
        risk_profile: "",
        life_stage: "",
        financial_and_familyGoal: "",
      };
  };
  const setQuestionFormData = data => {
    localStorage.setItem(
      "Question Form Details",
      JSON.stringify({
        risk_profile: data.risk_profile,
        life_stage: data.life_stage,
        financial_and_familyGoal: data.financial_and_familyGoal,
      }),
    );
  };
  const getCommunicationFormData = () => {
    const communicationFormDetails = localStorage.getItem(
      "Communication Form Details",
    );
    if (communicationFormDetails) return JSON.parse(communicationFormDetails);
    else return {};
  };
  const setCommunicationFormData = (name, email, mobileNumber) => {
    localStorage.setItem(
      "Communication Form Details",
      JSON.stringify({
        name: name,
        email: email,
        mobile: mobileNumber,
      }),
    );
  };

  return {
    getPreferredICFormData,
    setPreferredICFormData,
    getPersonalFormData,
    setPersonalFormData,
    getEligibilityFormData,
    setEligibilityFormData,
    getQuestionFormData,
    setQuestionFormData,
    getCommunicationFormData,
    setCommunicationFormData,
  };
};

export default useInputFormsHook;

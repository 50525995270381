import { useDispatch, useSelector } from "react-redux";
import { selectFilters } from "./filterSlice";
import { useGetEnquiriesQuery } from "../rider/services/rider";
import { changeFilter } from "./filterSlice";

const useGetDefaultFilters = () => {
  const dispatch = useDispatch();

  const { getMoneyAs, investedAmount, investFor, returnsAfter, payFrequency } =
    useSelector(selectFilters);

  const { isLoading } = useGetEnquiriesQuery();
  const invDefaultfilters = useSelector(state => state.filters.defFilters);

  const defaultGetMoneyAs = invDefaultfilters?.[5]?.default_value;
  const defaultPayfor = invDefaultfilters?.[1]?.default_value;
  const defaultIncomeFor = invDefaultfilters?.[4]?.default_value;
  const defaultinvestedAmount = invDefaultfilters?.[0]?.default_value;
  const defaultPayFrequency = invDefaultfilters?.[3]?.default_value;
  const defaultReturnsAfter = invDefaultfilters?.[2]?.default_value;

  const isDefaultFilters =
    getMoneyAs === "L" &&
    investedAmount === "300000" &&
    investFor === "5" &&
    returnsAfter === "10" &&
    // incomeFor === "3"
    payFrequency === "0";

  const resetFilters = () => {
    dispatch(
      changeFilter({
        pay_frequency: "0",
        pay_term: "5",
        investment_amount: "300000",
        get_money_as: "L",
        income_for: "3",
        policy_term: "10",
      }),
    );
  };

  return {
    defaultGetMoneyAs,
    defaultPayfor,
    defaultIncomeFor,
    defaultinvestedAmount,
    defaultPayFrequency,
    defaultReturnsAfter,
    isDefaultFilters,
    isLoading,
    resetFilters,
  };
};

export default useGetDefaultFilters;

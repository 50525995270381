import { InputAdornment } from "@mui/material";
// import { useSelector } from "react-redux";
import cigarette from "../../../../assets/images/cigarette.png";
import female from "../../../../assets/images/female.png";
import male from "../../../../assets/images/male.png";
import noCigarette from "../../../../assets/images/no-cigarette.png";
import { getInWords } from "../../../../utils/helper";
import { allowOnlyNumbers } from "../../../ProposalPage/InputUtils/onInput";
import { InputFormsTitle, InputPageTextInput } from "../../components";
import { useInputPageHook } from "../../hooks";
import {
  // InputFormBackButtonStyled,
  InputFormBirthDateStyled,
  InputFormButtonWrapperStyled,
  InputFormContinueButtonStyled,
  InputFormRadioButtonStyled,
  InputFormRadioButtonWrapperStyled,
  InputWrapperStyled,
} from "../../styles/inputForms.styled";
import { dateUtil } from "../../utils";
import usePersonalForm from "./usePersonalForm";

const PersonalForm = ({ setCurrent, current }) => {
  const { investmentInputPage1 } = useInputPageHook();
  const {
    onSubmit,
    handleSubmit,
    register,
    errors,
    clearErrors,
    date,
    annualIncome,
    age,
    setAge,
    setValue,
  } = usePersonalForm(current, setCurrent);
  // const { tenant } = useSelector(state => state.frontendBoot?.frontendData);
  return (
    <InputFormsTitle
      title={investmentInputPage1}
      current={current}
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputFormRadioButtonWrapperStyled>
        <InputFormRadioButtonStyled>
          <input
            type="radio"
            id="gen_m"
            value="M"
            name="gender"
            {...register("gender")}
          />
          <label htmlFor="gen_m">
            <img src={male} alt="gender" />
            <span>Male</span>
          </label>
        </InputFormRadioButtonStyled>
        <InputFormRadioButtonStyled>
          <input
            type="radio"
            id="gen_f"
            value="F"
            name="gender"
            {...register("gender")}
          />
          <label htmlFor="gen_f">
            <img src={female} alt="gender" />
            <span>Female</span>
          </label>
        </InputFormRadioButtonStyled>
      </InputFormRadioButtonWrapperStyled>
      <InputFormRadioButtonWrapperStyled>
        <InputFormRadioButtonStyled>
          <input
            type="radio"
            id="smoker"
            value="S"
            name="isSmoker"
            {...register("tobacco_habit")}
          />
          <label htmlFor="smoker">
            <img src={cigarette} alt="smoker" />
            <span>Smoker</span>
          </label>
        </InputFormRadioButtonStyled>
        <InputFormRadioButtonStyled>
          <input
            type="radio"
            id="non-smoker"
            value="NS"
            name="isSmoker"
            {...register("tobacco_habit")}
          />
          <label htmlFor="non-smoker">
            <img src={noCigarette} alt="non-smoker" />
            <span>Non Smoker</span>
          </label>
        </InputFormRadioButtonStyled>
      </InputFormRadioButtonWrapperStyled>
      <InputFormBirthDateStyled>
        <InputPageTextInput
          className="w-100"
          name="dob"
          label="Date of Birth*"
          placeholder="DD / MM / YYYY"
          {...register("dob")}
          error={errors.dob}
          helperText={errors.dob && errors.dob.message}
          onChange={e => {
            const age = dateUtil(e);
            setAge(age);
            setValue("dob", e.target.value, "dob");
            clearErrors("dob");
          }}
          value={date}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {age && `${age} years`}
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: 14,
            inputMode: "numeric",
          }}
        />
      </InputFormBirthDateStyled>
      <InputWrapperStyled>
        <InputPageTextInput
          {...register("annual_income")}
          label="Annual Income*"
          size="large"
          capitalize
          onInput={allowOnlyNumbers}
          error={errors.annual_income && errors.annual_income.message}
          value={annualIncome}
          inputProps={{ minLength: 6, maxLength: 10, inputMode: "numeric" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {getInWords(annualIncome)}
              </InputAdornment>
            ),
          }}
        />
      </InputWrapperStyled>
      <InputFormButtonWrapperStyled>
        {/* {tenant?.name !== "Fyntune" ? (
          <>
            <InputFormBackButtonStyled
              onClick={() => setCurrent(prev => prev - 1)}
            >
              Back
            </InputFormBackButtonStyled>
            <InputFormContinueButtonStyled type="submit">
              Continue
            </InputFormContinueButtonStyled>
          </>
        ) : (
          <> */}
        <InputFormContinueButtonStyled type="submit">
          Get Started
        </InputFormContinueButtonStyled>
        {/* </>
        )} */}
      </InputFormButtonWrapperStyled>
    </InputFormsTitle>
  );
};

export default PersonalForm;

import moment from "moment";
import * as yup from "yup";

export const personalFormSchema = ({ minAge, maxAge }) =>
  yup.object({
    dob: yup
      .string()
      .required("Date of Birth is required")
      .test(
        "dob",
        "Please enter the date in DD/MM/YYYY format",
        value => value.length === 14,
      )
      .test("dob", "Age should be between 18 and 65", value => {
        const age = moment().diff(moment(value, "DD/MM/YYYY"), "years");
        return age >= minAge && age <= maxAge && value.length === 14;
      }),
    annual_income: yup
      .string()
      .required("Annual Income is required")
      .test(
        "annual_income",
        "Annual Income should be between 1,00,000 and 1,00,00,000",
        value => {
          return parseInt(value) >= 100000 && parseInt(value) <= 1000000000;
        },
      ),
  });

import React, { useEffect, useRef, useState } from "react";
import useOutsideClickRef from "../../../custom-hooks/useOutsideClickRef";
import {
  CustomWrapper,
  DropDownArrow,
  DropDownList,
  Item,
  Label,
  TextInput,
  Value,
} from "./Quotes.style";
import { useDispatch } from "react-redux";
import { setSelectedPolicyPayoutFilter } from "../Quotes.slice";

const PolicyTermSelect = ({
  title = "",
  quoteData,
  setShowModal,
  policyTermToShow,
  setPolicyTermToShow,
  differentQuote = false,
}) => {
  const optionsList = quoteData?.pt_death_benefit || [];
  const [value, setValue] = useState(policyTermToShow);
  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(
    optionsList?.findIndex(item => item === value) || "0",
  );
  useEffect(() => {
    if (value) {
      setActiveIndex(optionsList?.findIndex(item => item === value) || "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const ref = useRef();
  const itemRef = useRef();

  useEffect(() => {
    if (itemRef.current) {
      itemRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [activeIndex, show]);

  useOutsideClickRef(ref, () => {
    setShow(false);
  });
  const dispatch = useDispatch();
  return (
    <CustomWrapper disabled={optionsList?.length > 1 ? false : true} ref={ref}>
      <TextInput
        onClick={() => {
          if (optionsList?.length > 1)
            setShowModal ? setShowModal(true) : setShow(!show);
        }}
      >
        <Label>{`${title}`}</Label>
        {policyTermToShow > 100 ? (
          <Value>Till Death</Value>
        ) : (
          <Value>{policyTermToShow} Years</Value>
        )}
        {optionsList?.length > 1 && (
          <DropDownArrow show={show}>
            <i className="fas fa-chevron-down"></i>
          </DropDownArrow>
        )}
      </TextInput>
      <DropDownList show={show} autoFocus>
        {optionsList?.map((value, index) => {
          return (
            <Item
              key={index}
              selected={index === parseInt(activeIndex)}
              ref={index === parseInt(activeIndex) ? itemRef : null}
              onClick={() => {
                setShow(false);
                setValue(value);
                setPolicyTermToShow(value);
                dispatch(
                  setSelectedPolicyPayoutFilter({
                    company_alias: differentQuote
                      ? `${quoteData?.company_alias}_different`
                      : quoteData?.company_alias,
                    value: {
                      policy_term: value,
                      investment_insurance_id:
                        quoteData?.investment_insurance_id,
                      product_id: quoteData?.product?.id,
                      selected_payout_term: quoteData?.selected_payout_term,
                    },
                  }),
                );
              }}
              value={value}
            >
              {value} Years
            </Item>
          );
        })}
      </DropDownList>
    </CustomWrapper>
  );
};

export default PolicyTermSelect;

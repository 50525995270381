import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useGetInWords from "../../../../custom-hooks/useGetInWords";
import useMediaQuery from "../../../../custom-hooks/useMediaQuery";
import * as mq from "../../../../styles/mediaQueries";
import { convertIntoYearly } from "../../../../utils";
import { updateEnquiries } from "../../../Landing/input.slice";
import { selectICLogo } from "../../../frontendBoot/frontendBoot.slice";
import { DeathBenefitSection } from "../../QuoteCard";
import {
  fetchKnowMoreData,
  selectShortListedPlanData,
  selectShortListedPlanTemp,
  setRemoveShowMore,
  setSelectedQuote,
  setShortListedPlan,
  setShortListedPlansData,
} from "../../Quotes.slice";
import BookmarkQuote from "../BookMarkQuote";
import GetDetails from "../Details/GetDetails";
import PayoutFrequencySelect from "../PayoutFrequencySelect";
import PolicyTermSelect from "../PolicyTermSelect";
import FilterModal from "./components/Modal/FilterModal";
import PayoutTermBodyMobile from "./components/Modal/ModalBody/PayoutTermBodyMobile";
import PolicyTermBodyMobile from "./components/Modal/ModalBody/PolicyTermBodyMobile";
import PolicyTermHeaderMobile from "./components/Modal/ModalHead/PolicyTermHeaderMobile";

function QuotesCardMobile({
  quoteData,
  isFetching,
  onBuyNowClick,
  showAddOn,
  setShowAddOn = () => {},
  differentQuote = false,
  showMorePlan,
  setShowMorePlan,
  hasMorePlans,
  morePlanCount,
  policyTermToShow,
  setPolicyTermToShow,
  payoutTermToShow,
  setPayoutTermToShow,
}) {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const logo = useSelector(selectICLogo(quoteData?.company_alias));
  const temp = useSelector(selectShortListedPlanTemp);
  const shortlisted = useSelector(selectShortListedPlanData);
  const [policyTermSelectModal, setPolicyTermSelectModal] = useState(false);
  const [payoutTermSelectModal, setPayoutTermSelectModal] = useState(false);
  useEffect(() => {
    if (
      temp.some(item => item?.product?.id === quoteData?.product?.id) &&
      shortlisted.some(item => item?.product?.id === quoteData?.product?.id) ===
        false
    ) {
      const { id } = quoteData?.product;
      dispatch(setShortListedPlan(id));
      dispatch(setShortListedPlansData(quoteData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetDetails = () => {
    dispatch(
      setSelectedQuote({ ...quoteData, frequency: payFrequency, getMoneyAs }),
    );
    dispatch(
      updateEnquiries({
        input: {
          selected_product: selectedProduct,
          policy_term: policyTermToShow,
        },
      }),
    );
    dispatch(fetchKnowMoreData(quoteData?.product?.id));
    onBuyNowClick && onBuyNowClick();
  };

  //product details
  const product = quoteData?.product_name ? quoteData?.product_name : "Product";
  const [planName, ...planType] = product
    ?.split("-")
    ?.map(item => item && item.trim());

  const deathBenefit = useGetInWords(quoteData?.death_benefit);

  const maturityBenefit = quoteData?.maturity_benefit;
  const maturityBenefitInWords = useGetInWords(maturityBenefit);
  const incomeBenefit = quoteData?.income_benfit;
  const incomeBenefitInWords = useGetInWords(incomeBenefit);

  // const maturityBenefit4 = quoteData?.maturity_benefit4;
  // const maturityBenefitInWords4 = useGetInWords(maturityBenefit4);
  // const incomeBenefit4 = quoteData?.income_benefit4;
  // const incomeBenefitInWords4 = useGetInWords(incomeBenefit4);

  const maturityBenefit8 = quoteData?.maturity_benefit8;
  const maturityBenefitInWords8 = useGetInWords(maturityBenefit8);
  const incomeBenefit8 = quoteData?.income_benefit8;
  const incomeBenefitInWords8 = useGetInWords(incomeBenefit8);

  const {
    getMoneyAs,
    payFrequency,
    investedAmount,
    investFor: investForValue = "10",
    returnsAfter: returnsAfterValue = "20",
    incomeFor: incomeForValue = "20",
  } = useSelector(state => state.filters);

  const incomeStartAtValue = `${quoteData?.income_start_at} years` || "0";
  const totalAmount = convertIntoYearly(
    quoteData?.pay_frequency,
    quoteData?.investment_amount,
  );
  const totalAmountInWords = useGetInWords(totalAmount);

  const productId = quoteData?.product?.id;
  const insuranceId = quoteData?.investment_insurance_id;

  const selectedProduct = {
    investment_insurance_id: insuranceId,
    product_id: productId,
  };
  const totalAmountToBeReceived =
    quoteData?.income_benfit * +incomeForValue +
    Number(quoteData?.maturity_benefit) +
    quoteData?.additional_lumpsum +
    quoteData?.additional_rop;
  const totalAmountInvested = quoteData.premium * investForValue;
  const returns =
    Math.round((totalAmountToBeReceived / totalAmountInvested) * 100) / 100;
  // For 4% SWAG PAR Plans
  // const totalAmountToBeReceived4 =
  //   quoteData?.income_benefit4 * +incomeForValue +
  //   quoteData?.maturity_benefit4 +
  //   quoteData?.additional_lumpsum +
  //   quoteData?.additional_rop;
  // const totalAmountInvested4 = quoteData.premium * investForValue;
  // const returns4 =
  //   Math.round((totalAmountToBeReceived4 / totalAmountInvested4) * 100) / 100;
  // For 8% SWAG PAR Plans
  const totalAmountToBeReceived8 =
    quoteData?.income_benefit8 * +incomeForValue +
    Number(quoteData?.maturity_benefit8) +
    quoteData?.additional_lumpsum +
    quoteData?.additional_rop;
  const totalAmountInvested8 = quoteData.premium * investForValue;
  const returns8 =
    Math.round((totalAmountToBeReceived8 / totalAmountInvested8) * 100) / 100;

  const { showMoreQuotes } = useSelector(state => state.quotes);

  const handleShowMore = () => {
    if (showMoreQuotes?.includes(quoteData?.company_alias)) {
      setShowMorePlan(showMorePlan);
    } else {
      setShowMorePlan(!showMorePlan);
    }
    dispatch(setRemoveShowMore(quoteData?.company_alias));
  };
  const matches = useMediaQuery(`${mq.xsForHook}`);

  if (isFetching) return null;
  return (
    <>
      <QuoteCardContainer>
        <DeathBenefitSection>
          Death Benefit : {deathBenefit}
        </DeathBenefitSection>
        <QuoteData>
          <QuoteCardMobileBookmark>
            <BookmarkQuote
              investmentId={quoteData?.product?.id}
              plans={quoteData}
            />
          </QuoteCardMobileBookmark>
          <QuoteDataMain hasMorePlans={hasMorePlans}>
            <div
              style={{
                width: "100px",
              }}
            >
              <QuoteDataIcon src={logo} />
            </div>
            <div
              style={{
                marginLeft: "10px",
              }}
            >
              <h1
                css={`
                  font-family: Inter;
                  font-weight: 600;
                  font-size: 14px;
                  margin-bottom: 0px;
                  ${mq.sm} {
                    font-size: 12px;
                    font-family: Inter;
                    font-weight: 600;
                  }
                  ${mq.xs} {
                    font-size: 11px;
                  }
                `}
              >
                {planName}
              </h1>
              <h2
                css={`
                  font-family: "Inter";
                  font-size: 10px;
                  margin-bottom: 0px;
                  ${mq.sm} {
                    font-size: 9.5px;
                    font-weight: 400;
                  }
                `}
              >
                {planType.join("-")}
              </h2>
            </div>
          </QuoteDataMain>
          {hasMorePlans && (
            <ShowMorePlanDiv onClick={handleShowMore}>
              {showMorePlan ? (
                <FaAngleUp
                  style={{
                    fontSize: "13px",
                  }}
                />
              ) : (
                <FaAngleDown
                  style={{
                    fontSize: "13px",
                  }}
                />
              )}
              {showMorePlan ? "Close" : `${morePlanCount} More Plan`}
            </ShowMorePlanDiv>
          )}
        </QuoteData>
        <QuoteDetailsStyled>
          <QuoteDataSub>
            <QuoteDataTextMedium>You Invest</QuoteDataTextMedium>
            <QuoteDataNumericValues
              differentQuote={
                quoteData?.differentQuote &&
                +quoteData?.investment_amount !== +investedAmount
              }
            >
              {totalAmountInWords}{" "}
            </QuoteDataNumericValues>
            <QuoteDataTextMedium>
              {+investForValue === 1 ? "Rupees in" : "Per Year"}
            </QuoteDataTextMedium>
            <QuoteDataTextMedium
              differentQuote={
                quoteData?.differentQuote &&
                +quoteData?.pay_term !== +investForValue
              }
            >
              {+investForValue === 1
                ? "Single Pay"
                : `For ${quoteData?.pay_term} Years`}
            </QuoteDataTextMedium>
          </QuoteDataSub>
          <QuoteDataSub>
            <QuoteDataTextMedium>You Receive</QuoteDataTextMedium>
            <QuoteDataNumericValues differentQuote={quoteData?.differentQuote}>
              {quoteData?.product_type === "PAR" ? (
                <>
                  {incomeBenefit8
                    ? incomeBenefitInWords8
                    : maturityBenefitInWords8}
                </>
              ) : (
                <>
                  {incomeBenefit
                    ? incomeBenefitInWords
                    : maturityBenefitInWords}
                </>
              )}
            </QuoteDataNumericValues>
            <QuoteDataTextMedium>
              {getMoneyAs === "I" || getMoneyAs === "B" ? "Per Year" : "After"}
            </QuoteDataTextMedium>
            {getMoneyAs === "I" || getMoneyAs === "B" ? (
              <QuoteDataTextMedium
                differentQuote={
                  quoteData?.differentQuote &&
                  +quoteData?.income_for !== +incomeForValue
                }
              >
                For {quoteData?.income_for}
                {+quoteData?.income_for === 1 ? " Year" : " Years"}
              </QuoteDataTextMedium>
            ) : (
              <QuoteDataTextMedium
                differentQuote={
                  quoteData?.differentQuote &&
                  +quoteData?.guaranteed_lumpsum_at !== +returnsAfterValue
                }
              >
                {quoteData?.guaranteed_lumpsum_at}
                {+quoteData?.guaranteed_lumpsum_at === 1 ? " Year" : " Years"}
              </QuoteDataTextMedium>
            )}
          </QuoteDataSub>
          <QuoteDataSub>
            <QuoteDataTextMedium>Returns</QuoteDataTextMedium>
            <QuoteDataNumericValues differentQuote={quoteData?.differentQuote}>
              {getMoneyAs === "L"
                ? `IRR ${quoteData?.irr_value}%`
                : `${
                    quoteData?.product_type === "PAR" ? returns8 : returns
                  } times`}
            </QuoteDataNumericValues>
            <QuoteDataTextMedium
              css={`
                white-space: nowrap;
              `}
            >
              {getMoneyAs === "L" ? "Policy Term" : "Income Start"}
            </QuoteDataTextMedium>
            {getMoneyAs === "L" ? (
              <QuoteDataTextMedium
                differentQuote={
                  quoteData?.differentQuote &&
                  quoteData?.policy_term !== policyTermToShow
                }
              >
                {policyTermToShow} Years
              </QuoteDataTextMedium>
            ) : (
              <QuoteDataTextMedium differentQuote={quoteData?.differentQuote}>
                After {incomeStartAtValue}
              </QuoteDataTextMedium>
            )}
          </QuoteDataSub>
          <GetDetailButton onClick={handleGetDetails}>
            Get Details
          </GetDetailButton>
        </QuoteDetailsStyled>
        {matches ? (
          <PolicyPayoutFilterWrapper>
            {getMoneyAs !== "L" && (
              <PayoutFrequencySelect
                title="Payout Term :"
                quoteData={quoteData}
                setShowModal={setPayoutTermSelectModal}
                differentQuote={differentQuote}
                payoutTermToShow={quoteData?.selected_payout_term}
                setPayoutTermToShow={setPayoutTermToShow}
              />
            )}
            {getMoneyAs !== "L" && (
              <PolicyTermSelect
                title="Policy Term :"
                quoteData={quoteData}
                setShowModal={setPolicyTermSelectModal}
                policyTermToShow={quoteData?.policy_term}
                setPolicyTermToShow={setPolicyTermToShow}
              />
            )}
          </PolicyPayoutFilterWrapper>
        ) : (
          <>
            {getMoneyAs !== "L" && (
              <QuoteCardPayoutTermSelect>
                <PayoutFrequencySelect
                  title="Payout Term :"
                  quoteData={quoteData}
                  setShowModal={setPayoutTermSelectModal}
                  differentQuote={differentQuote}
                  payoutTermToShow={quoteData?.selected_payout_term}
                  setPayoutTermToShow={setPayoutTermToShow}
                />
              </QuoteCardPayoutTermSelect>
            )}
            {getMoneyAs !== "L" && (
              <QuoteCardPolicyTermSelect
                isNoPayout={quoteData?.payout_term_options?.length}
              >
                <PolicyTermSelect
                  title="Policy Term :"
                  quoteData={quoteData}
                  setShowModal={setPolicyTermSelectModal}
                  policyTermToShow={quoteData?.policy_term}
                  setPolicyTermToShow={setPolicyTermToShow}
                />
              </QuoteCardPolicyTermSelect>
            )}
          </>
        )}
      </QuoteCardContainer>
      {/* Policy Term Modal */}
      <FilterModal
        show={policyTermSelectModal}
        setShow={setPolicyTermSelectModal}
        head={
          <PolicyTermHeaderMobile
            logo={logo}
            planName={planName}
            planType={planType}
          />
        }
        body={
          <PolicyTermBodyMobile
            quoteData={quoteData}
            getMoneyAs={getMoneyAs}
            policyTermToShow={policyTermToShow}
            setPolicyTermToShow={setPolicyTermToShow}
            setShow={setPolicyTermSelectModal}
          />
        }
      />
      {/* Payout Term Modal */}
      <FilterModal
        show={payoutTermSelectModal}
        setShow={setPayoutTermSelectModal}
        head={
          <PolicyTermHeaderMobile
            logo={logo}
            planName={planName}
            planType={planType}
          />
        }
        body={
          <PayoutTermBodyMobile
            quoteData={quoteData}
            getMoneyAs={getMoneyAs}
            payoutTermToShow={payoutTermToShow}
            setPayoutTermToShow={setPayoutTermToShow}
            setShow={setPayoutTermSelectModal}
            differentQuote={differentQuote}
          />
        }
      />
      {/* Get Details Modal */}
      <GetDetails
        show={showDetails}
        setShow={setShowDetails}
        company_alias={quoteData?.company_alias}
        quoteData={quoteData}
      />
    </>
  );
}

export const QuoteCardContainer = styled.div`
  display: none;
  ${mq.md} {
    display: block;
    width: 96%;
    height: fit-content;
    margin: auto;
    border: solid 1px;
    position: relative;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 5px 5px 0px 5px;
    background-color: #ffffff;
    margin-top: 10px;
  }
`;
const QuoteData = styled.div`
  display: flex;
  width: 100%;
  justify-content: 67px;
`;
const QuoteCardPolicyTermSelect = styled.div`
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 2px;
  right: ${props => (props?.isNoPayout ? "180px" : "40px")};
  @media (max-width: 475px) {
    width: 45%;
    position: absolute;
    top: 2;
    /* right: 70px; */
  }
  @media (max-width: 320px) {
    width: 45%;
    position: absolute;
    top: 2px;
    right: 25px;
  }
`;
const QuoteCardPayoutTermSelect = styled.div`
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 2px;
  right: 40px;
  @media (max-width: 475px) {
    width: 45%;
    position: absolute;
    top: 2;
    right: 30px;
  }
  @media (max-width: 320px) {
    width: 45%;
    position: absolute;
    top: 2px;
    right: 25px;
  }
`;
const QuoteDetailsStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
  width: 100%;
  /* border: 2px solid; */
`;
// quote icon and head -- main data
const QuoteDataMain = styled.div`
  width: ${props => (props.hasMorePlans ? "90%" : "100%")};
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 50px;
  justify-content: flex-start;
`;
const QuoteDataIcon = styled.img`
  height: 30px;
  margin-left: 10px;
`;
const GetDetailButton = styled.button`
  outline: none;
  border: none;
  margin: 0px 5px 0px 10px;
  background-color: var(--primary-color);
  text-align: center;
  padding: 3px;
  color: white;
  font-family: Inter;
  font-weight: 600;
  font-size: 10px;
  width: 19%;
  border-radius: 5px;
  ${mq.md} {
    font-size: 13px;
  }
`;
// quote sub data-- you invest , return etc
const QuoteDataSub = styled.div`
  width: 27%;
  text-align: center;
  border-right: ${props =>
    !props.last ? "1px solid var(--border-light);" : ""};
`;
const QuoteDataTextMedium = styled.p`
  font-size: 10px;
  font-family: Inter;
  font-weight: 500;
  color: ${props => (props?.differentQuote ? "red" : "rgba(0, 0, 0, 0.7)")};
  /* height: fit-content; */
  margin: 0;
  ${mq.xs} {
    font-size: 9.8px;
  }
`;
const QuoteDataNumericValues = styled.p`
  font-size: 12px;
  color: ${props => props?.differentQuote && "red"};
  font-family: Inter;
  font-weight: 900;
  margin: 0px 0px 0px 0px;
  height: fit-content;
  ${mq.xs} {
    font-size: 11px;
  }
`;
// bookmark section
const QuoteCardMobileBookmark = styled.div`
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 7px;
  right: 10px;
`;
//addon section
const ShowMorePlanDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 5px 5px 0 0;
  font-weight: 700;
  padding: 2px 5px;
  color: #000000;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  width: 30%;
`;
const PolicyPayoutFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default QuotesCardMobile;

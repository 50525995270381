import { createSlice } from "@reduxjs/toolkit";
import { setDefaultFilters } from "../frontendBoot/frontendBoot.slice";

import {
  contactDetails,
  enquiries,
  getEnquiries,
  getOtp,
  patchEnquiries,
  sendOtp,
} from "./serviceApi";

const input = createSlice({
  name: "input",
  initialState: {
    response: {},
    data: {},
    termDefaultFilters: [],
    loading: false,
    enquiryLoading: false,
    newFilters: {},
    otpData: [],
    otpResponse: [],
    sellerData: {},
  },

  reducers: {
    setResponse: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
    },
    setData: (state, { payload }) => {
      state.data = {
        ...state.data,
        ...payload,
        suitability: {
          ...(state?.data?.suitability || {}),
          ...(payload?.suitability || {}),
        },
      };
    },
    setTermDefaultFilters: (state, { payload }) => {
      state.termDefaultFilters = payload;
    },
    setsellerData: (state, { payload }) => {
      state.sellerData = payload;
    },
    initLoader: state => {
      state.loading = true;
    },
    initEnquiryLoader: state => {
      state.enquiryLoading = true;
    },
    clearEnquiryLoader: state => {
      state.enquiryLoading = false;
    },
    setFilters: (state, action) => {
      state.newFilters = action.payload;
    },
    setOtpData: (state, { payload }) => {
      state.otpData = payload;
    },
    setOtpResponse: (state, { payload }) => {
      state.otpResponse = payload;
    },
  },
});

export const {
  setData,
  setResponse,
  setTermDefaultFilters,
  initEnquiryLoader,
  clearEnquiryLoader,
  setFilters,
  setOtpData,
  setOtpResponse,
  setsellerData,
} = input.actions;

export const saveData = onSuccess => {
  return async (dispatch, getState) => {
    try {
      const req = getState().inputForm.data;
      dispatch(initEnquiryLoader());
      const { data } = await enquiries({
        ...req,
        section: "investment",
        is_mobile_email_required: true,
      });
      dispatch(clearEnquiryLoader());
      dispatch(setResponse(data?.data));
      const {
        gender,
        dob,
        pincode,
        tobacco_habit,
        occupation,
        education,
        annual_income,
        ...filters
      } = data?.data?.input;
      dispatch(
        setFilters({
          ...data?.data?.input,
          name: data?.data.name,
          email: data?.data.email,
          mobile: data?.data.mobile,
        }),
      );
      dispatch(setData());
      dispatch(
        setDefaultFilters(
          data?.investmentdefaultfilters?.map(item => {
            if (item.code === "pay_term") {
              return { ...item, options: Object?.values(item.options) };
            }
            if (item.code === "income_for") {
              const pay_term = data?.investmentdefaultfilters?.find(
                item => item.code === "pay_term",
              );
              return {
                ...item,
                options: Object?.values(
                  pay_term.options[filters?.pay_term]?.income_for || {},
                ),
              };
            }
            return item;
          }),
        ),
      );
      onSuccess(data?.data?.enquiry_id);
    } catch (error) {
      console.error(error);
    }
  };
};
export const saveOtpData = ({ mobile, email, section }) => {
  return async dispatch => {
    try {
      const response = await getOtp({
        mobile,
        email,
        section,
      });
      dispatch(setOtpData(response?.data));
    } catch (error) {
      console.error(error);
    }
  };
};
export const sendOtpData = ({ otp }) => {
  return async (dispatch, getState) => {
    try {
      const req = getState().inputForm.otpData;
      const uuid = req?.uuid;
      const response = await sendOtp({
        uuid,
        otp,
      });
      dispatch(setOtpResponse(response?.data));
    } catch (error) {
      console.error(error);
    }
  };
};
export const getData = () => {
  return async dispatch => {
    try {
      const { data } = await getEnquiries();
      const {
        gender,
        dob,
        pincode,
        tobacco_habit,
        occupation,
        education,
        annual_income,
        shortlistedIds,
        suitability,
      } = data?.data?.input;
      const newData = {
        gender,
        dob,
        pincode,
        tobacco_habit,
        occupation,
        education,
        annual_income,
        name: data?.data.name,
        email: data?.data.email,
        mobile: data?.data.mobile,
        shortlistedIds: shortlistedIds && [
          ...new Set([...shortlistedIds]?.map(item => item)),
        ],
        suitability,
      };
      dispatch(setData(newData));
      dispatch(
        setFilters({
          ...data?.data?.input,
          name: data?.data.name,
          email: data?.data.email,
          mobile: data?.data.mobile,
        }),
      );
      dispatch(setDefaultFilters(data?.investmentdefaultfilters));
    } catch (err) {
      console.error(err);
    }
  };
};

export const shareContactDetails = data => {
  return async dispatch => {
    try {
      dispatch(contactDetails(data));
    } catch (err) {}
  };
};
export const updateEnquiries = (req, next = () => {}) => {
  return async dispatch => {
    try {
      dispatch(initEnquiryLoader());
      const { data } = await patchEnquiries(req);
      const shortlistedIdsArray = data?.data?.input?.shortlistedIds || [];
      const newData = {
        name: data?.data.name,
        email: data?.data.email,
        mobile: data?.data.mobile,
        selected_product: data?.data.input?.selected_product,
        shortlistedIds: [
          ...new Set([...shortlistedIdsArray]?.map(item => item)),
        ],
      };
      dispatch(setData(newData));
      if (!req?.input?.shortlistedIds && !req?.input?.selected_product) {
        dispatch(setTermDefaultFilters(data?.termdefaultfilters));
        dispatch(
          setFilters({
            ...data?.data?.input,
            name: data?.data.name,
            email: data?.data.email,
            mobile: data?.data.mobile,
          }),
        );
      }
      next();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(clearEnquiryLoader());
    }
  };
};
export default input.reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useOutsideClickRef from "../../../custom-hooks/useOutsideClickRef";
import { setSelectedPolicyPayoutFilter } from "../Quotes.slice";
import {
  CustomWrapper,
  DropDownArrow,
  DropDownList,
  Item,
  Label,
  TextInput,
  Value,
} from "./Quotes.style";

const PayoutFrequencySelect = ({
  title = "",
  quoteData,
  setShowModal,
  differentQuote,
  payoutTermToShow,
  setPayoutTermToShow,
}) => {
  const payoutTermOptions = quoteData?.payout_term_options;
  const [value, setValue] = useState(payoutTermToShow);
  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(
    payoutTermOptions?.findIndex(item => item.display_name === value),
  );
  useEffect(() => {
    if (value) {
      setActiveIndex(
        payoutTermOptions.findIndex(item => item.display_name === value),
      );
    }
  }, [value]);

  const ref = useRef();
  const itemRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    if (itemRef.current) {
      itemRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [activeIndex, show]);

  useOutsideClickRef(ref, () => {
    setShow(false);
  });

  if (payoutTermOptions?.length === 0) {
    return <></>;
  }

  return (
    <CustomWrapper
      disabled={payoutTermOptions?.length > 1 ? false : true}
      ref={ref}
    >
      <TextInput
        onClick={() => {
          if (payoutTermOptions?.length > 1) {
            setShowModal ? setShowModal(true) : setShow(!show);
          }
        }}
      >
        <Label>{title}</Label>
        <Value>{quoteData?.selected_payout_term}</Value>
        {payoutTermOptions?.length > 1 && (
          <DropDownArrow show={show}>
            <i className="fas fa-chevron-down"></i>
          </DropDownArrow>
        )}
      </TextInput>
      <DropDownList show={show} autoFocus>
        {payoutTermOptions?.map((value, index) => {
          return (
            <Item
              key={value.code}
              selected={index === parseInt(activeIndex)}
              ref={index === parseInt(activeIndex) ? itemRef : null}
              onClick={() => {
                setShow(false);
                setValue(value.display_name);
                setPayoutTermToShow(value.display_name);
                dispatch(
                  setSelectedPolicyPayoutFilter({
                    company_alias: differentQuote
                      ? `${quoteData?.company_alias}_different`
                      : quoteData?.company_alias,
                    value: {
                      selected_payout_term: value?.display_name,
                      investment_insurance_id:
                        quoteData?.investment_insurance_id,
                      product_id: quoteData?.product?.id,
                      policy_term: quoteData?.policy_term,
                    },
                  }),
                );
              }}
              value={value.code}
            >
              {value.display_name}
            </Item>
          );
        })}
      </DropDownList>
    </CustomWrapper>
  );
};

export default PayoutFrequencySelect;

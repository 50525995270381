import React, { useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import * as mq from "../../../styles/mediaQueries";
import { FeatureSection } from "../FeatureSection";

import { FiCheck, FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import CorrectIcon from "../../../assets/images/correct.png";
import WrongIcon from "../../../assets/images/wrong.png";
import renderTooltip from "../../../components/Tooltip/Tooltip";
import {
  addSelectedFeature,
  removeSelectedFeature,
  setAllFeatures,
} from "../Compare.slice";
import {
  useGetCompareFeaturesQuery,
  useGetCompareProductsQuery,
  useGetDifferentQuoteQuery,
  useGetQuoteQuery,
} from "../../rider/services/rider";

import { Checkbox, useMediaQuery } from "@mui/material";
import ButtonLoader from "../../../components/Loaders/ButtonLoader/ButtonLoader";
import useGetInWords from "../../../custom-hooks/useGetInWords";

function CompareFeaturesSections() {
  const { data, isLoading } = useGetCompareProductsQuery();

  if (isLoading) return <></>;
  const { products } = data.data;
  if (products.length < 2) return <></>;
  return <AllSections products={products} />;
}

export default CompareFeaturesSections;

function AllSections({ products }) {
  const { product_id } = products[0];
  const { data, isLoading } = useGetCompareFeaturesQuery(
    { product_id },
    { skip: !product_id },
  );
  const { getMoneyAs } = useSelector(state => state.filters);
  const { selectedFeatures, showSelected, showDifference, differentFeatures } =
    useSelector(state => state.compare);

  const [planType, setPlanType] = useState([]);
  const [insurerDetails, setInsurerDetails] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [planBenefits, setPlanBenefits] = useState([]);

  useEffect(() => {
    if (isLoading) return;

    let plan_type = [];
    let insurer_details = [];
    let payment_options = [];
    let plan_benefits = [];
    data?.compare?.forEach(item => {
      const { code, name, sectiondescription } = item;
      if (code.includes("plan_type")) {
        plan_type.push({ code, name, sectiondescription });
      } else if (code.includes("insurer_details")) {
        insurer_details.push({ code, name, sectiondescription });
      } else if (code.includes("payment_options")) {
        payment_options.push({ code, name, sectiondescription });
      } else if (code.includes("plan_benefits")) {
        plan_benefits.push({ code, name, sectiondescription });
      }
    });
    setPlanType([...plan_type]);
    setInsurerDetails([...insurer_details]);
    setPaymentOptions([...payment_options]);
    setPlanBenefits([...plan_benefits]);
  }, [data, isLoading]);

  return isLoading ? (
    <div
      className="loader-comapre-section"
      css={`
        width: 100%;
        height: 100px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <ButtonLoader colored={true} />
    </div>
  ) : (
    <div
      css={`
        ${mq.md} {
          margin-top: 25px;
        }
      `}
    >
      {!showDifference &&
        ((showSelected && selectedFeatures.selectedDetails.length > 0) ||
          !showSelected) && (
          <FeatureSection heading="Selected Details">
            {((showSelected &&
              selectedFeatures.selectedDetails.includes("invested_amount")) ||
              !showSelected) && (
              <FeatureRow
                featureName="invested_amount"
                featureHeading="Invested Amount"
                featureDescription={
                  "Total amount of money you have invested to the insurance company"
                }
              />
            )}
            {((showSelected &&
              selectedFeatures.selectedDetails.includes("receive")) ||
              !showSelected) && (
              <FeatureRow
                featureName="receive"
                featureHeading="Receive"
                featureDescription={
                  "Amount of money you get from your policy, either as a payout or accumulated value, based on the growth rate of your investments."
                }
              />
            )}
            {((showSelected &&
              selectedFeatures.selectedDetails.includes("invest_for")) ||
              !showSelected) && (
              <FeatureRow
                featureName="invest_for"
                featureHeading="Invest For"
                featureDescription={
                  "Allocate your money into an investment with the goal of growing its value over a specified period."
                }
              />
            )}
            {getMoneyAs === "L"
              ? ((showSelected &&
                  selectedFeatures.selectedDetails.includes("returns_after")) ||
                  !showSelected) && (
                  <FeatureRow
                    featureName="returns_after"
                    featureHeading="Returns After"
                    featureDescription={
                      "The financial gains or benefits realized from a policy once the specified period has ended."
                    }
                  />
                )
              : ((showSelected &&
                  selectedFeatures.selectedDetails.includes("income_for")) ||
                  !showSelected) && (
                  <FeatureRow
                    featureName="income_for"
                    featureHeading="Income For"
                    featureDescription={
                      "Amount of money you will receive regularly or periodically as earnings from an investment"
                    }
                  />
                )}
            {getMoneyAs === "L"
              ? ((showSelected &&
                  selectedFeatures.selectedDetails.includes("irr_value")) ||
                  !showSelected) && (
                  <FeatureRow
                    featureName="irr_value"
                    featureHeading="IRR Value"
                    featureDescription={
                      "Represents the annualized rate of return on an insurance policy's investment component or cash value."
                    }
                  />
                )
              : ((showSelected &&
                  selectedFeatures.selectedDetails.includes("returns")) ||
                  !showSelected) && (
                  <FeatureRow
                    featureName="returns"
                    featureHeading="Returns"
                    featureDescription={
                      "Profit or income generated from an investment, expressed as a percentage of the initial amount invested."
                    }
                  />
                )}
          </FeatureSection>
        )}
      {/* plan type */}
      <SingleSection
        features={planType}
        heading={"Plan Type"}
        selected={selectedFeatures.planType}
        different={differentFeatures.planType}
      />
      {/* payment options */}
      <SingleSection
        features={paymentOptions}
        heading={"Payment Options"}
        selected={selectedFeatures.paymentOptions}
        different={differentFeatures.paymentOptions}
      />

      {/* plan benefits */}
      <SingleSection
        features={planBenefits}
        heading={"Plan Benefits"}
        selected={selectedFeatures.planBenefits}
        different={differentFeatures.planBenefits}
      />
      {/* insurerDetails */}
      <SingleSection
        features={insurerDetails}
        heading={"Insurer Details"}
        selected={selectedFeatures.insurerDetails}
        different={differentFeatures.insurerDetails}
      />
    </div>
  );
}

function SingleSection({ features, heading, selected, different }) {
  const { showSelected, showDifference } = useSelector(state => state.compare);
  return (
    <>
      {!showDifference
        ? ((showSelected && selected.length > 0) || !showSelected) && (
            <FeatureSection heading={heading}>
              {features.length > 0 &&
                features.map((item, index) => {
                  return (showSelected && selected.includes(item.code)) ||
                    !showSelected ? (
                    <FeatureRow
                      featureName={item.code}
                      featureHeading={item.name}
                      featureDescription={item.sectiondescription}
                    />
                  ) : (
                    <></>
                  );
                })}
            </FeatureSection>
          )
        : different.length > 0 &&
          ((showSelected && selected.length > 0) || !showSelected) && (
            <FeatureSection heading={heading}>
              {features.length > 0 &&
                features.map((item, index) => {
                  return different.includes(item.code) &&
                    ((showSelected && selected.includes(item.code)) ||
                      !showSelected) ? (
                    <FeatureRow
                      featureName={item.code}
                      featureHeading={item.name}
                      featureDescription={item.sectiondescription}
                    />
                  ) : (
                    <></>
                  );
                })}
            </FeatureSection>
          )}
    </>
  );
}

function FeatureRow({
  featureName = "",
  featureHeading = "",
  featureDescription = "",
  ...props
}) {
  const dispatch = useDispatch();
  const { data, isLoading } = useGetCompareProductsQuery();
  const [selected, setSelected] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { showSelected, selectedFeatures } = useSelector(
    state => state.compare,
  );

  useEffect(() => {
    if (featureName.includes("plan_type")) {
      setSelected(selectedFeatures.planType.includes(featureName));
    } else if (featureName.includes("payment_options")) {
      setSelected(selectedFeatures.paymentOptions.includes(featureName));
    } else if (featureName.includes("plan_benefits")) {
      setSelected(selectedFeatures.planBenefits.includes(featureName));
    } else if (featureName.includes("insurer_details")) {
      setSelected(selectedFeatures.insurerDetails.includes(featureName));
    } else {
      setSelected(selectedFeatures.selectedDetails.includes(featureName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeatures]);

  if (isLoading) return <p>Loading...</p>;

  const { products } = data.data;
  return (
    <div
      css={`
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        font-family: "Inter";
        font-weight: 600;
        padding: 1.5em 0;
        border-top: 1px solid rgb(236, 236, 236);
        border-radius: 0px;
        ${mq.md} {
          padding: 0.3em 0;
          text-align: left;
        }
        transition: all 0.2s;

        &:hover {
          box-shadow: rgb(100 100 111 / 20%) 0px 3px 12px 0px;
        }
        & > div {
          width: 25%;
          ${mq.md} {
            width: 50%;
            margin: 5px 0;
            :first-child {
              border-bottom: 1px solid #ececec;
              width: 100%;
            }
          }
        }
      `}
      {...props}
    >
      <div
        css={`
          text-transform: capitalize;
          position: relative;
          padding-left: 1em;
          ${mq.sm} {
            font-size: 11px;
          }
          ${mq.lg} {
            font-size: 11px;
          }
        `}
      >
        <div
          css={`
          display:flex;
          align-items:center;
          .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall{
            height:17px !important;
            width:17px !important;
          }
            ${mq.md} {
              margin-bottom: 5px;
              .MuiCheckbox-root {
                vertical-align: top !important;
              }
              .MuiSvgIcon-root {
                font-size: 1rem !important;isSelected
              }
            }
          `}
        >
          <Checkbox
            size="small"
            style={{ padding: 0, paddingRight: 5 }}
            checked={selected}
            onClick={e => {
              if (selected) {
                dispatch(removeSelectedFeature(featureName));
              } else {
                dispatch(addSelectedFeature(featureName));
              }
            }}
          />
          <>
            <div>{featureHeading}</div>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 0, hide: 100 }}
              overlay={renderTooltip({ desc: `${featureDescription}` })}
            >
              <i className="fa fa-info-circle mx-2"></i>
            </OverlayTrigger>
          </>
        </div>
      </div>
      {products.map((product, index) => (
        <FeatureValueCheck
          featureName={featureName}
          displayName={featureHeading}
          product={product}
          key={index}
        />
      ))}
    </div>
  );
}

function FeatureValueCheck({ featureName, product, displayName, ...props }) {
  return (
    <FeatureValue featureName={featureName} product={product} {...props} />
  );
}

function FeatureValue({ featureName, product, ...props }) {
  const dispatch = useDispatch();
  const { product_id, company_alias } = product;
  const { data, isLoading, isFetching } = useGetCompareFeaturesQuery(
    { product_id },
    { skip: !product_id },
  );

  const { newFilters } = useSelector(state => state.inputForm);
  const isDifferent = product.different_quote === "Y";
  const {
    data: quoteApiData,
    isLoading: isQuoteDataLoading,
    // isFetching: quoteDataFetching,
  } = useGetQuoteQuery(
    { company_alias, newFilters },
    {
      skip: isDifferent,
    },
  );
  const { data: differentQuoteApiData } = useGetDifferentQuoteQuery(
    { company_alias, newFilters },
    {
      skip: !isDifferent,
    },
  );
  const quotes = isDifferent
    ? differentQuoteApiData?.data === undefined
      ? []
      : differentQuoteApiData?.data?.filter(
          item => item?.product.id === product_id,
        )
    : quoteApiData?.data === undefined
    ? []
    : quoteApiData?.data?.filter(item => item?.product.id === product_id);
  const quote = quotes[0];

  const matches = useMediaQuery(`${mq.mdForHook}`);
  const [bool, setBool] = useState(false);
  const [val, setVal] = useState("NA");

  const { investedAmount, investFor, incomeFor, returnsAfter } = useSelector(
    state => state.filters,
  );
  const investForValue = useSelector(state => state.filters.investFor) || "10";
  const incomeForValue = quote?.income_for || "20";
  const investedAmountInWords = useGetInWords(quote?.investment_amount);
  const returnAmount = quote?.irr_value;
  const isPar = quote?.product_type === "PAR";
  // For normal returns
  const totalAmountToBeReceived =
    quote?.income_benfit * incomeForValue +
    Number(quote?.maturity_benefit) +
    quote?.additional_lumpsum +
    quote?.additional_rop;
  const totalAmountInvested = quote?.premium * investForValue;
  const returns =
    Math.round((totalAmountToBeReceived / totalAmountInvested) * 100) / 100;
  // For 4% returns
  const totalAmountToBeReceived4 =
    quote?.income_benefit4 * incomeForValue +
    Number(quote?.maturity_benefit4) +
    quote?.additional_lumpsum +
    quote?.additional_rop;
  const totalAmountInvested4 = quote?.premium * investForValue;
  const returns4 =
    Math.round((totalAmountToBeReceived4 / totalAmountInvested4) * 100) / 100;
  // For 8% returns
  const totalAmountToBeReceived8 =
    quote?.income_benefit8 * incomeForValue +
    Number(quote?.maturity_benefit8) +
    quote?.additional_lumpsum +
    quote?.additional_rop;
  const totalAmountInvested8 = quote?.premium * investForValue;
  const returns8 =
    Math.round((totalAmountToBeReceived8 / totalAmountInvested8) * 100) / 100;
  const income_benefit = quote?.income_benfit;
  const income_benefit4 = quote?.income_benefit4;
  const income_benefit8 = quote?.income_benefit8;
  const benefitWord = useGetInWords(quote?.maturity_benefit);
  const benefitWord4 = useGetInWords(quote?.maturity_benefit4);
  const benefitWord8 = useGetInWords(quote?.maturity_benefit8);
  const incomeBenefitInWords = useGetInWords(quote?.income_benfit);
  const incomeBenefitInWords4 = useGetInWords(quote?.income_benefit4);
  const incomeBenefitInWords8 = useGetInWords(quote?.income_benefit8);
  useEffect(() => {
    if (isLoading || isFetching || !data) return;
    const { compare } = data;
    compare.length > 0 &&
      compare.map(item => {
        if (item.code === featureName) {
          dispatch(setAllFeatures({ featureName, item, product_id }));
          if (item.compare_value === "Y" || item.compare_value === "N") {
            setBool(true);
          } else {
            setBool(false);
          }
          setVal(item.compare_value);
        }
        return null;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading || isFetching || isQuoteDataLoading)
    return <FeatureValueWrap>Loading...</FeatureValueWrap>;

  if (featureName === "invested_amount") {
    const featureValue = investedAmountInWords;
    return (
      <FeatureValueWrap
        different={quote?.investment_amount !== investedAmount}
        {...props}
      >
        {featureValue}
      </FeatureValueWrap>
    );
  }
  if (featureName === "irr_value") {
    const featureValue = returnAmount;
    return (
      <FeatureValueWrap
        different={quote?.irr_value}
        {...props}
        style={{ color: "black" }}
      >
        {featureValue}%
      </FeatureValueWrap>
    );
  }
  if (featureName === "returns") {
    const featureValue = isPar
      ? `${returns4}  times (4.0% p.a) / ${returns8} times (8.0% p.a)`
      : returns;
    return (
      <FeatureValueWrap
        different={featureValue !== returns}
        {...props}
        style={{ color: "black" }}
      >
        {featureValue}
      </FeatureValueWrap>
    );
  }
  if (featureName === "receive") {
    const featureValue = isPar
      ? `${
          income_benefit4 ? incomeBenefitInWords4 : benefitWord4
        } (4.0% p.a) / ${
          income_benefit8 ? incomeBenefitInWords8 : benefitWord8
        } (8.0% p.a)`
      : `${income_benefit ? incomeBenefitInWords : benefitWord}`;
    return (
      <FeatureValueWrap
        different={featureValue !== returns}
        {...props}
        style={{ color: "black" }}
      >
        {featureValue}
      </FeatureValueWrap>
    );
  }
  if (featureName === "invest_for") {
    const featureValue = quote?.pay_term;
    return (
      <FeatureValueWrap different={featureValue !== investFor} {...props}>
        {featureValue} Years
      </FeatureValueWrap>
    );
  }
  if (featureName === "income_for") {
    const featureValue = quote?.income_for;
    return (
      <FeatureValueWrap different={featureValue !== incomeFor} {...props}>
        {featureValue} Years
      </FeatureValueWrap>
    );
  }
  if (featureName === "returns_after") {
    const featureValue = quote?.policy_term;
    return (
      <FeatureValueWrap different={featureValue !== returnsAfter} {...props}>
        {featureValue} Years
      </FeatureValueWrap>
    );
  }

  let featureValue = val;

  if (bool) {
    if (val.toUpperCase() === "Y") {
      if (matches) {
        featureValue = (
          <Icon>
            <FiCheck />
          </Icon>
        );
      } else {
        featureValue = <StyledBooleanIcon src={CorrectIcon} alt="Yes" />;
      }
    }

    if (val.toUpperCase() === "N")
      if (matches) {
        featureValue = (
          <CrossIcon>
            <FiX />
          </CrossIcon>
        );
      } else {
        featureValue = <StyledBooleanIcon src={WrongIcon} alt="No" />;
      }
  }

  return <FeatureValueWrap {...props}>{featureValue}</FeatureValueWrap>;
}

const FeatureValueWrap = styled.div`
  text-align: center;
  border-left: 1px solid rgb(236, 236, 236);
  height: 100%;
  color: ${props => props.different && "red"};
  ${mq.md} {
    font-family: Inter;
    font-weight: 500;
  }
  ${mq.sm} {
    font-size: 11.5px;
  }
  ${mq.lg} {
    font-size: 11.5px;
  }
`;
const StyledBooleanIcon = styled.img`
  width: 20px;
  height: 20px;
  ${
    "" /* ${mq.sm} {
    width: 12%;
    height: auto;
  } */
  }
`;

const Icon = styled.span`
  font-size: 1.8em;
  color: #2cd44a;
  font-weight: 800;
  ${mq.lg} {
    font-size: 1.4em;
  }
`;
const CrossIcon = styled.span`
  font-size: 1.8em;
  color: #ff4133;
  font-weight: 800;
  ${mq.lg} {
    font-size: 1.4em;
  }
`;

import styled from "styled-components";
import * as mq from "../../../styles/mediaQueries";

export const QuoteCardWrapper = styled.div`
  padding: 0px 1px;
  ${mq.md} {
    display: none;
  }
`;
export const QuotecardComponent = styled.div`
  margin: 10px auto;
`;
export const QuoteCard1KnowMorePlanSection = styled.div`
  background: var(--primary-color-light);
  padding: 3px 0px;
  border-radius: 10px;
  color: #0056fe;
  font-size: 10px;
  position: relative;
  top: 8px;
  font-family: Inter;
  font-weight: 500;
  display: block;
  text-align: center;
  cursor: pointer;
`;

export const SectionWrapper = styled.div`
  display: flex;
  border: 2px solid #e6e6e6;
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 145px;
`;
export const QuotecardSection = styled.div`
  position: relative;
  width: 24%;
  text-align: center;
  position: relative;
  font-family: "Inter";
`;
export const CustomWrapper = styled.div`
  width: 100%;
  position: relative;
  cursor: ${props => (props.disabled ? "" : "pointer")};
  & img {
    width: 14px;
    margin-left: 2px;
    margin-top: -1px;
  }
  ${mq.md} {
    margin-top: 5px;
  }
  ${mq.xs} {
    background-color: #eee;
    padding: 6px 0 2px 0;
    margin: 0 2px;
    border-radius: 6px 6px 0 0;
  }
`;
export const TextInput = styled.div`
  background-color: transparent;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2px;
  margin-left: 5px;
  & .silver-color {
    color: #b0b6c1;
    margin-left: 20px;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  }
`;
export const Label = styled.span`
  font-size: 11px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 10px;
    font-family: Inter;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
  }
`;
export const Value = styled.div`
  font-weight: 700;
  font-size: 11px;
  color: ${props => (props.isDifferent ? "var(--primary-color)" : "#111")};
  white-space: pre;
  text-overflow: ellipsis;
  @media (max-width: 1024px) {
    font-size: 10px;
    color: black;
    font-family: Inter;
    font-weight: 900;
    margin: 0px 0px 0px 0px;
  }
`;
export const DropDownArrow = styled.div`
  width: 20px;
  display: flex;
  height: 20px;
  align-items: center;
  cursor: pointer;
  border-radius: 15px;
  justify-content: center;
  margin-top: -1px;
  @media (max-width: 1024px) {
    width: 16px;
    height: 16px;
    margin: 0 5px;
  }
`;
export const DropDownList = styled.div`
  max-height: ${props => (props.show ? "200px" : "0")};
  transition: all 0.3s cubic-bezier(0, 1, 0, 1);
  position: absolute;
  left: 10%;
  top: 100%;
  width: 80%;
  z-index: 101;
  background-color: #fff;
  border: 3px solid #e0f2ff;
  border: ${props => !props.show && "0px"};
  box-shadow: 0px 50px 100px 0px rgb(229 232 235 / 20%);
  border-radius: 10px;
  overflow-y: auto;
  @media (max-width: 1024px) {
    width: 100%;
  }
  ::-webkit-scrollbar {
    display: block;
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(157, 170, 185, 0.24);
    display: block;
    outline: 1px solid #708090;
    border-radius: 6px;
  }
  .custom-button-wrapper {
    width: 100%;
    position: sticky;
    bottom: 0px;
    .custom-button-button {
      width: 100%;
      padding: 6px 0;
      background-color: #fff;
      font-family: Inter;
      font-weight: 500;
      color: var(--primary-color);
      border-radius: 0 0 10px 10px;
      border: 2px solid var(--primary-color);
    }
  }
`;
export const Item = styled.div`
  cursor: pointer;
  font-size: 13px;
  position: relative;
  min-height: 20px;
  text-align: center;
  width: 100%;
  line-height: 2;
  color: #111;
  background-color: ${props => {
    if (props.selected) return "var(--primary-color)";
  }};
  color: ${props => {
    if (props.selected) return "#fff";
  }};
  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
`;

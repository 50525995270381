import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import * as mq from "../../../../../../styles/mediaQueries";
import {
  allowOnlyNumbers,
  allowOnlyUniqueAlphabetsNoSpace,
  toCapitalize,
} from "../../../../../InputUtils/onInput";
import TextInput from "../../../../../Landing/Components/TextInput";
import { setData } from "../../../../../Landing/input.slice";
import { modifyDetails } from "../../../../../Landing/validations";
import Selectt from "../../../../../ProposalPage/components/Selectt";
import { getAge2 } from "../../../EditDetailsSection/components/helper";

function EditDetailsModalMobile({ show, setShow }) {
  const { data } = useSelector(state => state.inputForm);

  const handleClose = () => {
    setValue("name", data.name);
    setValue("email", data.email);
    setValue("mobile", data.mobile);
    setValue("gender", data.gender);
    setValue("tobacco_habit", data.tobacco_habit);
    setValue("annual_income", parseInt(data.annual_income));
    setValue("risk_profile", data?.suitability?.risk_profile);
    setValue("life_stage", data?.suitability?.life_stage);
    setValue(
      "financial_and_familyGoal",
      data?.suitability?.financial_and_familyGoal,
    );
    setValue("pincode", data.pincode);
    setShow(false);
  };
  const ref = useRef();

  const {
    register,
    clearErrors,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(modifyDetails({ minAge: 18, maxAge: 85 })),

    defaultValues: {
      tobacco_habit: data.tobacco_habit,
    },
  });

  const [age, setAge] = useState(
    moment().diff(moment(data.dob, "YYYY-MM-DD"), "years"),
  );

  useEffect(() => {
    reset({
      dob: data?.dob?.split("-").reverse().join("/"),
    });
  }, [reset, data]);

  useEffect(() => {
    setValue("name", data.name);
    setValue("email", data.email);
    setValue("mobile", data.mobile);
    setValue("gender", data.gender);
    setValue("tobacco_habit", data.tobacco_habit);
    setValue("annual_income", parseInt(data.annual_income));
    setValue("risk_profile", data?.suitability?.risk_profile);
    setValue("life_stage", data?.suitability?.life_stage);
    setValue(
      "financial_and_familyGoal",
      data?.suitability?.financial_and_familyGoal,
    );
    setValue("pincode", String(data.pincode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const name = watch("name");
  const email = watch("email");
  const mobile = watch("mobile");
  const gender = watch("gender");
  const tobacco_habit = watch("tobacco_habit");
  const termAnnualIncome = watch("annual_income");
  const dateofbirth = watch("dob");
  const riskprofile = watch("risk_profile");
  const lifestage = watch("life_stage");
  const financialandfamilyGoal = watch("financial_and_familyGoal");
  const dispatch = useDispatch();

  const onDispatch = data => {
    dispatch(setData(getValues()));
    setShow(false);
  };

  function calcFunction() {
    return value => {
      var with2Decimals = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      return with2Decimals;
    };
  }

  const calc = calcFunction();
  const getInWords = getInWordsFunction();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      css={`
        border-radius: 5px;
        margin: auto;
        ${mq.md} {
          width: 100%;
        }
        ${mq.xs} {
          .modal-content {
            width: 90%;
            margin: auto;
          }
        }
      `}
      centered
      ref={ref}
    >
      <Modal.Header closeButton css={headerCss} style={{ textAlign: "center" }}>
        Edit Details
      </Modal.Header>

      <Modal.Body
        css={`
          overflow-y: auto;
          max-height: 55vh;
          padding: 1px 1px 1px 1px;
        `}
      >
        <BodyWrapper>
          <TextInput
            name="name"
            value={name}
            label="Full Name*"
            errorIcon={errors.name ? true : false}
            capitalize
            onInput={toCapitalize && allowOnlyUniqueAlphabetsNoSpace}
            error={errors.name}
            register={register}
            onChange={e => {
              setValue(
                "name",
                e.target.value.replace(/\b\w/g, char => char.toUpperCase()),
              );
            }}
            helperText={errors.name && errors?.name.message}
            placeholder="Enter your full name"
            inputProps={{ minLength: 2, maxLength: 30 }}
          />
          <TextInput
            name="email"
            label="Email*"
            value={email}
            error={errors.email}
            errorIcon={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            register={register}
            onChange={e => {
              setValue("email", e.target.value);
            }}
            placeholder="Enter your email"
            inputProps={{
              inputMode: "email",
            }}
          />
          <TextInput
            name="mobile"
            label="Mobile No.*"
            value={mobile}
            register={register}
            onInput={allowOnlyNumbers}
            placeholder="Enter your mobile number"
            error={errors.mobile}
            errorIcon={errors.mobile ? true : false}
            helperText={errors.mobile && errors.mobile.message}
            inputProps={{
              inputMode: "numeric",
              maxLength: 10,
            }}
          />
          <TextInput
            className=" w-100 mb-2"
            name="dob"
            label="Date of Birth*"
            value={dateofbirth}
            age={[18, 70]}
            placeholder="DD/MM/YYYY"
            register={register}
            error={errors.dob}
            errorIcon={errors.dob ? true : false}
            helperText={errors.dob && errors?.dob.message}
            clearErrors={clearErrors}
            onChange={e => {
              // const age = dateUtil(e);
              setValue("dob", e.target.value);
              setAge(getAge2(e.target.value));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span
                    css={`
                      font-family: Inter;
                      font-weight: 500;
                      font-size: 14px;
                      background-color: transparent;
                      color: var(--primary-color);
                    `}
                  >
                    {age && age !== 0 ? `${age} years` : ``}
                  </span>
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 10,
              inputMode: "numeric",
            }}
          />
          <Selectt
            options={[
              { code: "M", display_name: "Male" },
              { code: "F", display_name: "Female" },
            ]}
            name="gender"
            label="Gender*"
            error={errors.gender}
            errorIcon={errors.gender ? true : false}
            handleChange={e => {
              setValue("gender", e);
            }}
            register={register}
            placeholder="Select Your Gender"
            selectedValue={gender}
            value={gender}
          />
          <Selectt
            name="tobacco_habit"
            label="Tobbaco Habbit"
            options={[
              { code: "S", display_name: "Smoker" },
              { code: "NS", display_name: "Non Smoker" },
            ]}
            handleChange={e => {
              setValue("tobacco_habit", e);
            }}
            register={register}
            selectedValue={tobacco_habit}
          />
          <TextInput
            name="annual_income"
            label="Annual Income*"
            placeholder="Enter Your Annual Income"
            register={register}
            error={errors.annual_income}
            helperText={errors.annual_income && errors.annual_income.message}
            onInput={allowOnlyNumbers}
            noSearch
            value={termAnnualIncome}
            onChange={e => {
              setValue("annual_income", e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {errors.annual_income &&
                  errors.annual_income.message &&
                  !termAnnualIncome ? (
                    <span className="fs-5">{/* <MdError /> */}</span>
                  ) : (
                    termAnnualIncome && getInWords(termAnnualIncome)
                  )}
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 12,
              inputMode: "numeric",
            }}
          />
          <Selectt
            name="risk_profile"
            label="Risk Profile*"
            noSearch
            options={[
              {
                code: "Conservative",
                display_name: "Conservative",
              },
              {
                code: "Risk Averse",
                display_name: "Risk Averse",
              },
              {
                code: "Balance",
                display_name: "Balance",
              },
              {
                code: "Growth",
                display_name: "Growth",
              },
              {
                code: "Aggressive",
                display_name: "Aggressive",
              },
            ]}
            handleChange={e => {
              setValue("risk_profile", e);
            }}
            register={register}
            selectedValue={riskprofile}
          />
          <Selectt
            name="life_stage"
            label="Life Stage*"
            noSearch
            options={[
              {
                code: "Single",
                display_name: "Single",
              },
              {
                code: "Married",
                display_name: "Married",
              },
              {
                code: "Married with Young Children",
                display_name: "Married with Young Children",
              },
              {
                code: "Married with Older Children",
                display_name: "Married with Older Children",
              },
              {
                code: "Near Retirement",
                display_name: "Near Retirement",
              },
              {
                code: "Retirement",
                display_name: "Retirement",
              },
              {
                code: "Others",
                display_name: "Others",
              },
            ]}
            handleChange={e => {
              setValue("life_stage", e);
            }}
            register={register}
            selectedValue={lifestage}
          />
          <Selectt
            name="financial_and_familyGoal"
            label="Financial And Family Goal*"
            noSearch
            options={[
              {
                code: "Saving and Investments",
                display_name: "Saving and Investments",
              },
              {
                code: "Regular Income",
                display_name: "Regular Income",
              },
              {
                code: "Retirement",
                display_name: "Retirement",
              },
              {
                code: "Child",
                display_name: "Child",
              },
              {
                code: "Protection",
                display_name: "Protection",
              },
            ]}
            handleChange={e => {
              setValue("financial_and_familyGoal", e);
            }}
            register={register}
            selectedValue={financialandfamilyGoal}
          />
        </BodyWrapper>
      </Modal.Body>
      <Modal.Footer
        css={`
          padding: 13px 0px 13px 0px;
          border: 1px solid #b7b7b7;
        `}
      >
        <Button
          variant="primary"
          css={`
            margin: auto;
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            & .btn {
              border-radius: 0px;
            }
          `}
          onClick={onDispatch}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
  function getInWordsFunction() {
    return value => {
      if (!value) return null;
      value = String(value);
      const length = value.length;
      if (length >= 8) {
        return `${calc(value / Math.pow(10, 7))} Cr`;
      } else if (length >= 6) {
        return `${calc(value / Math.pow(10, 5))} Lac`;
      } else if (length >= 4) {
        return `${calc(value / Math.pow(10, 3))} Th`;
      } else return value;
    };
  }
}

const headerCss = `

font-size: 16px;
font-family: Inter;
  font-weight: 600;
background-color: var(--primary-color-light);
border-radius:10px 10px 0px 0px;
  display:inline-block;
  height:65px;
  padding:20px 15px;
  & .btn-close{
    float:right;
    z-index:1000;
}
`;

const BodyWrapper = styled.div`
  width: 100%;
  padding: 20px 30px 10px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  & .MuiFormControl-root {
    min-height: 50px !important;
  }
  & .MuiOutlinedInput-root {
    height: 45px !important;
    border-radius: 4px;
  }
`;

export default EditDetailsModalMobile;

import parse from "html-react-parser";
import {
  InputFormProgressBarWrapperStyled,
  InputFormTitleContainerStyled,
} from "../styles/inputForms.styled";
import InputFormsProgressBar from "./InputFormsProgressBar";

const InputFormsTitle = ({ children, title, onSubmit, current }) => {
  return (
    <>
      <InputFormTitleContainerStyled noValidate onSubmit={onSubmit}>
        <InputFormProgressBarWrapperStyled>
          {parse(title || "")}
          <InputFormsProgressBar contentToDisplay={current - 1} />
          <div>{children}</div>
        </InputFormProgressBarWrapperStyled>
      </InputFormTitleContainerStyled>
    </>
  );
};

export default InputFormsTitle;

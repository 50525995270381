import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setData } from "../../../Landing/input.slice";
import { useInputFormsHook } from "../../hooks";
import { preferredICFormSchema } from "./validation";

const usePreferredICForm = setCurrent => {
  const dispatch = useDispatch();
  const { setPreferredICFormData } = useInputFormsHook();
  const { handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(preferredICFormSchema),
    mode: "all",
  });

  const onSubmit = data => {
    dispatch(setData({ ...data }));
    setCurrent(prev => prev + 1);
    setPreferredICFormData(data);
  };
  const onSkip = () => {
    setValue("preferred_ic", null);
    setCurrent(prev => prev + 1);
  };
  return {
    onSubmit,
    handleSubmit,
    watch,
    onSkip,
    setValue,
  };
};

export default usePreferredICForm;

/* eslint-disable jsx-a11y/alt-text */
import * as mq from "../../../../styles/mediaQueries";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import "styled-components/macro";
import useGetInvestmentData from "../../../../custom-hooks/useGetInvestmentData";
import useGetInWords from "../../../../custom-hooks/useGetInWords";
import {
  riderApi,
  useGetRedirectionMutation,
  useUpdateCartMutation,
} from "../../../rider/services/rider";
import { setSelectedQuote } from "../../Quotes.slice";
import { toast } from "react-toastify";
import { findFrequency, redirectToIc } from "../../../../utils/helper";
import { amount } from "../../../../utils";
import { useEffect, useState } from "react";
import ButtonLoader from "../../../../components/Loaders/ButtonLoader/ButtonLoader";
export function DetailsFooter({
  imageUrl,
  company_alias,
  quoteData,
  isBiLoading,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const enquiryId = location.search.split("=")[1];
  const { investedAmountInWords, isSinglePay } = useGetInvestmentData({
    quoteData,
  });
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [updateCart, { isLoading: isCartLoading, isError: isCartError }] =
    useUpdateCartMutation();
  const { quotes, selectedQuote, addedRiders } = useSelector(
    state => state?.quotes,
  );

  useEffect(() => {
    if (isCartLoading) setLoader(true);
    if (isCartError) setLoader(false);
  }, [isCartLoading, isCartError]);

  const [getRedirection] = useGetRedirectionMutation();

  const totalRidersPremium =
    addedRiders[selectedQuote?.product?.id] !== undefined
      ? addedRiders[selectedQuote?.product?.id].reduce(
          (sum, riderAdded) => sum + parseInt(riderAdded.total_premium),
          0,
        )
      : 0;

  const totalPremiumPayable =
    totalRidersPremium + parseInt(selectedQuote.total_premium);
  const handleProceed = () => {
    setLoader(true);
    const quoteData = {
      total_premium: selectedQuote?.total_premium, // quote
      investment_amount: selectedQuote?.investment_amount, // quote
      tax_amount: selectedQuote?.tax_amount, // quote
      pay_term: selectedQuote?.pay_term, // quote
      policy_term: selectedQuote?.policy_term, // quote
      investment_insurance_id: selectedQuote?.investment_insurance_id, // quote
      product_id: selectedQuote?.product?.id, // quote
      premium: selectedQuote?.premium, // quote
      service_tax: selectedQuote?.tax_amount, // quote
      // riders: [],
      riders:
        addedRiders[selectedQuote?.product?.id] !== undefined
          ? addedRiders[selectedQuote?.product?.id].map(rider => {
              return {
                id: rider.rider_id, //add
                rider_id: rider?.rider_id, //add
                premium: rider?.total_premium, //add
                max_cover_amount: 0,
                min_cover_amount: 0,
                service_tax: rider?.service_tax, //add
                rider_name: rider?.rider_name, //add
                recalc_rider: rider.recalc_rider, //add
                rider_shortname: rider.rider_shortname || rider.short_name, //add
                pay_term: rider.default_ppt || 20, //add
                total_premium: rider?.total_premium || rider?.total_premium, //add
                investment_amount: rider.investment_amount || 0, //add
                name: rider.rider_name || rider.name, //add
                policy_term: rider.default_policy_term || 5, //add
              };
            })
          : [],
    };
    if (quotes[company_alias]) {
      dispatch(setSelectedQuote({ ...selectedQuote, ...quoteData }));
    }
    if (selectedQuote?.premium) {
      updateCart({ ...selectedQuote, ...quoteData }).then(() => {
        if (selectedQuote.is_rider_available === "Yes") {
          dispatch(riderApi.util.invalidateTags(["Rider"]));
          navigate(`/riders/?enquiryId=${enquiryId}`);
        } else {
          getRedirection({
            product_id: quoteData.product_id,
            investment_insurance_id: quoteData.investment_insurance_id,
          }).then(res => {
            if (res?.data?.data[0]?.journey_type) {
              const { data } = res.data;
              if (
                data[0] &&
                ["R", "PF"].includes(data[0].journey_type) &&
                data[0].redirection_url
              ) {
                redirectToIc(res.data.data[0]);
                return;
              } else {
                if (selectedQuote?.company_alias !== "pnb_metlife")
                  navigate(`/investment/proposal?enquiryId=${enquiryId}`);
              }
            } else {
              toast.error(
                res?.error?.data?.message
                  ? res?.error?.data?.message
                  : "Something went wrong",
                {
                  hideProgressBar: true,
                  toastId: "failed1",
                  autoClose: 3000,
                  position: "bottom-center",
                  theme: "colored",
                },
              );
            }
          });
        }
      });
    }
  };

  const product = quoteData?.product_name ? quoteData.product_name : "Product";
  const deathBenefit = useGetInWords(quoteData?.death_benefit);
  // const [planName, planType] = product
  //   .split("-")
  //   .map(item => item && item.trim());
  // Split the product string by dashes
  const parts = product.split("-").map(item => item && item.trim());

  // Extract planName and planType
  const planName = parts.length > 0 ? parts[0] : "";
  const planType = parts.length > 1 ? parts.slice(1).join(" - ") : "";
  // Now, planName contains the word before the first dash, and planType contains the rest
  return (
    <DetailsFooterSection>
      <ImageSection>
        <ImageLogo alt="company logo" src={imageUrl} />
      </ImageSection>
      <TextSection>
        <p className="footerPlanName">{planName}</p>
        <p
          className="plan-type"
          css={`
            margin-bottom: 0px;
            font-size: 10px;
          `}
        >
          {planType}
        </p>
        <p className="footerPlanType">
          Total Premium With GST : {amount(totalPremiumPayable)}
          {[
            "ACE - With Goal Protection Benefit",
            "ACE - Without Goal Protection Benefit",
          ]?.includes(selectedQuote?.product?.name) ? (
            <>
              <br />
              (Discount applied only for first year)
            </>
          ) : (
            <></>
          )}
          {/* Total Discounted Premium with GST for 1st Year &nbsp;: ₹{" "}
          {selectedQuote.total_premium} */}
        </p>
      </TextSection>
      <DataSection>
        <DataSub
          css={`
            border-right: 1px solid var(--border-light);
          `}
        >
          <p className="dataSub1">Investment Amount</p>
          <p className="dataSub2">
            {investedAmountInWords}{" "}
            {!isSinglePay ? findFrequency(selectedQuote?.frequency) : ""}
          </p>
        </DataSub>
        <DataSub
          css={`
            border-right: 1px solid var(--border-light);
          `}
        >
          {selectedQuote?.getMoneyAs === "L" ? (
            <p className="dataSub1">Investment Term</p>
          ) : (
            <p className="dataSub1">Investment For</p>
          )}
          <p className="dataSub2">
            {isSinglePay ? (
              <>Single Pay</>
            ) : (
              <>{selectedQuote?.pay_term} Years</>
            )}
          </p>
        </DataSub>
        <DataSub
          css={`
            border-right: 1px solid var(--border-light);
          `}
        >
          {selectedQuote?.getMoneyAs === "L" ? (
            <div>
              <div className="dataSub1">Returns After</div>
              {/* <div className="dataSub2">{returnsAfter} Years</div> */}
              <div className="dataSub2">{selectedQuote?.policy_term} Years</div>
            </div>
          ) : (
            <div className="planCardDataInd-text">
              <div className="dataSub1">Income For</div>
              {/* <div className="dataSub2">{incomeFor} Years</div> */}
              <div className="dataSub2">
                {selectedQuote?.income_for > 100
                  ? "Till Death"
                  : `${selectedQuote?.income_for} Years`}
              </div>
            </div>
          )}
        </DataSub>
        <DataSub
          css={`
            border-right: 1px solid var(--border-light);
          `}
        >
          <p className="dataSub1">Death Benefit</p>
          <p className="dataSub2">{deathBenefit}</p>
        </DataSub>
        <DataSub>
          <p className="dataSub1">Policy Term</p>
          <p className="dataSub2">
            {" "}
            {selectedQuote?.policy_term > 100
              ? "Till Death"
              : `${selectedQuote?.policy_term} Years`}
          </p>
        </DataSub>
      </DataSection>
      <ButtonSection disabled={isBiLoading || loader} onClick={handleProceed}>
        {loader ? <ButtonLoader /> : "Proceed To Buy"}
      </ButtonSection>
    </DetailsFooterSection>
  );
}

const DetailsFooterSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  margin-top: 10px;
  ${mq.lg} {
    padding: 5px;
    margin-top: 0px;
  }
  ${mq.md} {
    position: sticky;
    bottom: 0;
    margin-top: 0px;
    padding: 0px 15px;
  }
`;
const ImageSection = styled.div`
  width: 7%;
  height: 100%;
  display: flex;
  align-items: center;

  justify-content: center;
  ${mq.md} {
    display: none;
  }
  ${mq.lg} {
    width: 8%;
  }
`;
const ImageLogo = styled.img`
  max-width: 100%;
  height: auto;
`;

const TextSection = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  padding-left: 10px;
  gap: 2px;
  flex-direction: column;
  font-family: "Inter";
  font-weight: 600;
  ${mq.md} {
    display: none;
  }
  ${mq.lg} {
    gap: 1px;
    padding-left: 5px;
    height: fit-content;
  }

  .footerPlanName {
    font-size: 13px;
    font-family: "Inter";
    font-weight: 700;
    margin-bottom: 0px;
    color: var(--text-light);
    ${mq.lg} {
      font-size: 10px;
    }
  }
  .plan-type {
    font-size: 0.6rem;
    font-weight: 500;
    margin-bottom: 0px;
    color: var(--text-light);
    ${mq.lg} {
      font-size: 0.8rem;
    }
  }

  .footerPlanType {
    font-size: 0.6rem;
    font-weight: 500;
    margin-bottom: 7px;

    font-weight: 400;

    ${mq.lg} {
      font-size: 0.8rem;
    }
  }
`;

const DataSection = styled.div`
  width: 56%;
  height: 100%;
  background-color: var(--primary-color-light);
  display: flex;
  align-items: center;
  border-radius: 5px;
  ${mq.lg} {
    width: 60%;
  }
  ${mq.md} {
    display: none;
  }
`;

const DataSub = styled.div`
  width: 20%;
  height: 80%;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 5px 5px 5px 10px;
  .dataSub1 {
    font-size: 11px;
    color: var(--text-light);
    margin-bottom: 0px;
    ${mq.lg} {
      font-size: 10px;
    }
  }
  .dataSub2 {
    font-size: 13px;
    font-family: "Inter";
    font-weight: 600;
    margin-bottom: 0px;
    ${mq.lg} {
      font-size: 11px;
    }
  }
`;

const ButtonSection = styled.button`
  width: 12%;
  outline: none;
  border: none;
  color: white;
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  border-radius: 5px;
  background-color: ${props =>
    props?.disabled ? "#777" : "var(--primary-color)"};
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mq.lg} {
    font-size: 12px;
    height: 80%;
  }
  ${mq.md} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;
    position: sticky;
    bottom: 0;
  }
`;

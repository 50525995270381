import React from "react";
import { InputFormsTitle, InputPageSelect } from "../../components";
import {
  financialAndFamilyGoalOptions,
  lifeStageOptions,
  riskProfileOptions,
} from "../../constants/inputForms.constant";
import { useInputPageHook } from "../../hooks";
import {
  InputFormBackButtonStyled,
  InputFormButtonWrapperStyled,
  InputFormContinueButtonStyled,
  InputWrapperStyled,
} from "../../styles/inputForms.styled";
import useQuestionForm from "./useQuestionForm";

const QuestionForm = ({ current, setCurrent }) => {
  const { investmentInputPage2 = "Some More Questions" } = useInputPageHook();
  const {
    errors,
    setValue,
    onSubmit,
    handleSubmit,
    risk,
    setRisk,
    life,
    setLife,
    financial,
    setFinancial,
  } = useQuestionForm(current, setCurrent);

  return (
    <InputFormsTitle
      title={investmentInputPage2}
      current={current}
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputWrapperStyled>
        <InputPageSelect
          label="Risk Profile*"
          options={riskProfileOptions}
          error={errors.risk_profile && errors.risk_profile.message}
          handleChange={val => {
            setRisk(val);
            setValue("risk_profile", val, "risk_profile");
          }}
          noSearch
          selectedValue={risk}
        />
      </InputWrapperStyled>
      <InputWrapperStyled>
        <InputPageSelect
          label="Life Stage*"
          options={lifeStageOptions}
          error={errors.life_stage && errors.life_stage.message}
          handleChange={val => {
            setLife(val);
            setValue("life_stage", val, "life_stage");
          }}
          noSearch
          selectedValue={life}
        />
      </InputWrapperStyled>
      <InputWrapperStyled>
        <InputPageSelect
          label="Financial and Family Goal*"
          options={financialAndFamilyGoalOptions}
          error={
            errors.financial_and_familyGoal &&
            errors.financial_and_familyGoal.message
          }
          noSearch
          handleChange={val => {
            setFinancial(val);
            setValue(
              "financial_and_familyGoal",
              val,
              "financial_and_familyGoal",
            );
          }}
          selectedValue={financial}
        />
      </InputWrapperStyled>
      <InputFormButtonWrapperStyled>
        <InputFormBackButtonStyled onClick={() => setCurrent(prev => prev - 1)}>
          Back
        </InputFormBackButtonStyled>
        <InputFormContinueButtonStyled type="submit">
          Continue
        </InputFormContinueButtonStyled>
      </InputFormButtonWrapperStyled>
    </InputFormsTitle>
  );
};

export default QuestionForm;

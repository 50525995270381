export const progressStateColors = {
  past: "var(--primary-color)",
  present: "#ffffff",
  future: "#dedede",
};
export const riskProfileOptions = [
  { code: "Conservative", display_name: "Conservative" },
  { code: "Risk Averse", display_name: "Risk Averse" },
  { code: "Balance", display_name: "Balance" },
  { code: "Growth", display_name: "Growth" },
  { code: "Aggressive", display_name: "Aggressive" },
];
export const lifeStageOptions = [
  { code: "Single", display_name: "Single" },
  { code: "Married", display_name: "Married" },
  {
    code: "Married with Young Children",
    display_name: "Married with Young Children",
  },
  {
    code: "Married with Older Children",
    display_name: "Married with Older Children",
  },
  { code: "Near Retirement", display_name: "Near Retirement" },
  { code: "Retirement", display_name: "Retirement" },
  { code: "Others", display_name: "Others" },
];
export const financialAndFamilyGoalOptions = [
  { code: "Saving and Investments", display_name: "Saving and Investments" },
  { code: "Regular Income", display_name: "Regular Income" },
  { code: "Retirement", display_name: "Retirement" },
  { code: "Child", display_name: "Child" },
  { code: "Protection", display_name: "Protection" },
];
export const occupationOptions = [
  { code: "self_employed", display_name: "Self Employed" },
  { code: "salaried", display_name: "Salaried" },
];
export const educationOptions = [
  { code: "post_graduate", display_name: "Post Graduate" },
  { code: "graduate", display_name: "Graduate" },
  { code: "12th_pass", display_name: "12th Pass" },
  { code: "10th_pass", display_name: "10th Pass" },
];
